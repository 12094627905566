import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types";
import "./appointment.scss";

import {
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import TableContainer from "../../components/Common/TableContainer"
import LeftArrow from "../../assets/icons/ArrowLeft.png"

//import Images

import DeleteModal from "./DeleteModal"

//css
import "@fullcalendar/bootstrap/main.css"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import {
  getTodaysAppointments,
  fetchAppointmentTypes,
  getUserDetails,
  getAppointmentsByDocId,
  getAppointmentsOfDoc,
  setGlobalLoading,
} from "store/slices"
import {
  getAppointmentsByWeek,
  modifyEventsData,
  reasonOptions,
} from "helpers/utils"
import {
  BillingName,
  NumberVal,
  OrderId,
  StatusName,
  Time,
  Total,
} from "pages/Pets/components/PatientsCol"
import { deleteAppointment } from "helpers/api_helper"
import { IconSVG } from "components/Common/IconSvg"
import toggleIcon1 from "../../assets/icons/calender-Icons.png"
import toggleIcon2 from "../../assets/icons/table-toggle.png"
import toggleIcon3 from "../../assets/icons/Active-Calender.png"
import toggleIcon4 from "../../assets/icons/Inactive-table.png"
import navigateForword from "../../assets/icons/navigate-forword.png"
//calender buttons css
import "../../assets/scss/custom/components/_calender.scss"
import { Parent } from "pages/Parents/components/PatientsCol"
import SearchBarComponent from "components/Common/SearchBar"
import { Calendar, momentLocalizer } from "react-big-calendar"
import moment from "moment"
import "react-toastify/dist/ReactToastify.css"
import CustomToolbar from "./components/CalenderCustomToolbar"
import ErrorBoundary from "./ErrorBoundary"
import CalendarColorLegend from "./components/CalendarColorLegend";
import { capitalizeFirstLetter, setDateWithTime } from "helpers/commonHelpers";

const Appointments = props => {
  //meta title
  document.title = "Appointments | SuPaw"

  const dispatch = useDispatch()

  const [event, setEvent] = useState({})
  const [currentSelected, setCurrentSelected] = useState(-1)
  const [reasonType, setReasonType] = useState(null)

  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || "",
      category: (event && event.category) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Select Your Category"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateEvent = {
          id: event.id,
          title: values.title,
          classNames: values.category + " text-white",
          start: event.start,
        }
        // update event
        dispatch(onUpdateEvent(updateEvent))
        validation.resetForm()
      } else {
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          title: values["title"],
          start: selectedDay ? selectedDay.date : new Date(),
          className: values.category + " text-white",
        }
        // save new Appointment
        dispatch(onAddNewEvent(newEvent))
        validation.resetForm()
      }
      setSelectedDay(null)
      //   toggle()
    },
  })

  const appointments = useSelector(getAppointmentsOfDoc) || [];

  const userDetails = useSelector(getUserDetails)
  const [deleteModal, setDeleteModal] = useState(false)
  const [isDeleting, setIsdeleting] = useState(false)
  const history = useHistory()

  useEffect(() => {
    dispatch(fetchAppointmentTypes())
  }, [dispatch])


  useEffect(() => {
    async function fetchData() {
      if (userDetails?._id) {
        console.log(userDetails?.id, "iiidid")
        dispatch(setGlobalLoading(true))
        await dispatch(getAppointmentsByDocId(userDetails?._id))
        dispatch(setGlobalLoading(false))
      }
    }
    fetchData()
  }, [dispatch])

  const handleDateClick = arg => {}

  /**
   * Handling click on event on calendar
   */
  const handleDeleteEvent = async () => {
    try {
      setIsdeleting(true)
      const res = await deleteAppointment(currentSelected)
      if (res.status == 1) {
        await dispatch(getAppointmentsByDocId(userDetails?._id))
      }
      setIsdeleting(false)
      setDeleteModal(false)
    } catch (error) {
      setIsdeleting(false)
      setDeleteModal(false)
    }
  }

  /**
   * On delete event
   */
  const handleEventClick = arg => {
    history.push(`/edit-appointment/${arg.event._def.publicId}`)
  }

  const onClickDelete = arg => {
    // dispatch(onDeleteEvent(event))
    setCurrentSelected(arg._id)
    setDeleteModal(true)
    // toggle()
  }

  const PatientName = cell => {
    return (
      <Link
        to={`/pet/${cell.row?.original?.petObjectId?._id}`}
        className="font-fontFamily"
      >
        {cell.value ? cell.value : ""}
      </Link>
    )
  }
  const Cost = cell => {
    if (typeof cell.value === "number") {
      return "Rs " + Number(cell.value)
    }
    return ""
  }

  const columns = useMemo(
    () => [
      {
        Header: "Pets Name",
        accessor: "petObjectId.fullName",
        filterable: true,
        Cell: cellProps => {
          return <PatientName {...cellProps} />
        },
      },
      {
        Header: "Type",
        accessor: "type",
        filterable: true,
        Cell: cellProps => {
          return <Parent {...cellProps} />
        },
      },
      {
        Header: "Breed",
        accessor: "petObjectId.petBreed",
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Pet Type",
        accessor: "petObjectId.petType",
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Pet Parent",
        accessor: "parentId.fullName",
        filterable: true,
        Cell: cellProps => {
          return <Parent {...cellProps} />
        },
      },
      {
        Header: "Booked At",
        accessor: "time",
        filterable: true,
        Cell: cellProps => {
          return `${moment(cellProps.row.original.createdAt).format("YYYY-MM-DD HH:MM A")}`
        },
      },
      {
        Header: "Visit Type",
        accessor: "visitType",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      // {
      //   Header: "Visits",
      //   accessor: "visits",
      //   filterable: true,
      //   Cell: cellProps => {
      //     return <NumberVal {...cellProps} />
      //   },
      // },
      {
        Header: "Cost",
        accessor: "cost",
        filterable: true,
        Cell: cellProps => {
          return <Cost {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return <StatusName {...cellProps} />
        },
      },
      {
        Header: "Reasons",
        accessor: "reasonForVisit",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          console.log("cellProps", cellProps)
          return (
            <div className="d-flex gap-3 justify-content-end">
              {cellProps.row.original.status === "scheduled" &&
              cellProps.row.original.status !== "completed" ? (
                <Link
                  to={`/edit-appointment/${cellProps.row.original._id}`}
                  className="text-info"
                >
                  <i id="edit1tooltip">
                    <IconSVG icon="edit" />
                  </i>
                  <UncontrolledTooltip placement="top" target="edit1tooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              ) : null}
              {cellProps.row.original.status === "completed" && (
                <Link
                  to={`/prescription-details/${cellProps.row.original._id}`}
                  className="text-success"
                >
                  <i id="prec">
                    <IconSVG icon="more" />
                  </i>
                  <UncontrolledTooltip placement="top" target="prec">
                    Prescription
                  </UncontrolledTooltip>
                </Link>
              )}
              {cellProps.row.original.status !== "completed" &&
                cellProps.row.original.status !== "cancelled" && (
                  <Link
                    to={`${
                      cellProps.row.original.status === "checkIn"
                        ? "prescription"
                        : "checkin"
                    }/${cellProps.row.original._id}`}
                    className="text-success"
                  >
                    <i id={"edittooltip" + cellProps.row.original._id}>
                      <IconSVG icon="checkin" />
                    </i>
                    <UncontrolledTooltip
                      placement="top"
                      target={"edittooltip" + cellProps.row.original._id}
                    >
                      {cellProps.row.original.status === "checkIn"
                        ? "Prescription"
                        : "Checkin"}
                    </UncontrolledTooltip>
                  </Link>
                )}

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const appointmentData = cellProps.row.original
                  onClickDelete(appointmentData)
                }}
              >
                <i id="deletetooltip">
                  <IconSVG icon="delete" />
                </i>
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  const [toggleAppointmentPage, setToggleAppintmentPage] = useState(true)

  const displayCalender = {
    display: toggleAppointmentPage ? "" : "none",
  }
  const displayTable = {
    display: toggleAppointmentPage ? "none" : "",
  }

  function handleNav(event) {
    console.log(history.push(`/edit-appointment/${event}`))
  }

  // Custom Time Slot Wrapper
const CustomTimeSlotWrapper = ({ children }) => {
  return <div style={{ borderLeft: "2px solid #007bff", padding: "5px" }}>{children}</div>;
};

// Custom Event Component for Today View
const CustomDayEvent = ({ event }) => {
  const isToday = moment(event.start).isSame(new Date(), "day");

  return (
    <div style={{ 
      backgroundColor: isToday ? "#ffeb3b" : "#2196f3", 
      color: "#fff", 
      padding: "5px", 
      borderRadius: "5px" 
    }}>
      <strong>{event.title}</strong>
      <p>{moment(event.start).format("HH:mm")} - {moment(event.end).format("HH:mm")}</p>
    </div>
  );
};


  //Filter todays appoitment data
  const currentDate = moment();
  const todaysAppointments = appointments.filter(appointment => {
    const appointmentDate = moment(appointment.date.split("T")?.[0] || appointment.date);
    return (
      appointmentDate.date() === currentDate.date() &&
      appointmentDate.month() === currentDate.month() &&
      appointmentDate.year() === currentDate.year()
    )
  })

  console.log(todaysAppointments, "ottotoo")
  const handleCustomButtonClick = () => {
    history.push("/add-appointment")
  }

  const renderCustomButton = () => (
    <div className="custom-button">
      <button
        className="custom-button__button"
        onClick={handleCustomButtonClick}
      >
        Create New Appointment
      </button>
    </div>
  )

  const AllAppoinmentList = appointments.flatMap(appointment =>{
    return ({
      title: `${appointment.petObjectId.fullName}  - ${appointment?.type ? capitalizeFirstLetter(appointment?.type) : ""}`,
      start: setDateWithTime(appointment.date, appointment.time),
      end:  setDateWithTime(appointment.date, appointment.time, 30), 
      id: appointment.petObjectId._id,
      status: appointment.status,
      appointmentId: appointment?._id || ""
    })
  }
  )

  useEffect(() => {
    if(AllAppoinmentList && AllAppoinmentList.length) {
      setEvents([...AllAppoinmentList]);
    }
  }, [appointments])

  const [events, setEvents] = useState([...AllAppoinmentList]);

  const localizer = momentLocalizer(moment)

  const handleSelectSlot = ({ id }) => {
    console.log(id, "iii")
    history.push(`/checkin/${id}`)
  }

  const handleEventClickFromCalender = event => {
    history.push(`/pet/${event.id}?appointmentId=${event.appointmentId}&date=${new Date(event?.end).toLocaleDateString('in')}`)
  }

  const eventStyleGetter = event => {
    let backgroundColor = "#3174ad" // default color
    if (event.status?.toLowerCase() === "completed") {
      backgroundColor = "#3E7D3E"
    } else if (event.status?.toLowerCase() === "scheduled") {
      backgroundColor = "#672b88"
    } else if (event.status?.toLowerCase() === "Cancelled".toLowerCase()) {
      backgroundColor = "#F5222D"
    } else if (event.status?.toLowerCase() == "checkIn") {
      backgroundColor = "#E8A84C"
    }

    if(new Date() > new Date (event?.end)){
      backgroundColor = "rgba(0,0,0, 0.4)";
    }

    const style = {
      backgroundColor: backgroundColor,
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    }
    return {
      style: style,
    }
  }


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        isDeleting={isDeleting}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />

      <div className="page-content">
        <Container fluid={true}>
          <Row className="appointment-heading-div mb-4">
            <Col className="d-flex align-items-center justify-content-start gap-2">
              <img
                src={LeftArrow}
                width={32}
                onClick={() => history.goBack()}
                role="button"
              />
              <h4 className="mb-0 font-fontFamily fontSize-28">Appointments</h4>
            </Col>

            <Col
              xs={12}
              md={6}
              className="d-flex align-items-center justify-content-between gap-4 mr-4"
              style={{ zIndex: 10 }}
            >
              <div
                className="appointments-toggle-page-icons-div"
                style={{ cursor: "pointer" }}
                onClick={() => setToggleAppintmentPage(!toggleAppointmentPage)}
              >
                {" "}
                <img
                  alt="toggle-page-icons1"
                  src={toggleAppointmentPage ? toggleIcon3 : toggleIcon1}
                />
                <img
                  src={toggleAppointmentPage ? toggleIcon4 : toggleIcon2}
                  alt="toggle-page-icon2"
                />
              </div>
              <SearchBarComponent reasonType={reasonType} />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Row style={displayCalender}>
                <Col className="col-lg-12 mb-5">

                  <ErrorBoundary>
                    <Calendar
                      localizer={localizer}
                      events={events}
                      startAccessor="start"
                      endAccessor="end"
                      style={{ height: 500 }}
                      selectable
                      onSelectEvent={handleEventClickFromCalender}
                      eventPropGetter={eventStyleGetter}
                      components={{
                        toolbar: CustomToolbar,
                      }}
                    />
                    <CalendarColorLegend />
                  </ErrorBoundary>
                </Col>
              </Row>
              <Row className="mt-20" style={displayTable}>
                <div className="d-flex justify-content-between align-items-center margin-bottom-39">
                  <div>
                    <h2 className="font-fontFamily fontSize-20">
                      Today's Appointments
                    </h2>
                  </div>
                  <div
                    className="font-fontFamily fontSize-14 text-color-primary font-hover-underline"
                    onClick={() => history.push(`/all-appointment`)}
                    role="button"
                  >
                    All Appointments <img src={navigateForword} alt="forword" />
                  </div>
                </div>

                <TableContainer
                  columns={columns}
                  // data={appointments || []}
                  data={todaysAppointments || []}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  emptyListLink="/add-appointment"
                  emptyText="No Appointments found"
                  emptyLinkText="+ Add Appointment"
                  customPageSize={10}
                  setReasonType={setReasonType}
                  reasonType={reasonType}
                />
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Appointments.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

export default Appointments
