import classnames from "classnames"
import ButtonMain from "components/Common/Button"
import { IconSVG } from "components/Common/IconSvg"
import {
  BASE,
  fetchAppointmentsListBasedOnPetId,
  getImagingAndLabsBasedOnPetId,
} from "helpers/api_helper"
import { getDateDDMMYYYY } from "helpers/utils"
import React, { useEffect, useState } from "react"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import "react-modal-video/scss/modal-video.scss"
import { Link } from "react-router-dom"
import {
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap"
import DocViewer, {
  PDFRenderer,
  PNGRenderer,
  JPGRenderer,
  MSDocRenderer,
} from "react-doc-viewer"
import ReactSelect from "react-select"
import { getpricing, getPricingDetails, getUserDetails, setGlobalLoading } from "store/slices"
import { useDispatch, useSelector } from "react-redux"
import appConstants from "../../../constants/appConstants/appConstants.json";
import moment from "moment"

const VaccinationRecords = ({ petDetails, parentPets }) => {
  console.log("ParentPets", parentPets)
  const [currentPetDetails, setCurrentPetDetails] = useState([])
  const [photoIndex, setphotoIndex] = useState(0)
  const [isGalleryZoom, setisGalleryZoom] = useState(false)
  const [scansOrReports, setScansOrReports] = useState([])
  const [isAppointmentFetching, setIsAppointmentFetching] = useState(false)


  const [currentAppointmentType, setCurrentAppointmentType] = useState({
    label: "All",
    value: "All",
  })
  const NoMedicalHistoryAvailable = () => {
    return (
      <h6 className="no-data font-fontFamily" style={{ background: "white" }}>
        No Vacinations available
      </h6>
    )
  }
  const MAP = {
    labs: "Lab",
    imaging: "Imaging",
  }
  const pricingCategory = parentPets.filter(ele => ele.colour)
  const [activeTab, setActiveTab] = useState(0)
  const [activeTabName, setActiveTabName] = useState(pricingCategory[activeTab])
  const handleTabClick = tabId => {
    setActiveTab(tabId)
    setActiveTabName(pricingCategory[tabId])
  }
  
  useEffect(() => {
     async function fetchData() {
      try {  
        if (petDetails._id) {
         setIsAppointmentFetching(true);
          const firstPetDetails = await fetchAppointmentsListBasedOnPetId(
            activeTabName?._id
          )
          setIsAppointmentFetching(false);
          if (firstPetDetails.status === 1) {
            setCurrentPetDetails(firstPetDetails.data)
          }
        }
      } catch (error) {
        setIsAppointmentFetching(false);
        console.error("Error in fetching Appointment data:", error);
      }
     }
     fetchData()
   }, [activeTabName])



  const filterVal = currentPetDetails?.filter(
    obj => obj.visitType === currentAppointmentType?.value
  )

  return (
    <React.Fragment>
      <div className="history-doc-cta mb-4 d-flex align-items-center justify-content-between mx-2">
        <div>
          <h3>Appointment history</h3>
        </div>
        <div className="d-flex gap-2">
          <Link
            to={`/add-appointment/pet/${activeTabName._id}`}
            className="font-fontFamily fontSize-18 font-weight-500"
            style={{
              background: "#652B88",
              color: "white",

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textDecoration: "none",
            }}
          >
            + Add Appointment
          </Link>
        </div>
      </div>
      <Row className="mx-2 my-4">
        <Nav tabs>
          {pricingCategory.map((pVal, index) => (
            <NavItem key={index}>
              <NavLink
                className={activeTab === index ? "active" : ""}
                onClick={() => handleTabClick(index)}
                style={{
                  color: activeTab === index ? "#652B88" : "#6B7280",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: activeTab === index ? "2px solid #652B88" : "",
                  fontSize: "18px",
                  paddingBottom: "7px",
                }}
              >
                <p className="font-fontFamily">{pVal.fullName}</p>
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </Row>
      {currentAppointmentType?.value === "All" ? (
        <ul className="verti-timeline list-unstyled">
          {
            isAppointmentFetching ?
              (
                <Row style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </Row>
              )
              : currentPetDetails?.length ? !!!currentPetDetails?.filter((item) =>(item?.reasonForVisit?.toLowerCase() == appConstants.VACCINATION?.toLowerCase())).length 
                ? (<NoMedicalHistoryAvailable />)
                : (
                  currentPetDetails?.filter((item) =>
                    (item?.reasonForVisit?.toLowerCase() == appConstants.VACCINATION?.toLowerCase())
                  ).map((item, key) => {
                    return (
                      <li key={key} className="event-list">
                        <div className="event-timeline-dot" />
                        <div className="d-flex">
                          <div className="history-list-item">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="history-appointment-notes font-fontFamily ">
                                <h3 className="font-fontFamily fontSize-16 color-black font-weight-text">
                                  {item?.reasonForVisit}
                                </h3>
                              </div>
                              <div className="history-appointment-notes font-fontFamily fontSize-16 font-weight-text font-color-gray">
                                <p>
                                  Date:{" "}
                                  {moment(item.date?.split("T")?.[0] || item.date).format("YYYY-MM-DD")}{" "}
                                  {item?.time || ""}
                                </p>
                              </div>
                            </div>

                            <div className="history-appointment-details" style={{ width: "100%", padding: 0 }}>
                              <ol style={{ width: "100%" }}>
                                {item?.vaccinations?.map((vaccine, index) => {
                                  return (
                                    <li key={index} className="history-appointment-type-1 font-fontFamily">
                                      <p>{vaccine?.vaccine || "N/A"} ({vaccine?.type || "N/A"}) </p>
                                      {!!vaccine?.expiryDate && (
                                        <p>Exp: {moment(vaccine?.expiryDate?.split("T")?.[0] || vaccine?.expiryDate).format("YYYY-MM-DD")}</p>
                                      )}
                                    </li>
                                  )
                                })}
                              </ol>
                            </div>

                            {/* {item.radiologist && (
                      <div className="history-appointment-notes font-fontFamily">
                        Radiologist: {item.radiologist}
                      </div>
                    )} */}

                            <div className="history-appointment-reason font-fontFamily">
                              Notes: {item.notes}
                            </div>

                            <div className="history-appointment-otherdetails" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px 0" }}>
                              {item.doctorId && (
                                <div className="history-doc-details">
                                  <img src={item.doctorId.images[0]} />
                                  <p className="font-fontFamily">
                                    {item.doctorId.fullName}
                                  </p>
                                </div>
                              )}
                              {item.appointmentId !== null ? (
                                <div className="history-doc-cta font-fontFamily font-weight-text">
                                  <Link
                                    to={`/prescription-details/${item.appointmentId}`}
                                  >
                                    View Prescription
                                  </Link>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </li>
                    )
                  })
                ) : (
                <NoMedicalHistoryAvailable />
              )}
        </ul>
      ) : (
        <ul className="verti-timeline list-unstyled">
          {filterVal?.length ? (
            filterVal.map((item, key) => {
              return (
                <li key={key} className="event-list">
                  <div className="event-timeline-dot" />
                  <div className="d-flex">
                    <div className="history-list-item">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="history-appointment-notes font-fontFamily ">
                          <h3 className="font-fontFamily fontSize-16 color-black font-weight-text">
                            Vaccination-{item.type}
                          </h3>
                        </div>
                        <div className="history-appointment-notes font-fontFamily fontSize-16 font-weight-text font-color-gray">
                          <p>
                            Next Due:{" "}
                            {getDateDDMMYYYY(item.completeOrExpiryDate)}
                          </p>
                        </div>
                      </div>

                      <div className="history-appointment-details">
                        <div className="history-appointment-type font-fontFamily">
                          {/* {MAP[item.testType]} */}
                          <span className="history-appointment-type font-fontFamily">
                            {getDateDDMMYYYY(item.createdAt)}
                          </span>
                        </div>
                        <div className="history-appointment-reason font-fontFamily">
                          Notes: {item.notes}
                        </div>
                      </div>

                      {/* {item.radiologist && (
                      <div className="history-appointment-notes font-fontFamily">
                        Radiologist: {item.radiologist}
                      </div>
                    )} */}

                      <div className="history-appointment-otherdetails">
                        {item.doctorId && (
                          <div className="history-doc-details">
                            <img src={item.doctorId.images[0]} />
                            <p className="font-fontFamily">
                              {item.doctorId.fullName}
                            </p>
                          </div>
                        )}
                        <div className="history-doc-cta font-fontFamily font-weight-text">
                          <Link to={`/prescription-details/${item._id}`}>
                            View Prescription
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )
            })
          ) : (
            <NoMedicalHistoryAvailable />
          )}
        </ul>
      )}
      {/* {currentScans?.length > 0 && (
        <Modal
          isOpen={currentScans?.length}
          toggle={() => setCurrentScans(prevState => !prevState)}
          size="lg"
        >
          <ModalBody className="py-3 px-5">
            <DocViewer
              pluginRenderers={[
                PDFRenderer,
                PNGRenderer,
                JPGRenderer,
                MSDocRenderer,
              ]}
              documents={currentScans}
            />
          </ModalBody>
        </Modal>
      )} */}
      {isGalleryZoom ? (
        <Lightbox
          mainSrc={scansOrReports[photoIndex]}
          nextSrc={scansOrReports[(photoIndex + 1) % scansOrReports.length]}
          prevSrc={
            scansOrReports[
              (photoIndex + scansOrReports.length - 1) % scansOrReports.length
            ]
          }
          onCloseRequest={() => {
            setisGalleryZoom(false)
          }}
          onMovePrevRequest={() => {
            setphotoIndex(
              (photoIndex + scansOrReports.length - 1) % scansOrReports.length
            )
          }}
          onMoveNextRequest={() => {
            setphotoIndex((photoIndex + 1) % scansOrReports.length)
          }}
        />
      ) : null}
    </React.Fragment>
  )
}

export default VaccinationRecords
