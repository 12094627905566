import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  FormGroup,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar1 from "../../assets/images/users/avatar_placeholder.png"
// actions
import { showMessage } from "components/Notification"
import { getDetails, getUserDetails } from "store/slices"
import ButtonMain from "components/Common/Button"
import {
  BASE,
  getAllVetSpecialization,
  updatePatient,
  updateVet,
  uploadAttachment,
} from "helpers/api_helper"
import GooglePlacesAutocomplete from "components/google-location"
import ReactSelect from "react-select"
import { modifySpecializations } from "helpers/utils"
import LocationModal from "./AuthenticationInner/locationModal"

// const appointmentTypes = [
//   {
//     value: "audio",
//     label: "Audio",
//   },
//   { value: "video", label: "Video" },
//   { value: "visit", label: "Visit" },
// ]

const UserProfile = () => {
  //meta title
  document.title = "Profile | SuPaw"

  const dispatch = useDispatch()
  const useDetails = useSelector(getUserDetails)
  console.log("user details", useDetails)
  const [imageUrl, setImageUrl] = useState(useDetails?.images)
  const [selectedImage, setSelectedImage] = useState()
  const [selectedAAppointmentType, setAppointmentType] = useState([
    ...useDetails.appointmentType,
  ])
  const [latLong, setLetLong] = useState([...useDetails.location.coordinates])

  const [address, setAddress] = useState(useDetails.address)
  const [floor, setFloor] = useState(useDetails?.floor)
  const [landMark, setLandMark] = useState(useDetails?.landmark)
  const [completeAddress, setCompleteAddress] = useState(
    useDetails?.completeAddress
  )
  const [country, setCountry] = useState(useDetails?.country)
  const [state, setState] = useState(useDetails?.state)
  const [city, setCity] = useState(useDetails?.city)
  const [pinCode, setPinCode] = useState(useDetails?.pinCode)
  const [Specializations, setSpecializations] = useState([])
  const [selectedSpecializations, setSelectedSpecialization] = useState(
    useDetails?.specialization || []
  )

  const [isLoading, setIsLoading] = useState(false)
  const [isFileUploading, setIsFileUploading] = useState(false)

  const [addressDetailsObject, setAddressDetailsObject] = useState({})
  // Callback function to add imaging test values to the array
  const handleAddressValues = values => {
    setAddressDetailsObject(prevValues => {
      // Create a new object by spreading the previous values and adding the new values
      return { ...prevValues, ...values }
    })
  }

  console.log("AddressDetailsConsole", addressDetailsObject)
  if (!useDetails) {
    localStorage.removeItem("authUser")
    history.push("/logout")
  }
  console.log("latLong", latLong[0], latLong[1])

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getDetails(useDetails._id))
      try {
        const res = await getAllVetSpecialization()
        setSpecializations(modifySpecializations(res.data))
      } catch (error) {
        // Handle any errors that occur during fetching or data processing
        console.error(error)
      }
    }

    fetchData()
  }, [dispatch])
  useEffect(() => {
    // Check if addressDetailsObject is not empty
    if (Object.keys(addressDetailsObject).length !== 0) {
      // Update the floor state with the value from addressDetailsObject
      setFloor(addressDetailsObject.floor)
      setLandMark(addressDetailsObject.landMark)
      setCompleteAddress(addressDetailsObject.completeAddress)
      setCountry(addressDetailsObject.country)
      setState(addressDetailsObject.state)
      setCity(addressDetailsObject.city)
      setPinCode(addressDetailsObject.pinCode)
    }
  }, [addressDetailsObject])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: false,

    initialValues: {
      fullName: useDetails?.fullName,
      clinicName: useDetails?.clinicName,
      email: useDetails?.email,
      phone: useDetails?.phone,
      address,
      timings: useDetails.timings,
      city: useDetails.city,
      gender: useDetails.gender,
      about: useDetails.about,
      specialization: selectedSpecializations,
      location: {
        type: "Point",
        coordinates: [latLong[0], latLong[1]],
      },
      // appointmentType: selectedAAppointmentType,
      // consultationFee: useDetails.consultationFee,
      images: imageUrl,
      floor: floor,
      landmark: landMark,
      completeAddress: completeAddress,
      pinCode: pinCode,
      state: state,
      latitude: useDetails?.latitude,
      longitude: useDetails?.longitude,
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Please Enter Your Fullname"),
      clinicName: Yup.string().required("Please Enter Your Clinic Name"),
      email: Yup.string().required("Please Enter Your email"),
      phone: Yup.string()
        .required("Please Enter Your phone")
        .matches(/^\d{10}$/, "Phone number must be 10 digits"),
      address: Yup.string().required("Please Enter Your address"),
      about: Yup.string().required("Please Enter Your info"),
      // consultationFee: Yup.string().required(
      //   "Please Enter Your Consultation Fee"
      // ),
    }),

    onSubmit: async values => {
      try {
        if (!latLong?.length) {
          showMessage({
            label:
              "Please select location on map to detect Latitude and longitude",
            status: "warning",
          })
          return
        }
        // if (!selectedAAppointmentType?.length) {
        //   showMessage({
        //     label: "Please select appointment type(s)",
        //     status: "warning",
        //   })
        //   return
        // }
        if (!selectedSpecializations?.length) {
          showMessage({
            label: "Please select specialization(s)",
            status: "warning",
          })
          return
        }
        if (!imageUrl?.length) {
          showMessage({
            label: "Please Upload  your Profile Picture",
            status: "warning",
          })
          return
        }
        values.floor = floor
        values.landmark = landMark
        values.city = city
        values.country = country
        values.state = state
        values.pinCode = pinCode
        values.completeAddress = completeAddress
        values.images = [imageUrl]
        values.latitude = latLong[1]
        values.longitude = latLong[0]
        values.location = {
          type: "Point",
          coordinates: [latLong[1], latLong[0]],
        }
        setIsLoading(true)
        const res = await updateVet(useDetails._id, values)
        console.log("values", values)
        if (res.status === 1) {
          showMessage({
            label: "Updated Successfully!",
            status: "success",
          })
          dispatch(getDetails(useDetails._id))
        }
        setIsLoading(false)
      } catch (error) {
        console.error("Error in updateVet", error)
        setIsLoading(false)
        showMessage({ label: error.response.data.message, status: "error" })
      }
    },
  })

  const changeHandler = async event => {
    try {
      const files = event.target.files

      // Check if a file is selected
      if (files.length === 0) {
        return // Exit early if no file is selected (e.g., user cancels the dialog)
      }

      const file = files[0]

      // Validate that the file is an image
      if (file && file.type.startsWith("image/")) {
        const formData = new FormData()
        formData.append("files", file)

        setSelectedImage(file)
        setIsFileUploading(true)

        const response = await uploadAttachment(formData)

        if (response.status === 1) {
          setImageUrl(response.data)
          showMessage({
            label: "Uploaded Successfully! Please press update to continue",
            status: "success",
          })
        }
      } else {
        showMessage({
          label: "Please upload a valid image file.",
          status: "error",
        })
        // Optionally, reset the file input
        event.target.value = null
      }
    } catch (error) {
      setIsFileUploading(false)
      showMessage({ label: "Something went wrong!", status: "error" })
    } finally {
      setIsFileUploading(false)
    }
  }

  const handleLocation = e => {
    setLetLong([e.lng, e.lat])
    setAddress(e.formattedAddress)
    handleOpenModal()
  }

  useEffect(() => {
    // Update the "specialization" field value in the validation object
    validation.setFieldValue("specialization", selectedSpecializations, false)
  }, [selectedSpecializations])
  useEffect(() => {
    // Update the "appointmentType" field value in the validation object
    validation.setFieldValue("address", address, true)
  }, [latLong])

  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false)

  const handleOpenModal = () => {
    setIsLocationModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsLocationModalOpen(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <LocationModal
          onChange={handleLocation}
          isOpen={isLocationModalOpen}
          onClose={handleCloseModal}
          latLong={latLong}
          data={address}
          onAddressValuesSubmit={handleAddressValues}
        />
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title="Supaw"
            breadcrumbItem="Account"
            titleLink={"/dashboard"}
          />
          <p className="text-muted font-fontFamily">
            Update your account details
          </p>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      {imageUrl[0] ? (
                        <img
                          className="avatar-md rounded-circle img-thumbnail"
                          src={imageUrl}
                          alt="Header Avatar"
                          // onError={handleImageError}
                        />
                      ) : (
                        <span className="avatar-md rounded-circle img-thumbnail">
                          <i className="bx bx-user align-middle me-1" />
                        </span>
                      )}
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <div className="file-upload">
                          <ButtonMain
                            isLoading={false}
                            className="btn btn-primary btn-med"
                            type="submit"
                            // onClick={handleSubmit}
                          >
                            {isFileUploading
                              ? ` uploading ${selectedImage?.name}`
                              : "Upload New"}
                          </ButtonMain>
                          <input
                            type="file"
                            onChange={changeHandler}
                            accept="image/*"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col lg={6}>
                        {/* Email */}
                        <div className="row mb-9 mb-3">
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-4 text-lg-start text-start text-input-label text-input-label--md font-fontFamily"
                          >
                            Email
                          </Label>
                          <div className="col-lg-8">
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter Email"
                              type="text"
                              disabled
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>

                        {/* Full Name */}
                        <div className="row mb-9 mb-3">
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-4 text-lg-start text-start text-input-label text-input-label--md font-fontFamily"
                          >
                            Full Name
                          </Label>
                          <div className="col-lg-8">
                            <Input
                              name="fullName"
                              className="form-control"
                              placeholder="Enter User Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.fullName || ""}
                              invalid={
                                validation.touched.fullName &&
                                validation.errors.fullName
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.fullName &&
                            validation.errors.fullName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.fullName}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>

                        {/* Phone */}
                        <div className="row mb-9 mb-3">
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-4 text-lg-start text-start text-input-label text-input-label--md font-fontFamily"
                          >
                            Phone
                          </Label>
                          <div className="col-lg-8">
                            <Input
                              name="phone"
                              className="form-control"
                              placeholder="Enter Phone"
                              type="number"
                              min="10"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.phone || ""}
                              invalid={
                                validation.touched.phone &&
                                validation.errors.phone
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.phone &&
                            validation.errors.phone ? (
                              <FormFeedback type="invalid">
                                {validation.errors.phone}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>

                        {/* Appointment Types */}
                        {/* <div className="row mb-9 mb-3">
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-5 text-lg-end text-start text-input-label text-input-label--md font-fontFamily"
                          >
                            Appointment Types
                          </Label>
                          <div className="col-lg-7">
                            <ReactSelect
                              isMulti
                              value={selectedAAppointmentType}
                              onChange={setAppointmentType}
                              options={appointmentTypes}
                              classNamePrefix="form-react-select"
                              className="form-react-select"
                            />
                          </div>
                        </div> */}

                        {/* Address */}
                        <div className="row mb-9 mb-3">
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-4 text-lg-start text-start text-input-label text-input-label--md font-fontFamily"
                          >
                            Address
                          </Label>
                          <div  className="col-lg-8 px-2 ">
                          <div
                            style={{
                              // width: "300px",
                              height: "fit-content",
                              border: "1px solid #E2E5E7",
                              borderRadius: "6px",
                            }}
                            className="p-3 "
                          >
                            <h4>Clinic Address</h4>
                            {floor && <p>Floor Number - {floor}</p>}
                            <p>
                              {completeAddress}, {state}, {city}, {pinCode},{" "}
                              {country}
                            </p>
                            {landMark && <p>{landMark}</p>}
                          </div>
                          </div>
                        </div>
                      </Col>

                      <Col lg={6}>
                        {/* Search on Map */}
                        <div className="row mb-9 mb-3">
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-4 text-lg-start text-start text-input-label text-input-label--md font-fontFamily"
                          >
                            Search on Map
                          </Label>
                          <div className="col-lg-8">
                            <GooglePlacesAutocomplete
                              onChange={handleLocation}
                            />
                          </div>
                        </div>

                        {/* Specialization */}
                        <div className="row mb-9 mb-3">
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-4 text-lg-start text-start text-input-label text-input-label--md font-fontFamily"
                          >
                            Specialization
                          </Label>
                          <div className="col-lg-8">
                            <ReactSelect
                              isMulti
                              value={selectedSpecializations}
                              onChange={setSelectedSpecialization}
                              options={Specializations}
                              classNamePrefix="form-react-select"
                              className="form-react-select"
                            />
                          </div>
                        </div>

                        {/* Clinic Name */}
                        <div className="row mb-9 mb-3">
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-4 text-lg-start text-start text-input-label text-input-label--md font-fontFamily"
                          >
                            Clinic Name
                          </Label>
                          <div className="col-lg-8">
                            <Input
                              name="clinicName"
                              className="form-control"
                              placeholder="Enter Clinic Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.clinicName || ""}
                              invalid={
                                validation.touched.clinicName &&
                                validation.errors.clinicName
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.clinicName &&
                            validation.errors.clinicName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.clinicName}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Col lg={12}>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="projectdesc"
                          className="col-form-label col-lg-2  text-lg-start text-start text-input-label text-input-label--md font-fontFamily"
                        >
                          About you
                        </Label>
                        <div className="col-lg-10">
                          <Input
                            name="about"
                            id="ttil1"
                            className="form-control"
                            placeholder="Enter info about you"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.about || ""}
                            invalid={
                              validation.touched.about &&
                              validation.errors.about
                                ? true
                                : false
                            }
                          />
                          {validation.touched.about &&
                          validation.errors.about ? (
                            <FormFeedback type="invalid">
                              {validation.errors.about}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </FormGroup>
                    </Col>
                    <Row>
                      <div className="text-center mt-4 d-flex justify-content-end">
                        <ButtonMain
                          type="submit"
                          className="btn btn-primary btn-med"
                          isLoading={isLoading}
                          color="danger"
                        >
                          Update
                        </ButtonMain>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
