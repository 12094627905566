import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  getAllParents,
  getParentList,
  getUserDetails,
  setGlobalLoading,
} from "../../store/slices"

import {
  OrderId,
  FormattedTime,
  Date,
  Total,
  PaymentStatus,
  //   PaymentMethod,
} from "../Pets/components/PatientsCol"
import DocViewer, {
  PDFRenderer,
  PNGRenderer,
  JPGRenderer,
  MSDocRenderer,
} from "react-doc-viewer"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Col, Row, UncontrolledTooltip, Card, CardBody } from "reactstrap"
import { BASE, deleteParent, getImagingAndLap } from "helpers/api_helper"
import { IconSVG } from "components/Common/IconSvg"
import { modifyImagingAndLab } from "helpers/utils"
import LeftArrow from "../../assets/icons/ArrowLeft.png"
function ImagingRequestList() {
  //meta title
  document.title = "Lab Results | SuPaw"

  const userDetails = useSelector(getUserDetails)
  const [list, setList] = useState([])
  const [currentScans, setCurrentScans] = useState([])

  const history = useHistory()

  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setGlobalLoading(true))
      const list = await getImagingAndLap({
        doctorId: userDetails._id,
        testType: "labs",
      })
      setList(list.data.filter(ell => ell.status === "Completed"))
      dispatch(setGlobalLoading(false))
    }
    fetchData()
  }, [dispatch])

  const handleAddNewRequest = () => {
    history.push("/add-lab-request")
  }
  const Cost = cell => {
    if (typeof cell.value === "number") {
      return "Rs " + Number(cell.value)
    }
    return ""
  }
  const handleDownloadPDF = urls => {
    urls.forEach((url, index) => {
      const link = document.createElement("a")
      link.href = url
      link.target = "_blank" // Open the link in a new tab
      link.download = `report_${index}.pdf` // Set the desired file name for the download
      link.click()
    })
  }
  const columns = useMemo(
    () => [
      {
        Header: "Date Requested ",
        accessor: "createdAt",
        filterable: true,
        Cell: cellProps => {
          return <FormattedTime {...cellProps} />
        },
      },
      // {
      //   Header: "Requested By",
      //   accessor: "email",
      //   filterable: true,
      //   Cell: cellProps => {
      //     return <Date {...cellProps} />
      //   },
      // },
      {
        Header: "Patient",
        accessor: "petObjectId.fullName",
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Lab Type",
        accessor: "type",
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Lab Test",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Price",
        accessor: "pricing",
        filterable: true,
        Cell: cellProps => {
          return <Cost {...cellProps} />
        },
      },
      {
        Header: "Notes",
        accessor: "notes",
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3 justify-content-end">
              {/* <Link
                to={`/parent/${cellProps.row.original._id}`}
                className="text-black"
              >
                <i id="viewtooltip">
                  <IconSVG icon="more" />
                </i>

                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link> */}
              <Link
                to={`#`}
                className="text-success"
                onClick={() => {
                  const arr = cellProps?.row?.original?.scansOrReports
                  handleDownloadPDF(arr)
                }}
              >
                <i id={"edittooltip" + cellProps.row.original._id}>
                  <IconSVG icon="more" />
                </i>
                <UncontrolledTooltip placement="top" target={"edittooltip" + cellProps.row.original._id}>
                  View Reports
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="mx-2 mb-4 ">
            <Col
              className="d-flex align-items-center justify-content-start gap-2"
              md={12}
            >
              <img
                src={LeftArrow}
                width={32}
                onClick={() => history.goBack()}
                role="button"
              />
              <h4 className="mb-0 font-fontFamily fontSize-28">
                Lab Requests Completed
              </h4>
            </Col>

            <Col></Col>
          </Row>
          {/* <Breadcrumbs
            title="Dashboard"
            titleLink="/"
            breadcrumbItem="Lab Requests Completed"
          /> */}
          {currentScans?.length > 0 && (
            <DocViewer
              pluginRenderers={[
                PDFRenderer,
                PNGRenderer,
                JPGRenderer,
                MSDocRenderer,
              ]}
              documents={currentScans}
            />
          )}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={list}
                    isGlobalFilter={false}
                    isAddOptions={true}
                    handleOrderClicks={handleAddNewRequest}
                    customPageSize={10}
                    emptyListLink="/add-lab-request"
                    emptyText="No requests found"
                    emptyLinkText="+ New Lab Request"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
ImagingRequestList.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default ImagingRequestList
