import React, { useState, useEffect } from "react"
import { Row, Modal, ModalBody, ModalHeader, Button, Col } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import {
  getAllTimings,
  getUserDetails,
  getVetCurrentTimings,
} from "store/slices"
import DaySchedule from "../pages/Administration/components/DaySchedule"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css" // Required for Carousel styling
import calendarIcon from "../assets/images/calendar.png"

const DoctorTimings = ({ isOpen, setModal, onChangeDate, isLoading }) => {
  document.title = "Timing | SuPaw"

  const userDetails = useSelector(getUserDetails)
  const timingDetails = useSelector(getAllTimings)
  const [nextDays, setNextDays] = useState([])
  const [selectedDate, setSelectedDate] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [noSlotAvailable, setNoSlotAvailable] = useState(false) // State for the pop-up

  const vetTimings = useSelector((state) => state.Patients.vetTimings);
  useEffect(() => {
    if(vetTimings && vetTimings.length > 0){
      setNextDays(vetTimings)
    }

  }, [vetTimings])


  const dispatch = useDispatch()

  useEffect(() => {
    async function newLoad() {
      if (localStorage.getItem("authUser")) {
        dispatch(getVetCurrentTimings(userDetails._id));
      }
    }
    newLoad()
  }, [dispatch, userDetails._id])

  const onSelectedDate = (time, index) => {
    setSelectedDate({ time, dateIndex: index })
    onChangeDate({
      day: timingDetails?.[index]?.day,
      time,
      dateIndex: index,
      timingId: timingDetails?.[index]?._id,
      slotId: timingDetails?.[index]?.slots.find(el => el.time === time)
        ?._id,
    })
    setModal(false)
  }

  const handleNavigate = direction => {
    if (direction === "prev" && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    } else if (direction === "next" && currentIndex < nextDays.length - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  // Check if today has any available slots when the modal opens
  useEffect(() => {
    if (isOpen && nextDays && nextDays?.length > 0) {
      const todaySlots = nextDays[0].slots
      const hasAvailableSlot = todaySlots.some(slot => slot.status === "")

      if (!hasAvailableSlot) {
        setNoSlotAvailable(true)
      } else {
        setNoSlotAvailable(false)
      }
    }
  }, [isOpen, nextDays])

  return (
    <>
      <div className={noSlotAvailable && isOpen ? "blur-background" : ""}>
        <Modal
          isOpen={isOpen}
          toggle={() => setModal(prevState => !prevState)}
          size="lg"
        >
          <ModalHeader toggle={() => setModal(false)}>Select Time</ModalHeader>
          <ModalBody className="py-3 px-5">
            {isLoading ? 
            <Row style={{ display: 'flex', alignItems: 'center', justifyContent: "center"}}>
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </Row>
            : (<Row>
              {nextDays && nextDays?.length > 0 ? (
                <div className="w-100">
                  <Carousel
                    selectedItem={currentIndex}
                    showArrows={false}
                    showIndicators={false}
                    showThumbs={false}
                    showStatus={false}
                  >
                    {nextDays && nextDays.length && nextDays?.map((el, index) => {
                      return (
                        <DaySchedule
                          isCurrentDay={index === 0}
                          key={el._id}
                          value={el}
                          difference={30}
                          isSelectOnly
                          selectedDate={
                            selectedDate?.dateIndex === index
                              ? selectedDate.time
                              : null
                          }
                          onSelectedDate={time => onSelectedDate(time, index)}
                        />
                      )
                    })}
                  </Carousel>
                  <div className="d-flex justify-content-between mt-3">
                    <Button
                      color="primary"
                      onClick={() => handleNavigate("prev")}
                      disabled={currentIndex === 0}
                    >
                      Previous
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => handleNavigate("next")}
                      disabled={currentIndex === nextDays.length - 1}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              ) : (
                <Row style={{ display: 'flex', alignItems: 'center', justifyContent: "center"}}>
                  <Col xs="12" style={{ textAlign: "center" }}>
                    <div>
                      <img
                        src={calendarIcon}
                        alt="icon"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </div>
                    <h3>No Timings found!</h3>
                  </Col>
                </Row>
              )}
            </Row>)}
          </ModalBody>
        </Modal>
      </div>

      {/* No Slots Available Pop-Up */}
      <Modal
        isOpen={noSlotAvailable && isOpen}
        toggle={() => setNoSlotAvailable(false)}
        centered // Centers the modal
      >
        <ModalHeader toggle={() => setNoSlotAvailable(false)}>
          No Slots Available
        </ModalHeader>
        <ModalBody className="text-center">
          <p>Today, no slots are available. Please select another day.</p>
          <Button color="primary" onClick={() => setNoSlotAvailable(false)}>
            OK
          </Button>
        </ModalBody>
      </Modal>
    </>
  )
}

export default DoctorTimings
