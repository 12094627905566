import React from "react"
import { Bar } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min"
import "./style/dashboard.scss"
import { ButtonGroup, Dropdown } from "react-bootstrap"
import appConstants from "../../constants/appConstants/appConstants.json"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const BarChart = ({ title, labels, data, thickness }) => {
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "", // Empty label for the dataset itself
        data: data,
        backgroundColor: [...Object.values(appConstants.THEME_COLORS)], // Purple color for the bars
        borderRadius: 2, // Rounded corners for bars
        barThickness: thickness, // Set bar thickness
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows chart to take full height
    plugins: {
      legend: {
        display: false, // Hide the legend completely
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: () => "", // Empty title to remove the default label
          label: tooltipItem =>
            `${labels[tooltipItem.dataIndex]}: ${tooltipItem.raw}`, // Show the breed name and value
        },
        backgroundColor: "rgba(255, 255, 255, 1)", // White background for tooltip
        titleColor: "#333", // Title color in tooltip
        bodyColor: "#333", // Body color in tooltip
        borderColor: "#ddd", // Border color for tooltip
        borderWidth: 1,
        displayColors: false, // Hide color box in tooltip
      },
    },
    scales: {
      x: {
        ticks: {
          callback: function(value, index, values) {
            const label = this.getLabelForValue(value);
            return label.length > 10 ? label.substring(0, 10) + "..." : label;
          },
        },
        grid: {
          display: false, // Removes the gridlines on the x-axis
        },
      },
      y: {
        beginAtZero: true,
        max: Math.max(...data) + 10, // Dynamically adjust the y-axis max value based on data
        ticks: {
          stepSize: 10, // Step size for y-axis
        },
      },
    },
  }

  return (
    <div
      className="custom-card card dashboard-card"
      style={{ height: "350px", borderRadius: "15px " }}
    >
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="card-title">{title}</h5>
        </div>
        <div style={{ height: "87%" }}>
          <Bar data={chartData} options={options} />
        </div>
      </div>
    </div>
  )
}

export default BarChart
