import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { Switch, BrowserRouter as Router, Route } from "react-router-dom"
import { connect, useSelector } from "react-redux"

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import registerServiceWorker from "helpers/registerServiceWorker"

// Import scss
import "./assets/scss/theme.scss"
import {
  messaging,
  onMessageListener,
  requestFCMToken,
} from "helpers/firebase.config"
import { getToken, onMessage } from "firebase/messaging"
import NotFound from "pages/404/NotFound"
import { getIsLoading } from "store/slices"
import Loading from "components/Common/Loading"

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

const App = props => {
  const Layout = VerticalLayout
  const [fcmToken, setFCMToken] = useState();

  const isLoading = useSelector(getIsLoading);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        //  if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .register("/firebase-messaging-sw.js")
          .then(registration => {
            console.log(
              "Service Worker registered with scope:",
              registration.scope
            )
          })
          .catch(err => {
            console.error("Service Worker registration failed:", err)
          })
        self.addEventListener("install", event => {
          console.log("Service Worker installed")
        })

        self.addEventListener("activate", event => {
          console.log("Service Worker activated")
        })

        self.addEventListener("notificationclick", event => {
          event.notification.close()
          // event.waitUntil(
          //   clients.openWindow("https://yourwebsite.com") // Replace with the URL you want to open
          // )
        })
        //  }
      })
    }

    const fetchFCMToken = async () => {
      try {
        const token = await requestFCMToken()
        setFCMToken(token)
        console.log(token, "2222")
        // localStorage.setItem("fcmtoken", token)
      } catch (error) {
        console.log("Error getting a token")
      }
    }

    fetchFCMToken()

    // Listen for messages when the app is in the foreground
    onMessage(messaging, payload => {
      console.log("Message received in the foreground: ", payload)

      if (Notification.permission === "granted") {
        // new Notification(payload.notification.title, {
        //   body: payload.notification.body,
        //   icon: payload.notification.icon,
        // })
        new Notification("helllo world")
      }
    })
  }, [])

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              deviceToken={fcmToken}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
