import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  getAllParents,
  getParentList,
  getUserDetails,
  setGlobalLoading,
} from "../../store/slices"

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PetParent,
  //   PaymentMethod,
} from "./components/PatientsCol"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  Input,
  FormGroup,
} from "reactstrap"
import { deleteParent } from "helpers/api_helper"
import { IconSVG } from "components/Common/IconSvg"
import LeftArrow from "../../assets/icons/ArrowLeft.png"

function PetParentList() {
  //meta title
  document.title = "Pet Parents"

  const [modal, setModal] = useState(false)
  const [selectedParent, setSelectedParent] = useState({})

  const [parentList, setParentList] = useState([])
  const [selectedPatient, setSelectedPatient] = useState(null)
  const [showFullResults, setShowFullResults] = useState(false)

  const history = useHistory()
  const userDetails = useSelector(getUserDetails)
  const toggleViewModal = _selectedParent => {
    setModal(!modal)
    setSelectedParent(_selectedParent)
  }

  const dispatch = useDispatch()
  const parents = useSelector(getAllParents);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setGlobalLoading(true))
      const list = await dispatch(getParentList({ doctorId: userDetails._id }))
      setParentList(list)
      dispatch(setGlobalLoading(false))
    }
    fetchData()
  }, [dispatch])

  //delete order
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = patient => {
    setSelectedPatient(patient)
    setDeleteModal(true)
  }

  const handleDeleteOrder = async () => {
    if (selectedPatient._id) {
      const res = await deleteParent({ patientId: selectedPatient._id })
      if (res.status == 1) {
        const list = await dispatch(getParentList())
        setParentList(list)
      }
      setDeleteModal(false)
    }
  }
  const handleAddNewParent = () => {
    history.push("/add-parent")
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "fullName",
        filterable: true,
        Cell: cellProps => {
          return <PetParent {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "Gender",
        accessor: "gender",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "Phone",
        accessor: "phone",
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3 justify-content-end">
              <Link
                to={`/parent/${cellProps.row.original._id}`}
                className="text-black"
              >
                <i id="viewtooltip">
                  <IconSVG icon="more" />
                </i>

                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to={`/edit-parent/${cellProps.row.original._id}`}
                className="text-success"
              >
                <i id="edittooltip">
                  <IconSVG icon="edit" />
                </i>
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  const [searchTerm, setSearchTerm] = useState("")
  const [filteredItems, setFilteredItems] = useState([])
  console.log("parents", parents)
  useEffect(() => {
    const filtered = parents?.filter(item => {
      const fullNameMatch = item?.fullName
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase())
      const phoneMatch =
        typeof item?.phone === "number" &&
        item?.phone.toString().includes(searchTerm)
      return fullNameMatch || phoneMatch
    })
    setFilteredItems(filtered)
  }, [parents, searchTerm])
  console.log("FilteredItems", filteredItems)
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row className="appointment-heading-div mx-2">
            <Col className="d-flex align-items-center justify-content-start gap-2" xs="auto">
              <img
                src={LeftArrow}
                width={36}
                onClick={() => history.goBack()}
                role="button"
              />
              <h4 className="mb-0 font-fontFamily fontSize-36">Pet Parent</h4>
            </Col>

            <Col className="d-flex align-items-center justify-content-between gap-4" xs="auto">
              <div>
                <div>
                  <FormGroup className="mb-4" row>
                    <div>
                      <Input
                        id="itemName"
                        name="itemName"
                        type="text"
                        className="form-control"
                        placeholder="Search by Pet Parent / Mobile Number"
                        value={searchTerm}
                        style={{
                          width: "444px",
                          border: "none",
                          borderBottom: "1px solid #E8E8E8",
                          borderRadius: "0",
                          paddingLeft: "0",
                          paddingBottom: "0",
                        }}
                        onChange={e => setSearchTerm(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </FormGroup>
                  {searchTerm && (
                    <div style={{ position: "relative", marginTop: "10px" }}>
                      <div
                        style={{
                          position: "absolute",
                          top: 50,
                          right: 10,
                          width: "444px",
                          height: "334px",
                          background: "#FFFFFF",
                          zIndex: "1",
                          boxShadow: "0px 18px 30px 16px #00000008",
                          borderRadius: "12px",
                          overflowY: showFullResults ? "scroll" : "hidden",
                        }}
                      >
                        {filteredItems.length > 0 ? (
                          <>
                            {showFullResults ? (
                              <>
                                {filteredItems.map((ele, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      marginTop: "30px",
                                      marginLeft: "27px",
                                    }}
                                  >
                                    <Link
                                      to={`/parent/${ele._id}`}
                                      style={{ color: "black" }}
                                    >
                                      {ele.fullName}
                                    </Link>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <>
                                {filteredItems.slice(0, 3).map((ele, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      marginTop: "30px",
                                      marginLeft: "27px",
                                    }}
                                  >
                                    <Link
                                      to={`/parent/${ele._id}`}
                                      style={{ color: "black" }}
                                    >
                                      {ele.fullName}
                                    </Link>
                                  </div>
                                ))}
                                {filteredItems.length > 3 && (
                                  <div
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      marginTop: "auto",
                                      marginLeft: "27px",
                                      marginRight: "27px",
                                      marginBottom: "30px",
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setShowFullResults(true)}
                                  >
                                    <i className="fa fa-search me-1" />
                                    See All items
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              marginTop: "30px",
                              marginLeft: "27px",
                              textAlign: "center",
                            }}
                          >
                            No items found
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            {/* <div className="create-link">
              <Link
                color="primary"
                className="font-16 btn-block"
                to={"/add-appointment"}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New Appointment
              </Link>
            </div> */}
          </Row>
          {/* <Row className="my-2">
            <div className="appointment-heading-div d-flex  align-items-center">
              <div className="d-sm-flex align-items-end">
                <img
                  src={LeftArrow}
                  width={25}
                  onClick={() => history.goBack()}
                  className="mt-2"
                  role="button"
                />
                <h4 className="mb-sm-0 font-size-18">Pet Parent</h4>
              </div>

              <div>
                <div>
                  <FormGroup className="mb-4" row>
                    <div>
                      <Input
                        id="itemName"
                        name="itemName"
                        type="text"
                        className="form-control"
                        placeholder="Search by Pet Parent / Mobile Number"
                        value={searchTerm}
                        style={{
                          width: "444px",
                          border: "none",
                          borderBottom: "1px solid #E8E8E8",
                          borderRadius: "0",
                          paddingLeft: "0",
                          paddingBottom: "0",
                        }}
                        onChange={e => setSearchTerm(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </FormGroup>
                  {searchTerm && (
                    <div style={{ position: "relative", marginTop: "10px" }}>
                      <div
                        style={{
                          position: "absolute",
                          top: 50,
                          right: 10,
                          width: "444px",
                          height: "334px",
                          background: "#FFFFFF",
                          zIndex: "1",
                          boxShadow: "0px 18px 30px 16px #00000008",
                          borderRadius: "12px",
                          overflowY: showFullResults ? "scroll" : "hidden",
                        }}
                      >
                        {filteredItems.length > 0 ? (
                          <>
                            {showFullResults ? (
                              <>
                                {filteredItems.map((ele, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      marginTop: "30px",
                                      marginLeft: "27px",
                                    }}
                                  >
                                    <Link
                                      to={`/parent/${ele._id}`}
                                      style={{ color: "black" }}
                                    >
                                      {ele.fullName}
                                    </Link>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <>
                                {filteredItems.slice(0, 3).map((ele, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      marginTop: "30px",
                                      marginLeft: "27px",
                                    }}
                                  >
                                    <Link
                                      to={`/parent/${ele._id}`}
                                      style={{ color: "black" }}
                                    >
                                      {ele.fullName}
                                    </Link>
                                  </div>
                                ))}
                                {filteredItems.length > 3 && (
                                  <div
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      marginTop: "auto",
                                      marginLeft: "27px",
                                      marginRight: "27px",
                                      marginBottom: "30px",
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setShowFullResults(true)}
                                  >
                                    <i className="fa fa-search me-1" />
                                    See All items
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              marginTop: "30px",
                              marginLeft: "27px",
                              textAlign: "center",
                            }}
                          >
                            No items found
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Row> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={parents || []}
                    isGlobalFilter={false}
                    isAddOptions={true}
                    handleOrderClicks={handleAddNewParent}
                    customPageSize={10}
                    emptyListLink="/add-parent"
                    emptyText="No Parents found"
                    emptyLinkText="+ Add parent"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
PetParentList.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default PetParentList
