import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useHistory, useParams } from "react-router-dom"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"


import {
  Col,
  Row,
  Card,
  CardBody,
} from "reactstrap"
import {
  getPricingItemById, getinventoryItemById,
} from "helpers/api_helper"
// import "./style.scss"
import TestDetailsCard from "./components/InventoryItemCard"
// import PetCompleteDetails from "./components/PetCompleteDetails"

function ItemDetails() {
  //meta title
  document.title = "Inventory"

  const [itemDetails, setItemDetails] = useState({})
  const [noDetails, setNoDetails] = useState(false)


  const { itemId } = useParams()

  // fetchAppointmentsListBasedOnPetId
  // const toggleVertical = tab => {
  //   if (verticalActiveTab !== tab) {
  //     setverticalActiveTab(tab)
  //   }
  // }
  useEffect(() => {
    async function fetchData() {
      const detail = await getinventoryItemById(itemId)
      if (detail.status === 1 && detail.data) {
        setItemDetails(detail.data)
   

        // const petList = await getAllPetsByParentId(id)
        // setParentPets(petList.data)
        // setverticalActiveTab(petList.data[0]._id)
      } else {
        setNoDetails(true)
      }
    }
    fetchData()
  }, [])

  console.log("ItemDetails",itemDetails)
  if (noDetails) {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs
              title="Inventory"
              breadcrumbItem={itemDetails?.itemName}
            />
            <Row>
              <Col xs="12" style={{ textAlign: "center" }}>
                  <div>
                    <img
                        src={vetenary}
                        alt="icon"
                        style={{ width: "50px", height: "50px" }}
                      />
                  </div>
                  <h3>No Such Inventory exists.</h3>
                </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="mx-3">
          <Breadcrumbs
            title="Inventory"
            titleLink="/inventory"
            breadcrumbItem={itemDetails?.itemName}
          />
          </div>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TestDetailsCard
                    testDetails={itemDetails ? itemDetails : {}}
                  />
                  {/* <div className="parent-details__container">
                    <Row>
                      <Col md="3">
                        <CardBody>
                          <CardTitle className="mb-4">
                            {itemDetails.fullName} Pet (s) (
                            {parentPets?.length})
                          </CardTitle>
                          <Nav pills className="flex-column">
                            {parentPets?.length
                              ? parentPets.map((item, index) => {
                                  return (
                                    <NavItem key={index}>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          "mb-2": true,
                                          active:
                                            verticalActiveTab === item._id,
                                        })}
                                        onClick={() => {
                                          toggleVertical(item._id)
                                        }}
                                      >
                                        {item.fullName}
                                        {item.images[0] && (
                                          <img src={BASE + item.images[0]} />
                                        )}
                                      </NavLink>
                                    </NavItem>
                                  )
                                })
                              : null}
                          </Nav>
                        </CardBody>
                      </Col>
                      <Col md="9">
                        <TabContent
                          activeTab={verticalActiveTab}
                          className="text-muted mt-4 mt-md-0"
                        >
                          {parentPets?.length ? (
                            parentPets.map((item, index) => {
                              return (
                                <TabPane key={index} tabId={item._id}>
                                  <PetCompleteDetails petDetails={item} />
                                </TabPane>
                              )
                            })
                          ) : (
                            <div className="history-doc-cta mb-4 ">
                              <Link
                                className="btn btn-secondary"
                                to={`/add-pet/parent/${itemDetails?._id}`}
                              >
                                + Add Pets
                              </Link>
                            </div>
                          )}
                        </TabContent>
                      </Col>
                    </Row>
                  </div> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
ItemDetails.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default ItemDetails
