import React, { useEffect } from "react"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { useState } from "react"
import "./DaySchedule.scss"
import {
  dateFormatAsdddMMYYYY,
  getMinutesHrsString,
  getTimeSlots,
} from "helpers/utils"
import classNames from "classnames"
import ButtonMain from "components/Common/Button"

const DaySchedule = ({
  value,
  onTimeChange,
  onDayTimeChange,
  onDayChange,
  difference,
  isCurrentDay,
  setError,
  isUpdatingButton,
  isSelectOnly,
  selectedDate,
  onSelectedDate,
}) => {
  const options = {
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    allowInput: false,
    time_24hr: true,
    minuteIncrement: difference,
  }
  const datePickerOptionsFrom = {
    ...options,
    defaultHour: value.startTime,
  }
  const datePickerOptionsEnd = {
    ...options,
    defaultHour: 21, //value.endTime,
  }

  // ****************************

  const [isChecked, setIsChecked] = useState(value.isAvailable)
  const [error, setIsError] = useState(false)
  const [showTiming, setShowTimings] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [slots, setSlots] = useState(value.slots || [])
  const [isEditing, setIsEditing] = useState(false)
  // Convert the startTime and endTime strings to Date objects
  const _startTime = new Date(`1970-01-01T${value.startTime}:00Z`)
  const _endTime = new Date(`1970-01-01T${value.endTime}:00Z`)

  // Adjust the Date objects with the local timezone offset
  const timezoneOffset = new Date().getTimezoneOffset()
  _startTime.setMinutes(_startTime.getMinutes() + timezoneOffset)
  _endTime.setMinutes(_endTime.getMinutes() + timezoneOffset)
  const [startTime, setStartTime] = useState([_startTime])
  const [endTime, setEndTime] = useState([_endTime])
  // console.log("startTime:", startTime)
  // console.log("values", value)
  // ****************************

  const handleShowTimings = () => {
    setShowTimings(current => !current)
  }

  // ****************************

  useEffect(() => {
    const gaps = value.slots
    setSlots(gaps)
  }, [])

  // *****************************

  //xpecto developer 13/8/2024
  const handleStartTime = e => {
    const time = new Date(e)
    const _minutes = time.getMinutes()
    const _validationMinutes = _minutes + 30
    time.setSeconds(0)
    time.setMinutes(_validationMinutes)

    if (time > endTime[0]) {
      console.log("time > endTime[0]?????", time > endTime[0])

      setIsError(
        `Start time should be min -${difference} mins as that of end date`
      )
      setError({ [value.value]: true })
      // alert("error", error)
    } else {
      setIsError("")
      setError({ [value.value]: false })
      setStartTime(e)
      time.setMinutes(_validationMinutes - difference)

      const gaps = getTimeSlots(
        `${getMinutesHrsString(time.getHours())}:${getMinutesHrsString(
          time.getMinutes()
        )}:00`,
        `${getMinutesHrsString(endTime[0].getHours())}:${getMinutesHrsString(
          endTime[0].getMinutes()
        )}:00`,
        difference
      )
      setSlots(gaps)
    }
  }

  //xpecto developer 13/8/2024
  const handleEndTime = e => {
    const time = new Date(e)

    const _minutes = time.getMinutes()
    const _validationMinutes = _minutes - difference
    time.setSeconds(0)
    time.setMinutes(_validationMinutes)
    if (time < startTime[0]) {
      setIsError(
        `End time should be min +${difference} mins as that of start date`
      )
      setError({ [value.value]: true })
      console.log("error", error) // Add this line to display the error message
    } else {
      setIsError("")
      setError({ [value.value]: false })
      setEndTime(e)
      const gaps = getTimeSlots(
        `${getMinutesHrsString(startTime[0].getHours())}:${getMinutesHrsString(
          startTime[0].getMinutes()
        )}:00`,
        `${getMinutesHrsString(time.getHours())}:${getMinutesHrsString(
          time.getMinutes()
        )}:00`,
        difference
      )
      setSlots(gaps)
    }
  }
  const handleSelectTime = id => {
    if (!isSelectOnly) {
      let _slots = Object.assign({}, slots)
      _slots = slots.map(el => {
        if (el._id === id) {
          return {
            ...el,
            status: el.status === "UNAVAILABLE" ? "" : "UNAVAILABLE",
          }
        } else {
          return el
        }
      })
      setSlots(_slots)
    } else {
      let _slots = Object.assign({}, slots)
      _slots = slots.map(el => {
        if ((el._id === id && !el.status) || el.status === "USER-BLOCKED") {
          onSelectedDate(el.time)
          return {
            ...el,
            status: el.status === "" ? "USER-BLOCKED" : "",
          }
        } else {
          return el
        }
      })
      setSlots(_slots)
    }
  }

  const handleChange = () => {
    onTimeChange({
      ...value,
      slots,
    })
  }
  const handleChangeTime = () => {
    if (error) {
      // Don't call onDayTimeChange if there's an error
      return
    }
    if (startTime[0] !== value.startTime || endTime[0] !== value.endTime) {
      onDayTimeChange({
        _id: value._id,
        dayId: value.dayId,
        startTime: startTime[0].toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }),
        endTime: endTime[0].toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }),
      })
    }
    setIsEditing(false)
    // if (startTime[0] !== value.startTime || endTime[0] !== value.endTime) {
    //   onDayTimeChange({
    //     _id: value._id,
    //     dayId: value.dayId,
    //     startTime: startTime[0].toLocaleTimeString([], {
    //       hour: "2-digit",
    //       minute: "2-digit",
    //       hour12: false,
    //     }),
    //     endTime: endTime[0].toLocaleTimeString([], {
    //       hour: "2-digit",
    //       minute: "2-digit",
    //       hour12: false,
    //     }),
    //   })
    // }
    // setIsEditing(false)
  }

  // ****************************
  useEffect(() => {
    const _startTime = new Date(`1970-01-01T${value.startTime}:00Z`)
    const _endTime = new Date(`1970-01-01T${value.endTime}:00Z`)
    _startTime.setMinutes(_startTime.getMinutes() + timezoneOffset)
    _endTime.setMinutes(_endTime.getMinutes() + timezoneOffset)
    setStartTime([_startTime])
    setEndTime([_endTime])
    setSlots(value.slots || [])
  }, [value])

  return (
    <div
      className={`dates-container${
        isSelectOnly ? " dates-container--select" : ""
      }`}
    >
      <div className="dates-row">
        <div
          className={`form-check form-checkbox-outline form-check-warning ${
            isSelectOnly ? " form-check--selected" : ""
          }`}
        >
          {!isSelectOnly && (
            <input
              type="checkbox"
              checked={isChecked}
              {...(isCurrentDay ? { disabled: true } : {})}
              className="form-check-input"
              onChange={e => {
                setIsChecked(e.target.checked)
                onDayChange(e.target.checked)
              }}
            />
          )}
          <label className="form-check-label">
            {dateFormatAsdddMMYYYY(value.day)}
          </label>
        </div>
        {isEditing ? (
          <>
            {!isSelectOnly && (
              <Flatpickr
                className="form-control from-date"
                placeholder="Start time"
                // disabled={true || !isChecked || isCurrentDay}
                value={startTime}
                onChange={e => {
                  handleStartTime(e)
                }}
                options={datePickerOptionsFrom}
              />
            )}
            {!isSelectOnly && (
              <Flatpickr
                className="form-control to-date"
                placeholder="End time"
                // disabled={true || !isChecked}
                value={endTime}
                onChange={e => {
                  handleEndTime(e)
                }}
                options={datePickerOptionsEnd}
              />
            )}
            <ButtonMain
              isLoading={isUpdatingButton}
              className="btn btn-small btn-secondary btn-secondary2"
              type="button"
              onClick={handleChangeTime}
            >
              Update
            </ButtonMain>
            {!isSelectOnly && (
              <div onClick={() => setIsEditing(false)} role="button">
                <h3 className="font-fontFamily fontSize-16">Cancel</h3>
              </div>
            )}
          </>
        ) : (
          <>
            {!isSelectOnly && (
              <Flatpickr
                className="form-control from-date"
                placeholder="Start time"
                disabled={true || !isChecked || isCurrentDay}
                value={startTime}
                onChange={e => {
                  handleStartTime(e)
                }}
                options={datePickerOptionsFrom}
              />
            )}
            {!isSelectOnly && (
              <Flatpickr
                className="form-control to-date"
                placeholder="End time"
                disabled={true || !isChecked}
                value={endTime}
                onChange={e => {
                  handleEndTime(e)
                }}
                options={datePickerOptionsEnd}
              />
            )}
            {!isSelectOnly && (
              <i className="fa fa-edit" onClick={() => setIsEditing(true)}></i>
            )}
          </>
        )}
        {!isSelectOnly && (
          <button
            className={`dates__show-hide-cta${
              !isChecked ? " dates__show-hide-cta--disabled" : ""
            }`}
            onClick={isChecked ? handleShowTimings : null}
            type="button"
          >{`${!showTiming ? "Show/Edit" : "Hide"} Timings`}</button>
        )}
      </div>
      {error && isChecked && (
        <label className="form-control--error">{error}</label>
      )}
      {error && isChecked && (
        <label className="form-control--error">{error}</label>
      )}

      {(showTiming || isSelectOnly) && isChecked && (
        <div className={`timings`}>
          {slots?.length
            ? slots.map((el, index) => {
                return (
                  <button
                    key={el.time}
                    type="button"
                    onClick={() =>
                      el.status !== "BLOCKED" && handleSelectTime(el._id, index)
                    }
                    className={classNames("btn btn-time", {
                      "btn-time--unavailable": el.status === "UNAVAILABLE",
                      "btn-time--blocked":
                        el.status === "BLOCKED" || el.time === selectedDate,
                    })}
                  >
                    {el.time}
                  </button>
                )
              })
            : null}
          {!isSelectOnly && !_.isEqual(slots, value.slots) && (
            <ButtonMain
              isLoading={isUpdatingButton}
              className="btn btn-small btn-secondary btn-secondary2 "
              type="button"
              onClick={handleChange}
            >
              Update
            </ButtonMain>
          )}
        </div>
      )}
      {!value.isAvailable && isSelectOnly && (
        <label className="form-control--error">Doctor not available</label>
      )}
    </div>
  )
}

export default DaySchedule