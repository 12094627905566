import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Row,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { GoogleLogin } from "react-google-login"
import CarouselPage from "./AuthenticationInner/CarouselPage"
import { google } from "../../config"

// import images
import logodark from "../../assets/images/supaw-vets.svg"
import logolight from "../../assets/images/supaw-vets.svg"

import googleIcon from "../../assets/images/auth/google.png"
import { useDispatch } from "react-redux"
import ButtonMain from "components/Common/Button"
import {
    getAllVetSpecialization,
    getLoggedInUserDetails,
    registerVet,
    sendEmailOTP,
    updateVet,
    verifyEmailOTP,
} from "helpers/api_helper"
// import landingImage from "../../assets/images/auth/Invoices_landingPage.png"
import landingImage from "../../assets/images/auth/Invoices_landingPage_new.png"
import GooglePlacesAutocomplete from "components/google-location"
import ReactSelect from "react-select"
import { modifySpecializations } from "helpers/utils"
import LocationModal from "./AuthenticationInner/locationModal"
import { showMessage } from "components/Notification"
import Stepper from "./AuthenticationInner/Stepper"
import appConstants from "../../constants/appConstants/appConstants.json";
import errorMessages from "../../constants/appConstants/errorMessages.json";
import OTPInput from "react-otp-input"
import { setGlobalLoading } from "store/slices"

const RegisterStep1VerifyEmail = () => {
    const [otp, setOtp] = useState('');
    const [userData, setUserData] = useState(null);
    const [isSendOTPLoading, setIsSendOTPLoading] = useState(false);

    const history = useHistory()
    const dispatch = useDispatch()

    //meta title
    document.title = "Register | Supaw"
    const id = localStorage.getItem("id")
    console.log("ID", id)

    useEffect(() => {
        try {        
            async function getUserDetails(){
                dispatch(setGlobalLoading(true));
                const response = await getLoggedInUserDetails({userId: id});
                dispatch(setGlobalLoading(false));

                if(response.status === 1){
                    setUserData(response.data);
                }
            } 
            getUserDetails();
        } catch (error) {
            console.error("Error getting user details:", error);
        }
    }, []);

    useEffect(() => {
        if(!!userData?.email && !!userData?.role){
            handleResendEmailOTP();
        }
    }, [userData]);

    console.log("User Data:::", userData);

    const handleResendEmailOTP = async () => {
        try {
            if(userData){
            dispatch(setGlobalLoading(true));
            setIsSendOTPLoading(true);
            const response = await sendEmailOTP({ email: userData.email, role: userData.role, userId: id});
            dispatch(setGlobalLoading(false));
            setIsSendOTPLoading(false);
            if(response.status === 1){
                showMessage({
                    label: `OTP sent successfully to ${userData.email}`,
                    status: "success",
                });
            }}
        } catch (error) {
            console.error("Error in handleResendEmailOTP:", error);
            setIsSendOTPLoading(false);
            showMessage({
                label: "Failed to send OTP",
                status: "error",
            });
        }
    }


    //form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        // enableReinitialize: true,

        initialValues: {
            stepOneVerifyEmail: true,
        },
        onSubmit: async values => {
            try {
                if (!otp?.length) {
                  showMessage({
                    label: errorMessages.pleaseEnterOTP,
                    status: "error",
                  })
                  return;
                }

                if(otp?.length !== 6){
                    showMessage({
                        label: errorMessages.otpMustHave6Characters,
                        status: "error",
                      });
                    return;
                }
                dispatch(setGlobalLoading(true));
                const response = await verifyEmailOTP({ email: userData?.email, otp: otp, userId: id });
                if (response.status == 1) {
                    const updateVetResponse = await updateVet(id, values);
                    if (updateVetResponse.status == 1) {
                        dispatch(setGlobalLoading(false));
                        history.push("/register-step2");
                    } else {
                        showMessage({
                            label: errorMessages.failedToVerifyOTP,
                            status: "error",
                        });
                    }

                } else {
                    console.error("Show error",response);
                    showMessage({
                        label: response?.message?.data?.message || errorMessages.failedToVerifyOTP,
                        status: "error",
                    });
                }
                dispatch(setGlobalLoading(false));
              } catch (error) {
                console.error("Erron in submit::::", error);
                let message = error?.response?.data?.message
                if (message && typeof message !== "string") {
                  message = errorMessages.somethingWentWrong
                }
                // setError(message)
                // setIsLoading(false)
                showMessage({
                  label: message || "",
                  status: "warning",
            })
            }
        },
    })

    return (
        <React.Fragment>
            <div>
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col xl={5}>
                            <div className="auth-full-page-content p-md-4 p-4 vh-100">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-4 onboarding-logo-container">
                                            <Link to="dashboard" className="d-block auth-logo">
                                                <img src={logodark} alt="" className="auth-logo-dark" />
                                                <img
                                                    src={logolight}
                                                    alt=""
                                                    className="auth-logo-light"
                                                />
                                            </Link>
                                        </div>
                                        <div style={{ padding: "0px 18%" }}>
                                            <div>
                                                <div className="mt-2 d-flex flex-column align-items-center w-100">
                                                    <h1>Add your clinic details</h1>
                                                    <p className="text-muted">
                                                        Start using askforvets for your vet clinic
                                                        management.
                                                    </p>
                                                </div>
                                            </div>
                                            <Stepper active={2} />

                                            
                                        </div>
                                        <div className="mt-2" style={{ padding: "0px 5%" }}>
                                            <Form
                                                style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center" }}
                                                onSubmit={e => {
                                                    e.preventDefault()
                                                    validation.handleSubmit()
                                                    return false
                                                }}>
                                                <p style={{ fontSize: "1.5rem", fontWeight: 700 }}>Enter Email Verification Code</p>
                                                <div style={{ width: "80%", margin: "1rem" }}>
                                                    <Input
                                                        name="Email"
                                                        value={userData?.email || ""}
                                                        className="form-control"
                                                        placeholder="Enter Email"
                                                        type="email"
                                                        disabled={true}
                                                        style={{ textAlign: "center" }}
                                                    />
                                                </div>
                                                <OTPInput
                                                    value={otp}
                                                    onChange={setOtp}
                                                    numInputs={6}
                                                    renderSeparator={<span className="separator"></span>}
                                                    renderInput={(props) => <input {...props} className="otp-input" />}
                                                    inputType="number"
                                                />

                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                    <ButtonMain className="btn btn-lg m-4" type={"submit"} disabled={isSendOTPLoading || otp?.length <= 0}style={{ background: 'var(--secondary-color)', color: "#fff"}}>Verify Email</ButtonMain>
                                                    <ButtonMain className="btn btn-lg m-4" onClick={handleResendEmailOTP} disabled={isSendOTPLoading} style={{ background: 'var(--primary-color)', color: "#fff"}}>
                                                        Resend OTP
                                                    </ButtonMain>
                                                </div>

                                            </Form>
                                            <div className="mt-4 text-center">
                                                <p>
                                                    Already have an account ?{" "}
                                                    <Link to="login" className="fw-medium text-primary">
                                                        {" "}
                                                        Login
                                                    </Link>{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <CarouselPage
                            landingPage={landingImage}
                            imageTitle="Front desk & finance"
                        />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default RegisterStep1VerifyEmail
