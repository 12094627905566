import React, { useState, useEffect, useReducer } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  FormGroup,
  CardText,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"

import { useHistory, useParams, withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import {
  fetchAppointmentsBasedOnId,
  getDiagnosisList,
  updateVisit,
} from "helpers/api_helper"
import ButtonMain from "components/Common/Button"
import { getDateDDMMYYYY } from "helpers/utils"
import Type from "./Type"
import {
  fetchAppointmentTypes,
  fetchDiagnosisList,
  getTodaysAppointments,
  setGlobalLoading,
} from "store/slices"
import { showMessage } from "components/Notification"
import { Link } from "react-router-dom"

const UserProfile = () => {
  //meta title
  document.title = "Visit Information | Supaw"

  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [appointment, setAppointment] = useState({})
  const [typeOfModal, setTypeOfModal] = useState("")
  const [primaryDiagnosis, setPrimaryDiagnosis] = useState([])
  const [patientAllergies, setPatientAllergies] = useState([])

  // const appointmentType = useSelector(getServices)
  // const [visitType, setVisitType] = useState(
  //   modifyAppointmentType(appointmentType)[0]
  // )
  const { id } = useParams()

  const reducer = (state, action) => {
    switch (action.type) {
      case "edit":
        return { ...state, [action.payload.name]: action.payload.value }
      default:
        return state
    }
  }

  const [values, dispatchReducer] = useReducer(reducer, {
    notes: appointment.notes,
    reasonForVisit: appointment.reasonForVisit,
    weight: appointment.weight || "",
    temperature: appointment.temperature || "",
  })

  console.log(values, "vvss")

  const stateChange = name => e => {
    const _value = e.target.value
    dispatchReducer({ type: "edit", payload: { name, value: _value } })
  }
  const history = useHistory()
  useEffect(() => {
    async function fetch() {
      if (id) {
        dispatch(setGlobalLoading(true))
        const response = await fetchAppointmentsBasedOnId(id)
        dispatch(setGlobalLoading(false))
        setAppointment(response.data)
        dispatchReducer({
          type: "edit",
          payload: { name: "notes", value: response?.data?.notes || "" },
        })
        if (
          response?.data?.status === "checkIn" ||
          response?.data?.status === "completed"
        ) {
          history.push(`/appointments`)
        }
      }
      dispatch(fetchAppointmentTypes())
      dispatch(fetchDiagnosisList())
    }
    fetch()
  }, [dispatch])

  if (!id || !appointment?._id) {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumb
              title="Appointments"
              titleLink="/appointments"
              breadcrumbItem="Visit Information"
            />

            <Row>
              <Card>
                <CardBody>
                  {/* <CardText>Nothing to show here yet!</CardText> */}
                </CardBody>
              </Card>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }

  const handleOpenModal = type => {
    setTypeOfModal(type)
  }

  const BackButton = () => {
    window.history.back()
  }

  const handleDataSet = (data, type) => {
    if (type !== "patientAllergies") {
      const _primaryDiagnosis = [...primaryDiagnosis]
      _primaryDiagnosis.push(data)
      setPrimaryDiagnosis(_primaryDiagnosis)
    } else {
      const _patientAllergies = [...patientAllergies]
      _patientAllergies.push(data)
      setPatientAllergies(_patientAllergies)
    }
    setTypeOfModal("")
  }

  const handleDelete = (title, type) => {
    const _primaryDiagnosis = [...primaryDiagnosis]
    const index = _primaryDiagnosis.findIndex(
      el => el.type === type && el.title === title
    )

    _primaryDiagnosis.splice(index, 1)
    setPrimaryDiagnosis(_primaryDiagnosis)
  }

  const handlePatientAllergiesDelete = index => {
    const _patientAllergies = [...patientAllergies]
    _patientAllergies.splice(index, 1)
    setPatientAllergies(_patientAllergies)
  }

  // const renderCells = type => {
  //   const array = primaryDiagnosis.filter(el => el.type === type)
  //   return (
  //     <div className="cell-tag-container">
  //       {array?.length ? (
  //         <>
  //           <h6>
  //             {type === "primary" ? "Primary Diagnosis" : "Secondary Diagnosis"}
  //             :
  //           </h6>
  //           <div className="cell-tags-roe">
  //             {array.map(({ title }, index) => {
  //               return (
  //                 <div key={`${title}-${index}`} className="cell-tags">
  //                   <button
  //                     className="cell-tag-close"
  //                     onClick={() => handleDelete(title, type)}
  //                   >
  //                     x
  //                   </button>
  //                   {title}
  //                 </div>
  //               )
  //             })}
  //           </div>
  //         </>
  //       ) : null}
  //     </div>
  //   )
  // }

  const renderCells = type => {
    const array = primaryDiagnosis.filter(el => el.type === type)
    return (
      <div className="cell-tag-container">
        {array?.length ? (
          <>
            <h6 className="cell-tag-heading">
              {type === "primary" ? "Primary Diagnosis" : "Secondary Diagnosis"}
              :
            </h6>
            <div className="cell-tags-row">
              {array.map(({ title }, index) => {
                return (
                  <div key={`${title}-${index}`} className="cell-tag">
                    <button
                      className="cell-tag-close"
                      onClick={() => handleDelete(title, type)}
                    >
                      &times;
                    </button>
                    {title}
                  </div>
                )
              })}
            </div>
          </>
        ) : null}
      </div>
    )
  }

  // const renderAllergies = () => {
  //   return (
  //     <div className="cell-tag-container">
  //       {patientAllergies?.length ? (
  //         <>
  //           <h6>Allergies:</h6>
  //           <div className="cell-tags-roe">
  //             {patientAllergies.map(({ title }, index) => {
  //               return (
  //                 <div key={`${title}-${index}`} className="cell-tags">
  //                   <button
  //                     className="cell-tag-close"
  //                     onClick={() => handlePatientAllergiesDelete(index)}
  //                   >
  //                     x
  //                   </button>
  //                   {title}
  //                 </div>
  //               )
  //             })}
  //           </div>
  //         </>
  //       ) : null}
  //     </div>
  //   )
  // }

  const renderAllergies = () => {
    return (
      <div className="cell-tag-container">
        {patientAllergies?.length ? (
          <>
            <h6>Allergies:</h6>
            <div className="cell-tags-row">
              {patientAllergies.map(({ title }, index) => {
                return (
                  <div key={`${title}-${index}`} className="cell-tag">
                    <button
                      className="cell-tag-close"
                      onClick={() => handlePatientAllergiesDelete(index)}
                    >
                      &times;
                    </button>
                    {title}
                  </div>
                )
              })}
            </div>
          </>
        ) : null}
      </div>
    )
  }

  const handleSubmit = async () => {
    if (!primaryDiagnosis.length || !patientAllergies.length) {
      showMessage({
        label: "Please add diagnosis or allergies if any *",
        status: "info",
      })
    }

    const obj = {
      ...values,
      basicVitals: {
        weight: values.weight,
        temperature: values.temperature,
      },
      primaryDiagnosis,
      patientAllergies,
      operativePlan: [],
      checkIn: new Date(),
    }

    // if (!values.notes) {
    //   showMessage({
    //     label: "Please add notes",
    //     status: "info",
    //   })
    //   return
    // }
    if (!values.weight) {
      showMessage({
        label: "Please add weight",
        status: "info",
      })
      return
    }
    if (!values.temperature) {
      showMessage({
        label: "Please add temperature",
        status: "info",
      })
      return
    }

    // if (!values.reasonForVisit) {
    //   showMessage({
    //     label: "Please add a reason for visit",
    //     status: "info",
    //   })
    //   return
    // }
    setIsLoading(true)
    const response = await updateVisit(id, obj)
    if (response.status == 1) {
      dispatch(getTodaysAppointments())
      showMessage({
        label: "Checked in Successfull",
        status: "success",
      })
      history.push(`/prescription/${id}`)
    }
    setIsLoading(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Supaw" breadcrumbItem="Visit Information" />
          {typeOfModal && (
            <Type
              type={typeOfModal}
              onConfirm={handleDataSet}
              onCloseClick={() => {
                setTypeOfModal("")
              }}
            />
          )}
          <Row>
            <Card>
              <CardBody>
                <Form
                  className="form-horizontal row"
                  onSubmit={e => {
                    e.preventDefault()
                    return false
                  }}
                >
                  <Col lg={7}>
                    <div className="row">
                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-4 text-left text-input-label font-fontFamily"
                      >
                        Name:
                      </Label>
                      <div className="col-lg-7">
                        <Label
                          htmlFor="projectdesc"
                          className="col-form-label col-lg-8 text-primary text-left text-input-label font-fontFamily"
                        >
                          {appointment?.petObjectId?.fullName}
                        </Label>
                      </div>
                    </div>
                    <div className="row">
                      <Label className="col-form-label col-lg-4 text-left text-input-label font-fontFamily">
                        Type:
                      </Label>
                      <div className="col-lg-7">
                        <Label className="col-form-label col-lg-8 text-primary text-left text-input-label font-fontFamily">
                          {appointment?.petObjectId?.petType}
                        </Label>
                      </div>
                    </div>
                    <div className="row">
                      <Label className="col-form-label col-lg-4 text-left text-input-label font-fontFamily">
                        Breed:
                      </Label>
                      <div className="col-lg-7">
                        <Label className="col-form-label col-lg-8 text-primary text-left text-input-label font-fontFamily">
                          {appointment?.petObjectId?.petBreed}
                        </Label>
                      </div>
                    </div>
                    <div className="row">
                      <Label className="col-form-label col-lg-4 text-left text-input-label font-fontFamily">
                        Gender:
                      </Label>
                      <div className="col-lg-7">
                        <Label
                          htmlFor="projectdesc"
                          className="col-form-label col-lg-8 text-primary text-left text-input-label font-fontFamily"
                        >
                          {appointment?.petObjectId?.gender}
                        </Label>
                      </div>
                    </div>
                    <div className="row">
                      <Label className="col-form-label col-lg-4 text-left text-input-label font-fontFamily">
                        Date of Birth:
                      </Label>
                      <div className="col-lg-7">
                        <Label
                          htmlFor="projectdesc"
                          className="col-form-label col-lg-8 text-primary text-left text-input-label font-fontFamily"
                        >
                          {getDateDDMMYYYY(
                            appointment?.petObjectId?.dateOfBirth
                          )}
                        </Label>
                      </div>
                    </div>
                  </Col>
                  <Col lg={5}>
                    <div className="col-lg-4" />
                    <div className="col-lg-8 d-flex justify-content-end">
                      <span className="gray-note font-fontFamily">
                        Unique Id:{" "}
                        <span className="gray-note--primary font-fontFamily">
                          {appointment?.petObjectId?.petId}
                        </span>
                      </span>
                    </div>
                  </Col>
                  <hr />
                  <Row>
                    <Col lg={6}>
                      <FormGroup className="mb-4 row align-items-center ">
                        <Label
                          htmlFor="projectdesc"
                          className="col-form-label col-lg-6 text-left text-input-label font-fontFamily"
                        >
                          Primary Diagnosis:
                        </Label>
                        <div className="col-lg-6">
                          <button
                            type="button"
                            onClick={() => handleOpenModal("primaryDiagnosis")}
                            className="btn btn-dark  btn-label"
                          >
                            <i className="bx bx-plus label-icon "></i> Add
                            diagnosis
                          </button>
                        </div>
                        {renderCells("primary")}
                        {renderCells("secondary")}
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup className="mb-4 row align-items-center">
                        <Label
                          htmlFor="projectdesc"
                          className="col-form-label col-lg-6 text-left text-input-label font-fontFamily"
                        >
                          Patient Allergies:
                        </Label>
                        <div className="col-lg-6">
                          <button
                            type="button"
                            onClick={() => handleOpenModal("patientAllergies")}
                            className="btn btn-dark  btn-label"
                          >
                            <i className="bx bx-plus label-icon "></i> Add
                            Patient Allergies
                          </button>
                        </div>
                        {renderAllergies()}
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg={6}>
                      <FormGroup className="mb-4 row">
                        <Label className="col-form-label col-lg-5 text-left text-input-label font-fontFamily">
                          Checkin
                        </Label>
                        <div className="col-lg-7">
                          <Label className="col-form-label col-lg-5 text-left text-primary text-input-label-value font-fontFamily">
                            {getDateDDMMYYYY(new Date())}
                          </Label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup className="row mb-9">
                        <Label
                          htmlFor="projectdesc"
                          className="col-form-label col-lg-5 text-left text-input-label font-fontFamily"
                        >
                          Appointment Type
                        </Label>
                        <div className="col-lg-7">
                          <Label className="col-form-label col-lg-5 text-left text-primary text-input-label-value font-fontFamily">
                            {appointment?.visitType}
                          </Label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="projectdesc"
                          className="col-form-label col-lg-4  text-left text-input-label text-input-label--md font-fontFamily"
                        >
                          Notes
                        </Label>
                        <div className="col-lg-8">
                          <textarea
                            disabled={appointment.notes}
                            className="form-control"
                            id="projectdesc"
                            style={{ resize: "none", minHeight: 120 }}
                            onChange={stateChange("notes")}
                            rows="16"
                            defaultValue={appointment.notes}
                            placeholder="Enter Notes"
                          />
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="projectdesc"
                          className="col-form-label col-lg-4  text-left text-input-label text-input-label--md font-fontFamily"
                        >
                          Reason For Visit
                        </Label>
                        <div className="col-lg-8">
                          <textarea
                            disabled={appointment.reasonForVisit}
                            className="form-control"
                            name="reasonForVisit"
                            style={{ resize: "none", minHeight: 120 }}
                            placeholder="Enter Reason for visit"
                            onChange={stateChange("reasonForVisit")}
                            rows="16"
                            value={appointment.reasonForVisit}
                            // defaultValue={appointment.reasonForVisit}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr />
                  <h4 className="mb-sm-0 font-size-18 font-fontFamily">
                    Basic Vitals
                  </h4>
                  <Row className="mt-20">
                    <Col lg={6}>
                      <FormGroup className="mb-4 row justify-item-between">
                        <Label
                          htmlFor="projectdesc"
                          className="col-form-label col-lg-5 text-left text-input-label font-fontFamily"
                        >
                          Weight (KG)<span className="text-danger">*</span>
                        </Label>
                        <div className="col-lg-4">
                          <Input
                            name="weight"
                            type="number"
                            step={0.1}
                            max={300} // Set maximum value
                            placeholder="Enter weight in KG"
                            min={1}
                            onChange={e => {
                              const value = e.target.value

                              if (value === "") {
                                stateChange("weight")({
                                  target: { name: e.target.name, value: "" },
                                }) // Allow clearing the input
                              } else if (parseFloat(value) <= 999) {
                                stateChange("weight")(e) // Update state if value is 300 or less
                              } else {
                                e.target.value = 999 // Set input value to 300 if it exceeds 300
                                stateChange("weight")({
                                  target: { name: e.target.name, value: 999 },
                                })
                              }
                            }}
                            onKeyDown={e => {
                              // Prevent entering non-numeric characters like 'e', '+', and '-'
                              if (
                                e.key === "e" ||
                                e.key === "E" ||
                                e.key === "+" ||
                                e.key === "-"
                              ) {
                                e.preventDefault()
                              }
                            }}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup className="row mb-9">
                        <Label
                          htmlFor="projectdesc"
                          className="col-form-label col-lg-5 text-left text-input-label text-input-label--md font-fontFamily"
                        >
                          Temperature (˚F)<span className="text-danger">*</span>
                        </Label>
                        {/* <div className="col-lg-7">
                          <Input
                            name="temperature"
                            // value={appointment.temperature || ""}
                            type={"number"}
                            step={0.1}
                            max={110}
                            placeholder="Enter Temperature degree (c)"
                            onChange={stateChange("temperature")}
                          />
                        </div> */}
                        <div className="col-lg-7">
                          <Input
                            name="temperature"
                            type="number"
                            step={0.1}
                            min={1}
                            placeholder="Enter Temperature degree in ˚F"
                            onChange={e => {
                              const value = e.target.value

                              if (value === "") {
                                stateChange("temperature")({
                                  target: { name: e.target.name, value: "" },
                                }) // Allow clearing the input
                              } else if (parseFloat(value) <= 300) {
                                stateChange("temperature")(e) // Update state if value is 200 or less
                              } else {
                                e.target.value = 300 // Set input value to 200 if it exceeds 200
                                stateChange("temperature")({
                                  target: { name: e.target.name, value: 300 },
                                })
                              }
                            }}
                            onKeyDown={e => {
                              // Prevent entering non-numeric characters like 'e', '+', and '-'
                              if (
                                e.key === "e" ||
                                e.key === "E" ||
                                e.key === "+" ||
                                e.key === "-"
                              ) {
                                e.preventDefault()
                              }
                            }}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-end mt-8">
                    <ButtonMain
                      className="btn btn-secondary btn-med"
                      type="button"
                      onClick={BackButton}
                    >
                      cancel
                    </ButtonMain>

                    <ButtonMain
                      isLoading={isLoading}
                      className="btn btn-primary btn-med"
                      type="button"
                      onClick={handleSubmit}
                    >
                      Checkin{" "}
                      <i className="mdi mdi-arrow-down" id="edittooltip" />
                    </ButtonMain>
                  </Row>
                </Form>
              </CardBody>
            </Card>
            {/* </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
