import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Col,
  CardTitle,
  Row,
} from "reactstrap"
import { Link } from "react-router-dom"
import classNames from "classnames"
import HistoryRecords from "./HistoryRecords"
import { getDateDDMMYYYY } from "helpers/utils"
import { IconSVG } from "components/Common/IconSvg"
import avatar from "../../../assets/images/users/pawprint.png"
import { BASE } from "helpers/api_helper"
import ImagingRecords from "./ImagingRecords"
import VaccinationRecords from "./VaccineRecords"
import ParentDetailsCard from "./ParentDetailsCard"
import classnames from "classnames"
import { calculateAge, capitalizeEveryWord, capitalizeFirstLetter } from "helpers/commonHelpers"
const NAVITEM = [
  { name: "Patient Details", id: "1" },
  { name: "Medical History", id: "2" },
  { name: "Vaccination History", id: "4" },
  { name: "Imaging Records", id: "3" },
  { name: "Lab Records", id: "5" },

  // { name: "Lab", id: "4" },
  // { name: "Vacinations", id: "5" },
  // { name: "Lab Records", id: "4" },
]

const PetCompleteDetails = ({
  petDetails,
  parentDetails,
  parentPets,
  verticalActiveTab,
  toggleVertical,
}) => {
  console.log("petDetails", petDetails)
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  const [expandedItems, setExpandedItems] = useState({})

  const toggleItem = index => {
    setExpandedItems(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }))
  }

  return (
    <Card>
      {/* <div className="history-doc-cta mb-4 d-flex align-items-center justify-content-between">
          <div>
            <h3>Pet Details</h3>
          </div>
          <Link
            to={`/add-appointment/pet/${petDetails._id}`}
            className="font-fontFamily"
            style={{ background: "#652B88", color: "white" }}
          >
            + Add Appointment
          </Link>
        </div> */}
      <Nav tabs className="parent-details nav-tabs-custom nav-justified mx-3" >
        {NAVITEM.map(({ name, id }) => {
          return (
            <NavItem key={id}>
              <NavLink
                style={{
                  cursor: "pointer",
                  borderBottom:
                    customActiveTab !== id ? "1px solid grey" : "none",
                  paddingBottom: "5px", // Optional: To add some space between the text and the border
                }}
                className={classNames({
                  active: customActiveTab === id,
                })}
                onClick={() => {
                  toggleCustom(id)
                }}
              >
                <span className="d-none d-sm-block font-fontFamily">
                  {name}
                </span>
              </NavLink>
            </NavItem>
          )
        })}
      </Nav>

      <TabContent activeTab={customActiveTab} className="p-3 text-muted">
        <TabPane tabId="1">
          <ParentDetailsCard
            parentDetails={parentDetails ? parentDetails : {}}
          />
          <Col md="12"></Col>
          <div className="parent-details__container">
            <>
              <CardTitle className="mb-4 d-flex align-items-center justify-content-between">
                <h3 className="font-fontFamily fontSize-24">
                  {parentDetails.fullName} Pet (s) ({parentPets?.length})
                </h3>
                <div className="history-doc-cta">
                  <Link
                    to={`/add-pet`}
                    className="font-fontFamily font-weight-text"
                  >
                    + Add Pets
                  </Link>
                </div>
              </CardTitle>

              <Nav pills className="flex-column">
                {parentPets?.length
                  ? parentPets.map((item, index) => {
                      const isExpanded = expandedItems[index]
                      return (
                        <>
                          <NavItem key={index}>
                            <NavLink
                              style={{
                                cursor: "pointer",
                                background: "#F5F5FA",
                                height: "75px",
                                marginBottom: "10px",
                              }}
                              
                            >
                              <div
                                style={{ width: "100%" }}
                                className="d-flex justify-content-between align-items-center"
                              >
                                <div className="d-flex align-items-center gap-3">
                                  {item.images[0] && (
                                    <img
                                      src={item.images[0]}
                                      style={{
                                        borderRadius: "0px",
                                        height: "36px",
                                        width: "36px",
                                      }}
                                    />
                                  )}
                                  <h3 className="font-fontFamily fontSize-18 mt-1">
                                    {item.fullName}
                                  </h3>
                                  {!isExpanded ? (
                                    
                                      <Link
                                        to={`/edit-patient/${item._id}`}
                                        className="font-fontFamily font-weight-text"
                                        style={{color:"grey"}}
                                      ><i className="fa fa-edit"></i></Link>
                                    
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div>
                                  <h3
                                    className="font-fontFamily font-color-gray"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {capitalizeEveryWord(`${item?.petType} ${calculateAge(item.dateOfBirth)} old`)}
                                  </h3>
                                </div>
                                <div className="mt-1 d-flex gap-2 align-items-center">
                                  {isExpanded ? (
                                    <Link
                                    to={`/edit-patient/${item._id}`}
                                    className="font-fontFamily font-weight-text"
                                    style={{color:"grey"}}
                                  ><i className="fa fa-edit"></i></Link>
                                  ) : (
                                    ""
                                  )}

                                  <i
                                    className={`fa ${
                                      isExpanded
                                        ? "fa-angle-up"
                                        : "fa-angle-down"
                                    } ml-auto fa-lg`}
                                    onClick={() => toggleItem(index)}
                                  ></i>
                                </div>
                              </div>
                            </NavLink>
                          </NavItem>
                          {isExpanded && (
                            <div className="petdetails mx-4">
                              <div className="d-flex justify-content-end"></div>
                              <Row className="my-2">
                                <Col sm={4}>
                                  {" "}
                                  <li>
                                    <h6 className="parent-details__title font-fontFamily">
                                      Pet Photo
                                    </h6>
                                    <span className="parent-details__value font-fontFamily">
                                      <img
                                        className="avatar-sm"
                                        src={
                                          item?.images[0]
                                            ? item?.images[0]
                                            : avatar
                                        }
                                        style={{
                                          height: "88px",
                                          width: "88px",
                                        }}
                                      />
                                    </span>
                                  </li>
                                </Col>
                                <Col sm={4}>
                                  {" "}
                                  <li>
                                    <h6 className="parent-details__title font-fontFamily">
                                      QR Code
                                    </h6>
                                    <span className="parent-details__value font-fontFamily">
                                      {/* <img
                                    className="avatar-sm"
                                    src={
                                      item?.images[0] ? item?.images[0] : avatar
                                    }
                                    style={{ height: "88px", width: "88px" }}
                                  /> */}
                                      <div style={{width:"88px",height:"88px",border:"1px dotted grey",textAlign:"center"}} className="d-flex justify-content-center align-items-center">
                                        <p>Coming Soon</p>
                                      </div>
                                    </span>
                                  </li>
                                </Col>
                                <Col sm={4}>
                                  <li className="my-2">
                                    <h6 className="parent-details__title font-fontFamily">
                                      Pet id
                                    </h6>

                                    <span className="parent-details__value font-fontFamily">
                                      {item?.petId}
                                    </span>
                                  </li>
                                  <li>
                                    <h6 className="parent-details__title font-fontFamily">
                                      Pet Breed
                                    </h6>
                                    <span className="parent-details__value font-fontFamily">
                                      {item?.petBreed}
                                    </span>
                                  </li>
                                </Col>
                              </Row>
                              <Row className="my-2">
                                <Col sm={4}>
                                  {" "}
                                  <li>
                                    <h6 className="parent-details__title font-fontFamily">
                                      Gender
                                    </h6>
                                    <span className="parent-details__value font-fontFamily">
                                      {item?.gender}
                                    </span>
                                  </li>
                                </Col>
                                <Col sm={4}>
                                  {" "}
                                  <li>
                                    <h6 className="parent-details__title font-fontFamily">
                                      Colour
                                    </h6>
                                    <span className="parent-details__value font-fontFamily">
                                      {item?.colour}
                                    </span>
                                  </li>
                                </Col>
                                <Col sm={4}>
                                  <li>
                                    <h6 className="parent-details__title font-fontFamily">
                                      Dob
                                    </h6>
                                    <span className="parent-details__value font-fontFamily">
                                      {getDateDDMMYYYY(item?.dateOfBirth)}
                                    </span>
                                  </li>
                                </Col>
                              </Row>
                              <Row className="my-2">
                                <Col sm={4}>
                                  {" "}
                                  <li>
                                    <h6 className="parent-details__title font-fontFamily">
                                      Weight
                                    </h6>
                                    <span className="parent-details__value font-fontFamily">
                                      {item?.weight} kg
                                    </span>
                                  </li>
                                </Col>
                                <Col sm={4}>
                                  {" "}
                                  <li>
                                    <h6 className="parent-details__title font-fontFamily">
                                      Adopted On
                                    </h6>
                                    <span className="parent-details__value font-fontFamily">
                                      {item?.adoptedOn ? getDateDDMMYYYY(item?.adoptedOn) : "N/A"}
                                    </span>
                                  </li>
                                </Col>
                                <Col sm={4}>
                                  <li>
                                    <h6 className="parent-details__title font-fontFamily">
                                      Microchip No
                                    </h6>
                                    <span className="parent-details__value font-fontFamily">
                                      {item?.microchipNo}
                                    </span>
                                  </li>
                                </Col>
                              </Row>
                              <Row className="my-2">
                                <Col sm={4}>
                                  {" "}
                                  <li>
                                    <h6 className="parent-details__title font-fontFamily">
                                      Full Name
                                    </h6>
                                    <span className="parent-details__value font-fontFamily">
                                      {item?.fullName}
                                    </span>
                                  </li>
                                </Col>
                                <Col sm={4}>
                                  {" "}
                                  <li>
                                    <h6 className="parent-details__title font-fontFamily">
                                      Pet Type
                                    </h6>
                                    <span className="parent-details__value font-fontFamily">
                                      {item?.petType}
                                    </span>
                                  </li>
                                </Col>
                                <Col sm={4}>
                                  <li>
                                    <h6 className="parent-details__title font-fontFamily">
                                      Spay/Neutered
                                    </h6>
                                    <span className="parent-details__value font-fontFamily">
                                      {item?.spay}
                                    </span>
                                  </li>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </>
                      )
                    })
                  : null}
              </Nav>
            </>
          </div>
        </TabPane>
        <TabPane tabId="2">
          <HistoryRecords petDetails={petDetails} parentPets={parentPets} />
        </TabPane>
        <TabPane tabId="3">
          <ImagingRecords petDetails={petDetails} parentPets={parentPets} testType="imaging"/>
        </TabPane>
        <TabPane tabId="4">
          <VaccinationRecords petDetails={petDetails} parentPets={parentPets} />
        </TabPane>
        <TabPane tabId="5">
          <ImagingRecords petDetails={petDetails} parentPets={parentPets} testType="labs"/>
        </TabPane>
      </TabContent>
    </Card>
  )
}

PetCompleteDetails.propTypes = {
  details: PropTypes.any,
}

export default PetCompleteDetails
