
import PropTypes from "prop-types"
import React from "react"
import Lottie from "react-lottie"
import { Modal, ModalBody, ModalFooter } from "reactstrap"

import * as animationData from "../../assets/images/lottie/loading.json"

const CommonModalHOC = ({ show, onActionClick, onCloseClick, title, actionBtnText, children, isLoading }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
          <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
              <Lottie options={defaultOptions} height={48} width={48} />
            </div>
          </div>
          <p className="text-muted font-size-16 mb-4">
            {title || "Are you sure you want to do this?"}
          </p>

          {children || <></>}

          <ModalFooter className="d-flex justify-content-center align-items-center">
            <div className="hstack gap-2 justify-content-center mb-0">
                <button
                    type="button"
                    className="btn btn-primary btn-secondary btn-med btn-lg ms-2"
                    onClick={onActionClick}
                    disabled={isLoading || false}
                >
                {actionBtnText || "Action"}
                </button>
                <button
                    type="button"
                    style={{ minWidth: 140 }}
                    className="btn btn-primary btn-lg ms-2"
                    onClick={onCloseClick}
                    disabled={isLoading || false}
                >
                Close
                </button>
            </div>
          </ModalFooter>
        </ModalBody>
      </div>
    </Modal>
  )
}

CommonModalHOC.propTypes = {
  onCloseClick: PropTypes.func,
  onActionClick: PropTypes.func,
  show: PropTypes.any,
  actionBtnText: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
}

export default CommonModalHOC
