import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  getAllParents,
  getParentList,
  getUserDetails,
  setGlobalLoading,
} from "../../store/slices"

import {
  OrderId,
  FormattedTime,
  Date,
  Total,
  PaymentStatus,
  //   PaymentMethod,
} from "../Pets/components/PatientsCol"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Col, Row, UncontrolledTooltip, Card, CardBody } from "reactstrap"
import { BASE, deleteParent, getImagingAndLap } from "helpers/api_helper"
import { IconSVG } from "components/Common/IconSvg"
import { modifyImagingAndLab } from "helpers/utils"
import Lightbox from "react-image-lightbox"
import LeftArrow from "../../assets/icons/ArrowLeft.png"
import { useLocation } from "react-router-dom"

function AddPharMacy() {
  //meta title

  document.title = "Pharmacy"

  const userDetails = useSelector(getUserDetails)
  const [list, setList] = useState([])
  const [photoIndex, setphotoIndex] = useState(0)
  const [isGalleryZoom, setisGalleryZoom] = useState(false)
  const [currentScans, setCurrentScans] = useState([])
  const history = useHistory()
  const location = useLocation()

  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setGlobalLoading(true))
      const list = await getImagingAndLap({
        doctorId: userDetails._id,
        testType: "imaging",
      })
      setList(list.data.filter(ell => ell.status === "Completed"))
      dispatch(setGlobalLoading(false))
    }
    fetchData()
  }, [dispatch])

  const handleAddNewRequest = route => {
    history.push("/add-imaging-request")
    if (location.pathname === "/pharmacy-request") {
      history.push(route)
    }
  }
  const Cost = cell => {
    if (typeof cell.value === "number") {
      return "Rs " + Number(cell.value)
    }
    return ""
  }

  const columns = useMemo(
    () => [
      {
        Header: "Prescription Date ",
        accessor: "createdAt",
        filterable: true,
        Cell: cellProps => {
          return <FormattedTime {...cellProps} />
        },
      },
      // {
      //   Header: "Requested By",
      //   accessor: "email",
      //   filterable: true,
      //   Cell: cellProps => {
      //     return <Date {...cellProps} />
      //   },
      // },
      {
        Header: "Patient Name",
        accessor: "petObjectId.fullName",
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Appointment Type",
        accessor: "type",
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Medicine",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Quantity",
        accessor: "pricing",
        filterable: true,
        Cell: cellProps => {
          return <Cost {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3 justify-content-end">
              {/* <Link
                to={`/parent/${cellProps.row.original._id}`}
                className="text-black"
              >
                <i id="viewtooltip">
                  <IconSVG icon="more" />
                </i>

                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link> */}

              <div>
                {cellProps.row.original.scansOrReports &&
                cellProps.row.original.scansOrReports.length > 0 ? (
                  <Link
                    to={`#`}
                    className="text-success"
                    onClick={() => {
                      setCurrentScans(cellProps.row.original.scansOrReports)
                      setisGalleryZoom(true)
                      setphotoIndex(0)
                    }}
                  >
                    <i id="viewScansTooltip">
                      <IconSVG icon="more" />
                    </i>
                  </Link>
                ) : (
                  <span className="text-muted">
                    <i id="noScansTooltip">
                      <IconSVG icon="more" />
                    </i>
                  </span>
                )}

                {cellProps.row.original.scansOrReports &&
                cellProps.row.original.scansOrReports.length > 0 ? (
                  <UncontrolledTooltip
                    placement="top"
                    target="viewScansTooltip"
                  >
                    View Scans
                  </UncontrolledTooltip>
                ) : (
                  <UncontrolledTooltip placement="top" target="noScansTooltip">
                    No Scans Available
                  </UncontrolledTooltip>
                )}
              </div>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="mx-2 mb-4 ">
            <Col
              className="d-flex align-items-center justify-content-start"
              md={12}
            >
              <img
                src={LeftArrow}
                width={36}
                onClick={() => history.goBack()}
                role="button"
              />
              <h4 className="mb-0 font-fontFamily fontSize-36">
                Pharmacy Requests Completed
              </h4>
            </Col>

            <Col></Col>
          </Row>

          {isGalleryZoom ? (
            <Lightbox
              mainSrc={currentScans[photoIndex]}
              nextSrc={currentScans[(photoIndex + 1) % currentScans.length]}
              prevSrc={
                currentScans[
                  (photoIndex + currentScans.length - 1) % currentScans.length
                ]
              }
              onCloseRequest={() => {
                setisGalleryZoom(false)
              }}
              onMovePrevRequest={() => {
                setphotoIndex(
                  (photoIndex + currentScans.length - 1) % currentScans.length
                )
              }}
              onMoveNextRequest={() => {
                setphotoIndex((photoIndex + 1) % currentScans.length)
              }}
            />
          ) : null}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={list}
                    isGlobalFilter={false}
                    isAddOptions={true}
                    handleOrderClicks={handleAddNewRequest}
                    customPageSize={10}
                    emptyListLink="/add-pharmacy-request"
                    emptyText="No requests found"
                    emptyLinkText="+ New Request"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
AddPharMacy.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default AddPharMacy
