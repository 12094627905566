import { Autocomplete, useJsApiLoader } from "@react-google-maps/api"
// import { LocationInput } from '@/types';
import React from "react"
import { Col, Row } from "reactstrap"
// import { useTranslation } from 'next-i18next';
// import Loader from '@/components/ui/loader/loader';

const libraries = ["places"]

export default function GooglePlacesAutocomplete({
  onChange,
  data,
  disabled = false,
}) {
  //   const { t } = useTranslation();
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google_map_autocomplete",
    googleMapsApiKey: "AIzaSyBskR2t4VZZ_ptWYPSmwoG12Xna2mH8iU0",
    libraries,
  })

  const [autocomplete, setAutocomplete] = React.useState(null)

  const onLoad = React.useCallback(function callback(autocompleteInstance) {
    setAutocomplete(autocompleteInstance)
  }, [])

  const onUnmount = React.useCallback(function callback() {
    setAutocomplete(null)
  }, [])

  const onPlaceChanged = () => {
    const place = autocomplete.getPlace()

    if (!place.geometry || !place.geometry.location) {
      return
    }
    const location = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      formattedAddress: place.formatted_address,
    }

    for (const component of place.address_components) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0]

      switch (componentType) {
        case "postal_code": {
          location["zip"] = component.long_name
          break
        }

        case "postal_code_suffix": {
          location["zip"] = `${location?.zip}-${component.long_name}`
          break
        }

        case "locality":
          location["city"] = component.long_name
          break

        case "administrative_area_level_1": {
          location["state"] = component.short_name
          break
        }

        case "country":
          location["country"] = component.long_name
          break
      }
    }
    if (onChange) {
      onChange(location)
    }
  }

  const onFetchLiveLocation = () => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords

          // Use Google Maps Geocoding API to get the formatted address
          const geocoder = new window.google.maps.Geocoder()
          const latlng = { lat: latitude, lng: longitude }

          geocoder.geocode({ location: latlng }, (results, status) => {
            if (status === "OK" && results[0]) {
              const location = {
                lat: latitude,
                lng: longitude,
                formattedAddress: results[0].formatted_address,
              }

              // Call the onChange function with the live location data
              if (onChange) {
                onChange(location)
              }
            } else {
              console.error("Geocoder failed:", status)
            }
          })
        },
        error => {
          console.error("Error fetching live location:", error)
        }
      )
    } else {
      console.error("Geolocation is not supported by this browser.")
    }
  }

  if (loadError) {
    return <div>{t("common:text-map-cant-load")}</div>
  }
  return isLoaded ? (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
      onUnmount={onUnmount}
      fields={["address_components", "geometry.location", "formatted_address"]}
      // types={['address']}
    >
      <Row>
        <Col xs={9}>
          <input
            type="text"
            placeholder={"Search your location"}
            defaultValue={data}
            className={`form-control ${
              disabled ? "cursor-not-allowed bg-[#EEF1F4] border-[#D4D8DD]" : ""
            }`}
            disabled={disabled}
          />
        </Col>
        <Col xs={3} style={{ paddingLeft: "24px", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <button
            type="button"
            onClick={onFetchLiveLocation}
            className="btn btn-primary"
          >
            Live
          </button>
        </Col>
      </Row>
    </Autocomplete>
  ) : (
    // '<Loader simple={true} className="h-6 w-6" />'
    ""
  )
}