import React, { Fragment, useMemo } from 'react';
import { Link } from "react-router-dom";
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
  } from "react-table";
import { Table, Row, Col, Button, Input, CardBody } from "reactstrap"

import { Total } from 'pages/Parents/components/PatientsCol';
import { IconSVG } from './IconSvg';
import TableContainer from "../../components/Common/TableContainer"
import { DefaultColumnFilter } from './filters';

const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

const PrescriptionImagingAndLabTestListing = ({ data, deleteClickHandler = () => {} }) => {
    const columns = useMemo(
        () => [
          {
            Header: "Test Name",
            accessor: "type",
            filterable: true,
            Cell: cellProps => {
              return <Total {...cellProps} />
            },
          },
          {
            Header: "Test Type",
            accessor: "testType",
            filterable: true,
            Cell: cellProps => {
              return <Total {...cellProps} />
            },
          },
          {
            Header: "Notes",
            accessor: "notes",
            filterable: true,
            Cell: cellProps => {
              return <Total {...cellProps} />
            },
          },
          {
            Header: "Action",
            accessor: "action",
            disableFilters: true,
            className: "text-left",
            style: {
              textAlign: "left",
              background: "#0000",
            },
            Cell: cellProps => {
              return (
                <div
                  className="d-flex gap-3 justify-content-end"
                >
                  <Link
                    to="#"
                    className="text-success"
                    onClick={() => deleteClickHandler(cellProps?.row?.original)}
                    data-bs-toggle="tooltip" 
                    data-bs-placement="top" 
                    title="Delete"
                  >
                    <i>
                      <IconSVG icon="delete" />
                    </i>
                  </Link>
                </div>
              )
            },
          },
        ],
        []
      );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
    
        state: { pageIndex, pageSize },
      } = useTable(
        {
          columns,
          data,
          defaultColumn: { Filter: DefaultColumnFilter },
          initialState: {
            pageIndex: 0,
            pageSize: 10,
            sortBy: [
              {
                desc: true,
              },
            ],
          },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination
      )
    
  return (
    // <TableContainer
    //     columns={columns}
    //     data={data || []}
    //     isGlobalFilter={false}
    //     isAddOptions={false}
    //     // handleOrderClicks={handleOrderClicks}
    //     // emptyListLink="/add-pet"
    //     // emptyText="No Pet found"
    //     // emptyLinkText="+ New Patient"
    //     customPageSize={10}
    // />
    <div className="table-responsive react-table">
        <Table hover {...getTableProps()}>
          <thead className="table-light table-nowrap ">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div
                      className="title font-fontFamily"
                      {...column.getSortByToggleProps()}
                    >
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    {/* <Filter column={column} /> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              console.log("row", row.cells[0].id)
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td
                          key={cell.id}
                          {...cell.getCellProps()}
                          style={{ paddingLeft: "17px", textWrap: "nowrap" }}
                          className="font-fontFamily"
                        >
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>
  )
}

export default PrescriptionImagingAndLabTestListing