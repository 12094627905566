import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import {
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { GoogleLogin } from "react-google-login"
import CarouselPage from "./AuthenticationInner/CarouselPage"
import { google } from "../../config"

// import images
import logodark from "../../assets/images/supaw-vets.svg"
import logolight from "../../assets/images/supaw-vets.svg"

import googleIcon from "../../assets/images/auth/google.png"
import { useDispatch, useSelector } from "react-redux"
import ButtonMain from "components/Common/Button"
import {
  getAllVetSpecialization,
  getLoggedInUserDetails,
  registerVet,
  sendMobileVerificationOTP,
  updateVet,
  verifyMobileOTP,
} from "helpers/api_helper"
// import landingImage from "../../assets/images/auth/Invoices_landingPage.png"
import landingImage from "../../assets/images/auth/Invoices_landingPage_new.png"
import GooglePlacesAutocomplete from "components/google-location"
import ReactSelect from "react-select"
import { modifySpecializations } from "helpers/utils"
import LocationModal from "./AuthenticationInner/locationModal"
import { showMessage } from "components/Notification"
import Stepper from "./AuthenticationInner/Stepper"
import appConstants from "../../constants/appConstants/appConstants.json";
import { getIsLoading, getUserDetails, setGlobalLoading } from "store/slices";
import errorMessages from "../../constants/appConstants/errorMessages.json";
import CommonModalHOC from "components/Common/CommonModalHOC"
import OTPInput from "react-otp-input";
import successMessages from "../../constants/appConstants/successMessages.json";
import { TiTick } from "react-icons/ti";

const RegisterStep2 = () => {
  //meta title
  document.title = "Register | Supaw"
  const id = localStorage.getItem("id");

  const history = useHistory()
  const dispatch = useDispatch()
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [latLong, setLetLong] = useState([])
  const [address, setAddress] = useState("")
  const [floor, setFloor] = useState(null)
  const [landMark, setLandMark] = useState("")
  const [completeAddress, setCompleteAddress] = useState("")
  const [country, setCountry] = useState("")
  const [state, setState] = useState("")
  const [city, setCity] = useState("")
  const [pinCode, setPinCode] = useState("")
  const [Specializations, setSpecializations] = useState([])
  const [selectedSpecializations, setSelectedSpecialization] = useState([]);
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [userData, setUserData] = useState(null);

  const [addressDetailsObject, setAddressDetailsObject] = useState({});
  const [isPhoneVerificationModelOpen, setIsPhoneVerificationModelOpen] = useState(false);
  const [otp, setOtp] = useState('');
  
  const globalLoading = useSelector(getIsLoading);

  useEffect(() => {
          try {        
              async function getUserDetails(){
                  dispatch(setGlobalLoading(true));
                  const response = await getLoggedInUserDetails({userId: id});
                  dispatch(setGlobalLoading(false));
  
                  if(response.status === 1){
                      setUserData(response.data);
                  }
              } 
              getUserDetails();
          } catch (error) {
              console.error("Error getting user details:", error);
          }
      }, []);

  // Callback function to add imaging test values to the array
  const handleAddressValues = values => {
    setAddressDetailsObject(prevValues => {
      // Create a new object by spreading the previous values and adding the new values
      return { ...prevValues, ...values }
    })
  }

  useEffect(() => {
    // Check if addressDetailsObject is not empty
    if (Object.keys(addressDetailsObject).length !== 0) {
      // Update the floor state with the value from addressDetailsObject
      setFloor(addressDetailsObject.floor)
      setLandMark(addressDetailsObject.landMark)
      setCompleteAddress(addressDetailsObject.completeAddress)
      setCountry(addressDetailsObject.country)
      setState(addressDetailsObject.state)
      setCity(addressDetailsObject.city)
      setPinCode(addressDetailsObject.pinCode)
    }
  }, [addressDetailsObject])

  // const userId=JSON.parse(id)
  //form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    // enableReinitialize: true,

    initialValues: {
      phone: "",
      specialization: selectedSpecializations,
      location: {
        type: "Point",
        coordinates: [],
      },
      address,
      floor: "",
      landmark: "",
      completeAddress: "",
      pinCode: "",
      state: "",
      latitude: 0,
      longitude: 0,
      stepTwo: true,
      clinicName: "",
      instructions: "step1",
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .required(errorMessages.pleaseEnterPhoneNumber)
        .matches(/^\d{10}$/, errorMessages.phoneNumberMustBeOf10Digits),
      address: Yup.string().required("Please Enter Your address"),
      clinicName: Yup.string().required("Please Enter Your Clinic Name"),
    }),
    onSubmit: async values => {
      try {
        if (!selectedSpecializations?.length) {
          showMessage({
            label: "Please select specialization(s)",
            status: "warning",
          })
          return
        }
        values.role = appConstants.USER_ROLES.VET
        values.floor = floor
        values.landmark = landMark
        values.city = city
        values.country = country
        values.state = state
        values.pinCode = pinCode
        values.completeAddress = completeAddress
        values.latitude = latLong[1]
        values.longitude = latLong[0]
        values.location = {
          type: "Point",
          coordinates: [latLong[1], latLong[0]],
        }
        setIsLoading(true)
        const response = await updateVet(id, values)
        if (response.status == 1) {
          history.push(
            "/register-step3?successMessage=Registered Successfully. Login to continue"
          )
        }
      } catch (error) {
        let message = error?.response?.data?.message
        if (message && typeof message !== "string") {
          message = "something went wrong!"
        }
        setError(message)
        setIsLoading(false)
        showMessage({
          label: message,
          status: "warning",
        })
      }
    },
  })

  useEffect(() => {
    if(validation.values.phone && String(validation.values.phone).length > 0) {
      if(isMobileVerified){
        setIsMobileVerified(false);
      }
      setOtp("");
    }

  }, [validation.values.phone])

  const handleLocation = e => {
    setLetLong([e.lng, e.lat])
    setAddress(e.formattedAddress)
    handleOpenModal()
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getAllVetSpecialization()
        setSpecializations(modifySpecializations(res.data))
      } catch (error) {
        // Handle any errors that occur during fetching or data processing
        console.error(error)
      }
    }

    fetchData()
  }, [dispatch]);

  useEffect(() => {
    // Update the "specialization" field value in the validation object
    validation.setFieldValue("specialization", selectedSpecializations, false)
  }, [selectedSpecializations]);

  useEffect(() => {
    // Update the "appointmentType" field value in the validation object
    validation.setFieldValue("address", address, true)
  }, [latLong])

  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false)
  const handleOpenModal = () => {
    setIsLocationModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsLocationModalOpen(false)
  }

  const handleMobileVerifyModal = async () => {
    try {
      if(!validation.values.phone){
        showMessage({
          label: errorMessages.pleaseEnterPhoneNumber,
          status: "warning",
        })
        return;
      }

      if(String(validation.values.phone).length !== 10){
        showMessage({
          label: errorMessages.phoneNumberMustBeOf10Digits,
          status: "warning",
        })
        return;
      }
      dispatch(setGlobalLoading(true));
      const response = await sendMobileVerificationOTP({ countryCode: userData?.countryCode || "+91", userId: id, phone: validation.values.phone});
      dispatch(setGlobalLoading(false));
      if(response?.status === 1){
        showMessage({
          label: `Verification OTP is sent to ${userData?.countryCode}-${validation.values.phone}`,
          status: "success",
        });
        setIsPhoneVerificationModelOpen(true);
      } else {
        showMessage({
          label: JSON.stringify(response?.message) || errorMessages.failedToSendOTP,
          status: "error",
        })
      }
      
    } catch (error) {
      console.error("Error in handleMobileVerify:",error);
    }
  }

  console.log("OTP:::::", otp)

  const handlePhoneVerify = async () => {
    try {

      console.log("Handle Phone Verification");
      if (!otp?.length) {
        showMessage({
          label: errorMessages.pleaseEnterOTP,
          status: "error",
        })
        return;
      }

      if (otp?.length !== 6) {
        showMessage({
          label: errorMessages.otpMustHave6Characters,
          status: "error",
        });
        return;
      }

      dispatch(setGlobalLoading(true));
      const response = await verifyMobileOTP({ countryCode: userData?.countryCode || '+91', phone: validation.values.phone, otp: otp, userId: id });
      if (response.status == 1) {
        showMessage({
          label: successMessages.phoneVerifiedSuccessfully,
          status: "success",
        });
        setIsMobileVerified(true);
        setIsPhoneVerificationModelOpen(false);
        setOtp('');
      } else {
        console.error("Show error", response);
        setOtp('');
        showMessage({
          label: response?.message?.data?.message || errorMessages.failedToVerifyOTP,
          status: "error",
        });
      }
      dispatch(setGlobalLoading(false));
    } catch (error) {
      setOtp('');
      console.error("Error in handlePhoneVerify:", error);
    }
  }


  return (
    <React.Fragment>
      <div>
        <LocationModal
          onChange={handleLocation}
          isOpen={isLocationModalOpen}
          onClose={handleCloseModal}
          latLong={latLong}
          data={address}
          onAddressValuesSubmit={handleAddressValues}
        />
        <CommonModalHOC
          show={isPhoneVerificationModelOpen}
          onCloseClick={() => setIsPhoneVerificationModelOpen(false)}
          title={appConstants.ENTER_OTP || ""}
          actionBtnText={appConstants.VERIFY_OTP}
          onActionClick={handlePhoneVerify}
          isLoading={globalLoading || isLoading}
        >
          <div className="d-flex justify-content-center align-items-center">
            <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span className="separator"></span>}
                renderInput={(props) => <input {...props} className="otp-input" />}
                inputType="number"
            />
          </div>
        </CommonModalHOC>

        <Container fluid className="p-0">
          <Row className="g-0">
            <Col xl={5}>
              <div className="auth-full-page-content p-md-4 p-4 vh-100">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-4 onboarding-logo-container">
                      <Link to="dashboard" className="d-block auth-logo">
                        <img src={logodark} alt="" className="auth-logo-dark" />
                        <img
                          src={logolight}
                          alt=""
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div style={{ padding: "0px 18%" }}>
                      <div>
                        {/* <p className="font-fontFamily fontSize-16 font-color-purple">
                          Step 2
                        </p> */}

                        <div className="mt-2 d-flex flex-column align-items-center w-100">
                          <h1>Add your clinic details</h1>
                          <p className="text-muted">
                            Start using askforvets for your vet clinic
                            management.
                          </p>
                        </div>
                      </div>
                      <Stepper active={3} />

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3">
                            <Input
                              name="clinicName"
                              // value={name}
                              className="form-control"
                              placeholder="Enter Clinic Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.clinicName || ""}
                              invalid={
                                validation.touched.clinicName &&
                                validation.errors.clinicName
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.clinicName &&
                            validation.errors.clinicName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.clinicName}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <div className="d-flex justify-content-center align-items-center">
                              <Input
                                name="phone"
                                // value={name}
                                className="form-control"
                                placeholder="Enter Phone"
                                type="number"
                                min="10"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone || ""}
                                invalid={
                                  validation.touched.phone &&
                                  validation.errors.phone
                                    ? true
                                    : false
                                }
                              />
                              <div style={{ margin: "10px"}}>
                                {
                                  isMobileVerified 
                                  ? (
                                  <div className="d-flex justify-content-center align-items-center" style={{ height: "30px", width: "30px", backgroundColor: "#3E7D3E", border: "1px solid transparent", borderRadius: '50%'}}>
                                    <TiTick color={'#fff'} size={25}/>
                                  </div>
                                  )
                                  : (<a className="text-nowrap" onClick={handleMobileVerifyModal}>Verify Mobile *</a>)
                                }
                              </div>
                            </div>
                            {validation.touched.phone &&
                            validation.errors.phone ? (
                              <FormFeedback type="invalid">
                                {validation.errors.phone}
                              </FormFeedback>
                            ) : null}
                          </div>
                          {/* <div className="mb-3">
                            
                            <Input
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Your Email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div> */}
                          <div className="mb-3">
                            <GooglePlacesAutocomplete
                              onChange={handleLocation}

                              // data={validation.values.address}
                            />
                          </div>
                          <div className="mb-3">
                            <Input
                              name="address"
                              // value={name}
                              className="form-control"
                              placeholder="Address"
                              type="text"
                              disabled
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.address || ""}
                              invalid={
                                validation.touched.address &&
                                validation.errors.address
                                  ? true
                                  : false
                              }
                            />

                            {validation.touched.address &&
                            validation.errors.address ? (
                              <FormFeedback type="invalid">
                                {validation.errors.address}
                              </FormFeedback>
                            ) : null}
                          </div>
                          {/* xpecto Developer 6/7/2024*/}
                          <div className="mb-3">
                            <ReactSelect
                              isMulti
                              value={selectedSpecializations}
                              onChange={setSelectedSpecialization}
                              options={Specializations}
                              classNamePrefix="form-react-select"
                              className="form-react-select"
                              placeholder="Select Specializations"
                              closeMenuOnSelect={false}
                            />
                          </div>
                          {/* xpecto Developer 6/7/2024*/}
                          {/* <div className="mb-3">
                            
                            <Input
                              name="fullName"
                              type="text"
                              placeholder="Your Full Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.fullName || ""}
                              invalid={
                                validation.touched.fullName &&
                                validation.errors.fullName
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.fullName &&
                            validation.errors.fullName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.fullName}
                              </FormFeedback>
                            ) : null}
                          </div> */}
                          {/* <div className="mb-3">
                            
                            <Input
                              name="password"
                              type="password"
                              placeholder="Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div> */}
                          {/* <div className="mb-3">
                            
                            <Input
                              name="confirmPassword"
                              type="password"
                              placeholder="Re-enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.confirmPassword || ""}
                              invalid={
                                validation.touched.confirmPassword &&
                                validation.errors.confirmPassword
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.confirmPassword &&
                            validation.errors.confirmPassword ? (
                              <FormFeedback type="invalid">
                                {validation.errors.confirmPassword}
                              </FormFeedback>
                            ) : null}
                          </div> */}
                          {/* <div>
                            <p className="mb-0">
                              By registering you agree to the Supaw{" "}
                              <a href="#" className="text-primary">
                                Terms of Use
                              </a>
                            </p>
                          </div> */}

                          <div className="mt-4 d-grid">
                            <ButtonMain
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                              isLoading={isLoading}
                              disabled={!isMobileVerified}
                            >
                              Next
                            </ButtonMain>
                          </div>
                        </Form>
                        {/* <ButtonMain
                          className="btn btn-primary waves-effect waves-light"
                          type="button"
                          isLoading={isLoading}
                          onClick={() => history.push("/register-step3")}
                        >
                          Next
                        </ButtonMain> */}
                        <Form action="dashboard">
                          <div className="text-center">
                            {/* <hr data-text="OR" /> */}

                            {/* <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="auth-google"
                                  onClick={renderProps.onClick}
                                >
                                  <img src={googleIcon} /> Sign in with Google
                                </Link>
                              )}
                              onSuccess={googleResponse}
                              onFailure={() => {}}
                            /> */}
                          </div>
                        </Form>

                        <div className="mt-2 text-center">
                          <p>
                            Already have an account ?{" "}
                            <Link to="login" className="fw-medium text-primary">
                              {" "}
                              Login
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage
              landingPage={landingImage}
              imageTitle="Front desk & finance"
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RegisterStep2
