import React, { useState, useEffect, useReducer } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Form,
  FormGroup,
} from "reactstrap"

import { useSelector, useDispatch } from "react-redux"

import { Link, useHistory, useParams, withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

// action
import ReactSelect from "react-select"
import ButtonMain from "components/Common/Button"
import {
  createpricing,
  getPricingItemById,
  updatepricing,
} from "helpers/api_helper"
import {
  getCategoryOfPricing,
  getPricingCategory,
  getPricingDetails,
  getUserDetails,
  setGlobalLoading,
} from "store/slices"
import {
  modifyAppointmentType,
  modifyPricingType,
  timestampToDatetimeInputString,
} from "helpers/utils"
import { showMessage } from "components/Notification"
import * as Yup from "yup"
import { useFormik } from "formik"
import LeftArrow from "../../assets/icons/ArrowLeft.png"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import appConstants from "../../constants/appConstants/appConstants.json";

const AddPayment = () => {
  const history = useHistory()
  const [details, setDetails] = useState({})
  const [itemName, setItemName] = useState({})
  const [category, setCategory] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const userDetails = useSelector(getUserDetails)
  const allCategories = useSelector(getCategoryOfPricing)
  // console.log(modifyInventoryType(allCategories));
  const { itemId } = useParams() // For Editing appointment

  const { priceCategory } = useParams()
  console.log("itemId:", itemId)
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchCategoryData() {
      if (userDetails?._id) {
        dispatch(setGlobalLoading(true))
        await dispatch(getPricingCategory({ vetId: userDetails?._id }))
        dispatch(setGlobalLoading(false))
      }
    }
    fetchCategoryData()
  }, [dispatch])

  useEffect(() => {
    async function fetchItemData() {
      console.log("working")
      const _details = await getPricingItemById(itemId)
      console.log(_details.data)
      setDetails(_details.data)
      setCategory({
        label: _details.data.category.pricingCategoryName,
        value: _details.data.category._id,
      })
      setTestType({
        label: _details.data.testingType,
        value: _details.data.testingType,
      })
      setItemName({
        label: _details.data.name,
        value: _details.data.name,
      })
    }
    fetchItemData()
  }, [itemId])
  console.log("allCategories", allCategories)
  //Validations
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      amount:details.amount || "",
      paymentDate:details.date || "",
      transactionId:details.transactionId || "",
      notes:details.notes || ""
    },
    validationSchema: Yup.object({
      amount:Yup.number().required(),
      paymentDate:Yup.string().required(),
      transactionId:Yup.string().required(),
    }),
    onSubmit: async values => {
      if (!testType.value) {
        showMessage({ label: "Please Select Test Type", status: "info" })
        return
      }
      if (!itemName.value) {
        showMessage({ label: "Please Select ItemName", status: "info" })
        return
      }

      const obj = {
        vetId: userDetails._id,
        name: itemName.value,
        pricing: values.Price,
        category: category[0].value,
        testingType: testType.value,
        description: values.description,
      }

      console.log(obj)

      setIsLoading(true)
      try {
        let response = {}
        if (!itemId) {
          response = await createpricing(obj)
        } else {
          response = await updatepricing(itemId, obj)
        }

        if (response.status == 1) {
          console.log("response ", response)
          history.push("/pricing")
          dispatch(getPricingDetails())
        } else {
          showMessage({
            label: `Something went wrong, try again`,
            status: "error",
          })
        }
      } catch (error) {
        showMessage({
          label: `Something went wrong, try again`,
          status: "error",
        })
      }
      setIsLoading(false)
    },
  })

  // handle AddCategoryButton

  const handleAddCategoryButton = () => {
    history.push("/pricing-Category")
  }

  const ConsultationNameOptions = appConstants.APPOINTMENT_TYPES;

  const ImagingNameOptions = [
    {
      value: "X-ray",
      label: "X-ray",
      type: "Radiography",
    },
    {
      value: "Ultrasound",
      label: "Ultrasound",
      type: "Ultrasonography",
    },
    {
      value: "Computed Tomography (CT)",
      label: "Computed Tomography (CT)",
      type: "Radiography",
    },
    {
      value: "Magnetic Resonance Imaging (MRI)",
      label: "Magnetic Resonance Imaging (MRI)",
      type: "Radiography",
    },
    {
      value: "Fluoroscopy",
      label: "Fluoroscopy",
      type: "Radiography",
    },
    {
      value: "Positron Emission Tomography (PET)",
      label: "Positron Emission Tomography (PET)",
      type: "Nuclear Medicine",
    },
    {
      value: "Single Photon Emission Computed Tomography (SPECT)",
      label: "Single Photon Emission Computed Tomography (SPECT)",
      type: "Nuclear Medicine",
    },
    {
      value: "Digital Subtraction Angiography (DSA)",
      label: "Digital Subtraction Angiography (DSA)",
      type: "Radiography",
    },
    {
      value: "Myelography",
      label: "Myelography",
      type: "Radiography",
    },
    {
      value: "Endoscopy",
      label: "Endoscopy",
      type: "Diagnostic Procedures",
    },
    {
      value: "Arthroscopy",
      label: "Arthroscopy",
      type: "Diagnostic Procedures",
    },
    {
      value: "Cystoscopy",
      label: "Cystoscopy",
      type: "Diagnostic Procedures",
    },
    {
      value: "Rhinoscopy",
      label: "Rhinoscopy",
      type: "Diagnostic Procedures",
    },
    {
      value: "Otoscopy",
      label: "Otoscopy",
      type: "Diagnostic Procedures",
    },
    {
      value: "Bronchoscopy",
      label: "Bronchoscopy",
      type: "Diagnostic Procedures",
    },
    ,
    {
      value: "Gastrointestinal Contrast Study",
      label: "Gastrointestinal Contrast Study",
      type: "Diagnostic Procedures",
    },
    {
      value: "Barium Swallow Study",
      label: "Barium Swallow Study",
      type: "Diagnostic Procedures",
    },
    {
      value: "Barium Enema Study",
      label: "Barium Enema Study",
      type: "Diagnostic Procedures",
    },
    {
      value: "Nuclear Scintigraphy",
      label: "Nuclear Scintigraphy",
      type: "Nuclear Medicine",
    },
    {
      value: "Bone Scintigraphyy",
      label: "Bone Scintigraphy",
      type: "Nuclear Medicine",
    },
    {
      value: "Gallium Scintigraphy",
      label: "Gallium Scintigraphy",
      type: "Nuclear Medicine",
    },
    {
      value: "Technetium-99m Scintigraphy",
      label: "Technetium-99m Scintigraphy",
      type: "Nuclear Medicine",
    },
    {
      value: "Radionuclide Therapy",
      label: "Radionuclide Therapy",
      type: "Nuclear Medicine",
    },
  ]

  const LabNameOptions = [
    {
      value: "Complete Blood Count (CBC)",
      label: "Complete Blood Count (CBC)",
      type: "Hematology",
    },
    {
      value: "Blood Chemistry Panel",
      label: "Blood Chemistry Panel",
      type: "Clinical Chemistry",
    },
    {
      value: "Urinalysis",
      label: "Urinalysis",
      type: "Clinical Chemistry",
    },
    {
      value: "Fecal Examination",
      label: "Fecal Examination",
      type: "Parasitology",
    },
    {
      value: "Heartworm Test",
      label: "Heartworm Test",
      type: "Infectious Diseases",
    },
    {
      value:
        "Feline Leukemia Virus (FeLV) and Feline Immunodeficiency Virus (FIV) Test",
      label:
        "Feline Leukemia Virus (FeLV) and Feline Immunodeficiency Virus (FIV) Test",
      type: "Infectious Diseases",
    },
    {
      value: "Parvovirus Test",
      label: "Parvovirus Test",
      type: "Infectious Diseases",
    },
    {
      value: "Feline Infectious Peritonitis (FIP) Test",
      label: "Feline Infectious Peritonitis (FIP) Test",
      type: "Infectious Diseases",
    },
    {
      value: "Feline Panleukopenia Virus (FPV) Test",
      label: "Feline Panleukopenia Virus (FPV) Test",
      type: "Infectious Diseases",
    },
    {
      value: "Canine Distemper Virus (CDV) Test",
      label: "Canine Distemper Virus (CDV) Test",
      type: "Infectious Diseases",
    },
    {
      value: "Canine Parvovirus (CPV) Test",
      label: "Canine Parvovirus (CPV) Test",
      type: "Infectious Diseases",
    },
    {
      value: "Canine Influenza Virus (CIV) Test",
      label: "Canine Influenza Virus (CIV) Test",
      type: "Infectious Diseases",
    },
    {
      value: "Tick-Borne Disease Panel",
      label: "Tick-Borne Disease Panel",
      type: "Infectious Diseases",
    },
    {
      value: "Heartworm Antigen Test",
      label: "Heartworm Antigen Test",
      type: "Infectious Diseases",
    },
    {
      value: "Babesia Test",
      label: "Babesia Test",
      type: "Infectious Diseases",
    },
    {
      value: "Fungal Culture",
      label: "Fungal Culture",
      type: "Infectious Diseases",
    },
    {
      value: "Skin Scraping for Demodex or Sarcoptic Mites",
      label: "Skin Scraping for Demodex or Sarcoptic Mites",
      type: "Infectious Diseases",
    },
    {
      value: "Feline Calicivirus (FCV) and Feline Herpesvirus (FHV) Test",
      label: "Feline Calicivirus (FCV) and Feline Herpesvirus (FHV) Test",
      type: "Infectious Diseases",
    },
    {
      value: "Canine Brucellosis Test",
      label: "Canine Brucellosis Test",
      type: "Infectious Diseases",
    },
    {
      value: "Canine Leptospirosis Test",
      label: "Canine Leptospirosis Test",
      type: "Infectious Diseases",
    },
    {
      value: "Canine Parainfluenza Virus (CPiV) Test",
      label: "Canine Parainfluenza Virus (CPiV) Test",
      type: "Infectious Diseases",
    },
    {
      value: "Feline Heartworm Antibody Test",
      label: "Feline Heartworm Antibody Test",
      type: "Infectious Diseases",
    },
    {
      value: "Blood Coagulation Profile",
      label: "Blood Coagulation Profile",
      type: "Coagulation Disorders",
    },
    {
      value: "Blood Gas Analysis",
      label: "Blood Gas Analysis",
      type: "Clinical Pathology",
    },
    {
      value: "Electrolyte Panel",
      label: "Electrolyte Panel",
      type: "Clinical Chemistry",
    },
    {
      value: "Thyroid Function Tests",
      label: "Thyroid Function Tests",
      type: "Endocrinology",
    },
    {
      value: "Cortisol Level Test",
      label: "Cortisol Level Test",
      type: "Endocrinology",
    },
    {
      value: "Cushing's Disease (ACTH Stimulation) Test",
      label: "Cushing's Disease (ACTH Stimulation) Test",
      type: "Endocrinology",
    },
    {
      value: "Addison's Disease (ACTH Stimulation) Test",
      label: "Addison's Disease (ACTH Stimulation) Test",
      type: "Endocrinology",
    },
    {
      value: "Pancreatic Lipase Immunoreactivity (PLI) Test",
      label: "Pancreatic Lipase Immunoreactivity (PLI) Test",
      type: "Gastroenterology",
    },
    // {
    //   value: "Complete Blood Count (CBC)",
    //   label: "Complete Blood Count (CBC)",
    //   type: "Hematology",
    // },
    {
      value: "Blood Chemistry Panel",
      label: "Blood Chemistry Panel",
      type: "Clinical Chemistry",
    },
    {
      value: "Strangles (Streptococcus equi) Test",
      label: "Strangles (Streptococcus equi) Test",
      type: "Infectious Diseases",
    },
    {
      value: "Equine Infectious Anemia (EIA) Test",
      label: "Equine Infectious Anemia (EIA) Test",
      type: "Infectious Diseases",
    },
    {
      value: "Coggins Test (Equine Infectious Anemia)",
      label: "Coggins Test (Equine Infectious Anemia)",
      type: "Infectious Diseases",
    },
    {
      value: "West Nile Virus Test",
      label: "West Nile Virus Test",
      type: "Infectious Diseases",
    },
    {
      value: "Lyme Disease Test",
      label: "Lyme Disease Test",
      type: "Infectious Diseases",
    },
    {
      value: "Equine Protozoal Myeloencephalitis (EPM) Test",
      label: "Equine Protozoal Myeloencephalitis (EPM) Test",
      type: "Infectious Diseases",
    },
    {
      value: "Serum Amyloid A (SAA) Test",
      label: "Serum Amyloid A (SAA) Test",
      type: "Inflammation",
    },
    {
      value: "Insulin Resistance Test",
      label: "Insulin Resistance Test",
      type: "Endocrinology",
    },
    {
      value: "Lameness Evaluation",
      label: "Lameness Evaluation",
      type: "Musculoskeletal",
    },
    {
      value: "Joint Fluid Analysis",
      label: "Joint Fluid Analysis",
      type: "Musculoskeletal",
    },
    {
      value: "Radiography (X-ray)",
      label: "Radiography (X-ray)",
      type: "Radiology",
    },
    {
      value: "Ultrasound",
      label: "Ultrasound",
      type: "Ultrasonography",
    },
    {
      value: "Complete Blood Count (CBC) Exotic Pets",
      label: "Complete Blood Count (CBC) Exotic Pets",
      type: "Hematology",
    },
    {
      value: "Blood Chemistry Panel Exotic Pets",
      label: "Blood Chemistry Panel Exotic Pets",
      type: "Clinical Chemistry",
    },
    {
      value: "Urinalysis Exotic Pets",
      label: "Urinalysis Exotic Pets",
      type: "Clinical Pathology",
    },
    {
      value: "Fecal Examination Exotic Pets",
      label: "Fecal Examination Exotic Pets",
      type: "Parasitology",
    },
    {
      value: "Chlamydia psittaci Test (Birds) Exotic Pets",
      label: "Chlamydia psittaci Test (Birds) Exotic Pets",
      type: "Infectious Diseases",
    },
    {
      value: "Reptile Parasite Test (Reptiles) Exotic Pets",
      label: "Reptile Parasite Test (Reptiles) Exotic Pets",
      type: "Infectious Diseases",
    },
    {
      value: "Radiography (X-ray) Exotic Pets",
      label: "Radiography (X-ray) Exotic Pets",
      type: "Radiology",
    },
  ]

  const [nameOptions, setNameOptions] = useState(ConsultationNameOptions)

  const notApplicabletestTypeOptions = [
    {
      value: "NA",
      label: "Not Applicable",
    },
  ]

  const ImagingTestTypeOptions = [
    {
      value: "Radiography",
      label: "Radiography",
    },
    { value: "Ultrasonography", label: "Ultrasonography" },
    { value: "Nuclear Medicine", label: "Nuclear Medicine" },
    { value: "Diagnostic Procedures", label: "Diagnostic Procedures" },
  ]

  const LabTestTypeOptions = [
    {
      value: "Hematology",
      label: "Hematology",
    },
    { value: "Clinical Chemistry", label: "Clinical Chemistry" },
    { value: "Clinical Pathology", label: "Clinical Pathology" },
    { value: "Parasitology", label: "Parasitology" },
    { value: "Infectious Diseases", label: "Infectious Diseases" },
    { value: "Coagulation Disorders", label: "Coagulation Disorders" },
    { value: "Endocrinology", label: "Endocrinology" },
    { value: "Inflammation", label: "Inflammation" },
    { value: "Musculoskeletal", label: "Musculoskeletal" },
    { value: "Musculoskeletal", label: "Musculoskeletal" },
    { value: "Ultrasonography", label: "Ultrasonography" },
    { value: "Gastroenterology", label: "Gastroenterology" },
    { value: "Parasitology", label: "Parasitology" },
  ]

  const [testTypeOptions, setTestTypeOptions] = useState(
    notApplicabletestTypeOptions
  )
  const [testType, setTestType] = useState({})

  const filteredImagingNameOption = ImagingNameOptions.filter(
    ele => ele.type === testType.value
  )
  console.log("current options:", filteredImagingNameOption)

  const filteredLabNameOption = LabNameOptions.filter(
    ele => ele.type === testType.value
  )

  // console.log("priceCategory:",priceCategory)

  let filterCategoryDropdown = allCategories.filter(
    c => c.pricingCategoryName === priceCategory
  )
  console.log("filterCategoryDropdown:", filterCategoryDropdown)
  console.log(modifyPricingType(filterCategoryDropdown))
  useEffect(() => {
    if (priceCategory && priceCategory === "Lab Pricing") {
      setTestTypeOptions(LabTestTypeOptions)
      setNameOptions(LabNameOptions)
      setCategory(modifyPricingType(filterCategoryDropdown))
    } else if (priceCategory && priceCategory === "Imaging Pricing") {
      setTestTypeOptions(ImagingTestTypeOptions)
      setNameOptions(ImagingNameOptions)
      setCategory(modifyPricingType(filterCategoryDropdown))
    } else if (priceCategory && priceCategory === "Procedure Pricing") {
      setTestTypeOptions([])
      setNameOptions([])
      setCategory(modifyPricingType(filterCategoryDropdown))
    } else if (priceCategory && priceCategory === "Consultation Pricing") {
      setTestTypeOptions(notApplicabletestTypeOptions)
      setNameOptions(ConsultationNameOptions)
      setCategory(modifyPricingType(filterCategoryDropdown))
    }
  }, [])

  const handleTestChange = value => {
    if (priceCategory && priceCategory === "Imaging Pricing") {
      console.log(value)
      setItemName({})
      setTestType(value)
      // setNameOptions(filteredImagingNameOption);
    } else if (priceCategory && priceCategory === "Lab Pricing") {
      console.log(value)
      setItemName({})
      setTestType(value)
    } else if (priceCategory && priceCategory === "Consultation Pricing") {
      setTestType(value)
    }
  }
  useEffect(() => {
    if (testType !== "" && priceCategory === "Imaging Pricing") {
      setNameOptions(filteredImagingNameOption)
    } else if (testType !== "" && priceCategory === "Lab Pricing") {
      setNameOptions(filteredLabNameOption)
    }
  }, [testType])

  const handleOnCancel = () => {
    validation.resetForm
    history.goBack()
  }

  console.log("category", category)
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title="Billings"
          titleLink="/invoices"
          breadcrumbItem={"Add Payment"}
        />
        <Row>
          <Card>
            <CardBody>
              <Form
                className="form-horizontal row"
                onSubmit={validation.handleSubmit}
              >
                <Row>
                  <Col>
                  <FormGroup className="row mb-9">
                      <Label className="col-form-label col-lg-5 text-right text-input-label">
                        Patient
                      </Label>
                      <div className="col-lg-7">
                        <ReactSelect
                          classNamePrefix="form-react-select"
                          className="form-react-select"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="row mb-9">
                      <Label className="col-form-label col-lg-5 text-right text-input-label">
                        Pet Parent
                      </Label>
                      <div className="col-lg-7">
                        <ReactSelect
                          classNamePrefix="form-react-select"
                          className="form-react-select"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className="mb-4 row">
                      <Label
                        htmlFor="amount"
                        className="col-form-label col-lg-6 text-right text-input-label"
                      >
                        Amount
                      </Label>
                      <div className="col-lg-6">
                        <Input
                          id="amount"
                          name="amount"
                          type="text"
                          className="form-control"
                          placeholder="Amount"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.amount || ""}
                          invalid={
                            validation.touched.amount &&
                            validation.errors.amount
                              ? true
                              : false
                          }
                        />
                        {validation.touched.amount &&
                        validation.errors.amount ? (
                          <FormFeedback type="invalid">
                            {validation.errors.amount}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mb-4 row">
                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-6 text-right text-input-label"
                      >
                        Paid On
                      </Label>
                      <div className="col-lg-6">
                        <Input
                          id="paymentDate"
                          name="paymentDate"
                          type="date"
                          className="form-control"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.paymentDate || ""}
                          invalid={
                            validation.touched.paymentDate &&
                            validation.errors.paymentDate
                              ? true
                              : false
                          }
                        />
                        {validation.touched.paymentDate &&
                        validation.errors.paymentDate ? (
                          <FormFeedback type="invalid">
                            {validation.errors.paymentDate}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormGroup className="mb-4 row">
                      <Label
                        htmlFor="transactionId"
                        className="col-form-label col-lg-6 text-right text-input-label"
                      >
                        Transaction ID
                      </Label>
                      <div className="col-lg-6">
                        <Input
                          id="transactionId"
                          name="transactionId"
                          type="text"
                          className="form-control"
                          placeholder="Transaction ID"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.transactionId || ""}
                          invalid={
                            validation.touched.transactionId &&
                            validation.errors.transactionId
                              ? true
                              : false
                          }
                        />
                        {validation.touched.transactionId &&
                        validation.errors.transactionId ? (
                          <FormFeedback type="invalid">
                            {validation.errors.transactionId}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-3 text-right text-input-label"
                      >
                        Notes
                      </Label>
                      <div className="col-lg-9">
                        <textarea
                          className="form-control"
                          id="projectdesc"
                          rows="8"
                          name="notes"
                          style={{ resize: "none", minHeight: 120 }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.notes || ""}
                          placeholder="Notes"
                        />
                        {validation.touched.notes && validation.errors.notes ? (
                          <FormFeedback type="invalid">
                            {validation.errors.notes}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="justify-content-end mt-8 ms-15 mb-4">
                  <ButtonMain
                    // isLoading={isLoading}
                    type="submit"
                    className="btn btn-med btn-primary me-1"
                  >
                    Cancel
                  </ButtonMain>

                  <ButtonMain
                    // isLoading={isLoading}
                    type="submit"
                    className="btn btn-med btn-primary"
                  >
                    Save
                  </ButtonMain>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Row>
      </Container>
    </div>
  )
}

export default AddPayment
