import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useHistory, useParams, useLocation } from "react-router-dom"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  CardBody,
} from "reactstrap"
import {
  fetchAppointmentsBasedOnId,
  fetchAppointmentsListBasedOnPetId,
  getPatientById,
  getPetById,
  getPricingItemById,
} from "helpers/api_helper"
// import "./style.scss"
import PetDetailsCard from "./components/PetDetailCard"
import { useDispatch } from "react-redux"
import { setGlobalLoading } from "store/slices"
import { getDateDDMMYYYY } from "helpers/utils";
import appConstants from "../../constants/appConstants/appConstants.json";
import { capitalizeFirstLetter } from "helpers/commonHelpers"
import vetenary from "../../assets/images/veterinary.png";
// import PetCompleteDetails from "./components/PetCompleteDetails"
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PetDetails() {
  //meta title
  document.title = "Pet Parents"

  const [itemDetails, setItemDetails] = useState({})
  const [noDetails, setNoDetails] = useState(false);
  const [isPastAppointment, setIsPastAppointment] = useState(true);
  const [currentPetAppointmentDetails, setCurrentPetAppointmentDetails] = useState([]);


  const { itemId } = useParams()
  const query = useQuery();
  const dispatch = useDispatch();
  let appointmentDate = query.get("date");
  appointmentDate = appointmentDate?.split("/").reverse().join("-");

  useEffect(() => {
    if(appointmentDate){
      if(new Date(appointmentDate) < new Date()){
        setIsPastAppointment(true)
      }else{
        setIsPastAppointment(false)
      }
    }
    async function fetchData() {
          if (itemId) {
            const firstPetDetails = await fetchAppointmentsListBasedOnPetId(itemId);
            if (firstPetDetails.status === 1) {
              setCurrentPetAppointmentDetails(firstPetDetails.data)
            }
          }
        }
    fetchData()

  }, []); 

  const NoMedicalHistoryAvailable = () => {
    return (
      <h6 className="no-data font-fontFamily" style={{background:"white"}}>No Appointments available</h6>
    )
  }


  useEffect(() => {
    async function fetchData() {
      dispatch(setGlobalLoading(true));
      const detail = await getPetById(itemId)
      if (detail.status === 1 && detail.data) {
        setItemDetails(detail.data)
      } else {
        setNoDetails(true)
      }
      dispatch(setGlobalLoading(false));
    }
    fetchData()
  }, [])
  if (noDetails) {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs
              title="Appointment"
              breadcrumbItem={itemDetails?.name}
            />
            <Row>
              <Col xs="12" style={{ textAlign: "center" }}>
                <div>
                  <img
                      src={vetenary}
                      alt="icon"
                      style={{ width: "50px", height: "50px" }}
                    />
                </div>
                <h3>No Such Pet exists.</h3>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="mx-3">
          <Breadcrumbs
            title="Patients"
            titleLink="/patients"
            breadcrumbItem={itemDetails?.fullName}
          />
          </div>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PetDetailsCard
                    testDetails={itemDetails ? itemDetails : {}}
                    isPastAppointment={isPastAppointment}
                  />
                </CardBody>
              </Card>
            </Col>

            <Col xs="12">
              <Card>
                <CardBody>
                  <div>
                    <h3>Appointment history</h3>
                  </div>
                  <div style={{ overflowY: 'scroll', overflowY: 'hidden'}}>
                    {currentPetAppointmentDetails?.length ? (
                      currentPetAppointmentDetails.map((item, key) => (
                        <li key={key} className="event-list">
                          <div className="event-timeline-dot" />
                          <div className="d-flex">
                            <div className="history-list-item">
                              <div className="history-appointment-details">
                                <div className="history-appointment-type font-fontFamily">
                                  {capitalizeFirstLetter(item.type)}
                                  <span className="history-appointment-type font-fontFamily">
                                    {getDateDDMMYYYY(item.date)}
                                  </span>
                                </div>
                                <div className="history-appointment-reason font-fontFamily">
                                  Reason: {item.reasonForVisit}
                                </div>
                                <div className="history-appointment-invoice font-fontFamily fontSize-14">
                                  Invoice total: <span> {item.cost}</span>
                                </div>
                              </div>
                              {/* <div className="history-appointment-notes font-fontFamily">
                                Notes: {item.notes || "N/A"}
                              </div> */}
                              <div className="history-appointment-otherdetails font-fontFamily d-flex justify-content-between align-items-center">
                                {item.doctorId && (
                                  <div className="history-doc-details">
                                    <div style={{ height: "40px", width: "40px", backgroundColor: "#fff", border: "1px solid transparent", borderRadius: "50%", boxSizing: "border-box", boxShadow: "3px 2px 10px rgba(0,0,0,0.2)", }}>
                                      <img src={item.doctorId.images[0] || appConstants.DEFAULT_VET_IMAGE} style={{ height: "100%", width: "100%", objectFit: 'contain' }} />
                                    </div>
                                    <p className="fontSize-14">{item.doctorId.fullName}</p>
                                  </div>
                                )}
                                <div className="history-appointment-notes font-fontFamily">
                                Notes: {item.notes || "N/A"}
                              </div>
                                <div className="history-doc-cta font-fontFamily font-weight-text">
                                  <Link to={`/prescription-details/${item._id}`}>
                                    View Prescription
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <NoMedicalHistoryAvailable />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
PetDetails.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default PetDetails
