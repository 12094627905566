import ButtonMain from "components/Common/Button"
import { showMessage } from "components/Notification"
import {
  addNewPet,
  addNewPetParent,
  BASE,
  getParentById,
  getPatientById,
  getPetBreed,
  updatePet,
  uploadAttachment,
} from "helpers/api_helper"
import {
  modifyAppointmentType,
  validateEmail,
  validatePhone,
} from "helpers/utils"
import React, { useReducer, useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useParams } from "react-router-dom"
import ReactSelect from "react-select"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Alert,
} from "reactstrap"
import {
  fetchPetTypes,
  getParentList,
  getTypes,
  getUserDetails,
  setGlobalLoading,
} from "store/slices"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import moment from "moment"
import avatar from "../../assets/images/users/pawprint.png"
import LeftArrow from "../../assets/icons/ArrowLeft.png"
import appConstants from "../../constants/appConstants/appConstants.json";
import { capitalizeFirstLetter } from "helpers/commonHelpers"

const CreatePatient = () => {
  //meta title
  document.title = "Create New Patient"
  const colorArr = [
    {
      label: "Black",
      value: "Black",
    },
    {
      label: "White",
      value: "White",
    },
    {
      label: "Brown",
      value: "Brown",
    },
    {
      label: "Gray",
      value: "Gray",
    },
    {
      label: "Orange",
      value: "Orange",
    },
    {
      label: "Calico",
      value: "Calico",
    },
    {
      label: "Tabby",
      value: "Tabby",
    },
    {
      label: "Tortoiseshell",
      value: "Tortoiseshell",
    },
    {
      label: "Siamese",
      value: "Siamese",
    },
    {
      label: "Sphynx",
      value: "Sphynx",
    },
  ]
  const [isLoading, setIsLoading] = useState(false)
  const [parent, setParent] = useState({})
  const [patient, setPatient] = useState(null)
  const [isParentDropdownDisabled, setIsParentDropdownDisabled] =
    useState(false)
  const [parentList, setParentList] = useState([])
  const [gender, setGender] = useState({})
  const [colour, setColour] = useState({})
  const [spay, setSpayed] = useState({})

  const [petType, setPetTypes] = useState({})
  const [breeds, setBreeds] = useState([])
  const [selectedBreed, setSelectedBreed] = useState({})

  const [selectedImage, setSelectedImage] = useState()
  const [imageUrl, setImageUrl] = useState([])
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [selectedPetTemperament, setSelectedPetTemperament] = useState({});
  const [temperamentDropdown, setTemperamentDropdown] = useState([]);

  const petTypes = useSelector(getTypes);
  const history = useHistory()
  const { id } = useParams()
  const { parentId } = useParams()

  const userDetails = useSelector(getUserDetails);

  useEffect(() => {
    const temperamentList = appConstants.PET_TEMPERAMENTS.map((item) => {
      return { value: item.name, label: item.name }
    });
    if(temperamentList && temperamentList.length){
      setTemperamentDropdown(temperamentList);
    }
  }, [])


  const handlePetTypeChange = async item => {
    setPetTypes(item);
    setSelectedBreed({});
    const selected = petTypes.find(ele => ele._id === item.value)
    const response = await getPetBreed(selected.id)
    if (response.status == 1) {
      setBreeds(modifyAppointmentType(response.data))
    }
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case "edit":
        return { ...state, [action.payload.name]: action.payload.value }
      default:
        return state
    }
  }
  const showToast = ({ message, type }) => {
    showMessage({ label: message, status: type || "error" })
  }
  const [values, dispatchReducer] = useReducer(reducer, {
    vetId: userDetails._id,
    petBreed: "",
    petType: "",
    microchipNo: "",
    gender: "",
    spay: "",
    locationChipping: "",
    insurance: "",
    notes: "",
    parentId: "",
    parentName: "",
    fullName: "",
    dateOfBirth: "",
    colour: "",
    adoptedOn: null,
    weight: "",
    images: [],
    petTemperament: ""
  })

  const stateChange = name => e => {
    const _value = e.target.value
    dispatchReducer({ type: "edit", payload: { name, value: _value } })
  }
  const handleSubmit = async e => {
    e.preventDefault()

    values.parentId = parent?.value
    values.ParentName = parent?.label

    if (!parent.value) {
      showMessage({ label: "Please select Parent", status: "error" })
      return
    }
    // if (!imageUrl?.length) {
    //   showMessage({ label: "Please Upload a Photo", status: "error" })
    //   return
    // }
    if (!values.fullName) {
      showMessage({ label: "Please add pet's name", status: "error" })
      return
    }
    if (!gender.label) {
      showMessage({ label: "Please select gender", status: "error" })
      return
    }
    if (!colour.label) {
      showMessage({ label: "Please select colour", status: "error" })
      return
    }
    // if (!spay.label) {
    //   showMessage({ label: "Please select Spay Type", status: "error" })
    //   return
    // }
    if (!petType.label) {
      showMessage({ label: "Please Select Pet Type", status: "error" })
      return
    }
    if (!selectedBreed.value) {
      showMessage({
        label: "Please select Pet Type and pet Breed",
        status: "error",
      })
      return
    }

    if(!selectedPetTemperament?.value) {
      showMessage({
        label: "Please select Pet's temperament",
        status: "error",
      });
      return;
    }
    // if (!values.microchipNo) {
    //   showMessage({
    //     label: "Please add microchip no or add '-'",
    //     status: "error",
    //   })
    //   return
    // }
    // if (!values.locationChipping) {
    //   showMessage({ label: "Please add location of chipping", status: "error" })
    //   return
    // }
    if (!values.dateOfBirth) {
      showMessage({ label: "Please add date of birth", status: "error" });
      setIsLoading(false)
      return
    }
    // if (!values.dateOfBirth) {
    //   showMessage({ label: "Please add date of birth", status: "error" })
    //   return
    // }

    // if (!values.insurance) {
    //   showMessage({
    //     label: "Please add insurance no or add '-'",
    //     status: "error",
    //   })
    //   return
    // }
    // if (!values.notes) {
    //   showMessage({ label: "Please add Notes", status: "error" })
    //   return
    // }
    values.petType = petType?.label;
    values.petBreed = selectedBreed?.label;
    values.gender = gender?.label;
    values.colour = colour?.label;
    values.spay = spay?.label;
    values.images = imageUrl;
    values.createdBy = "vet";
    values.petTemperament = selectedPetTemperament?.value?.toLowerCase();

    setIsLoading(true)
    try {
      let res
      if (!id) {
        res = await addNewPet(values)
      } else {
        res = await updatePet(id, values)
      }
      if (res.status === 1) {
        setIsLoading(false)
        showMessage({ label: "Successfully added", status: "success" })
        history.push("/patients")
      } else {
        showMessage({
          label: capitalizeFirstLetter(res?.message?.message) || "something went wrong1",
          status: "error",
        });
        setIsLoading(false);
        history.push("/patients")
      }
    } catch (error) {
      showMessage({
        label: error?.response?.data?.message || "something went wrong",
        status: "error",
      })
      setIsLoading(false)
    }
  }

  const handleAdoptedOnChange = e => {
    const { value } = e.target

    if (!values.dateOfBirth) {
      showMessage({
        label: "Please select Date of Birth first",
        status: "error",
      })
      return
    }

    //  // Proceed with setting the adoptedOn date if validation passes
    //  setValues({
    //    ...values,
    //    adoptedOn: value,
    //  })
  }

  const dispatch = useDispatch()
  useEffect(() => {
    const fetchData = async () => {
      dispatch(setGlobalLoading(true))
      let patient = null
      const list = await dispatch(getParentList({ doctorId: userDetails._id }))
      if (id) {
        patient = await getPatientById({ petId: id })
      }

      const _list = await list.map(item => {
        return { value: item._id, label: item.fullName }
      })
      setParentList(_list)
      const pt = await dispatch(fetchPetTypes())
      if (id && patient) {
        await initializeValues(patient, pt)
        setPatient(patient)
      }
      if (parentId) {
        const parent = await getParentById({ patientId: parentId })
        if (parent.status === 1 && parent.data) {
          setParent({ label: parent?.data?.fullName, value: parent?.data?._id })
          setIsParentDropdownDisabled(true)
        }
      }
      dispatch(setGlobalLoading(false))
    }
    fetchData()
  }, [dispatch])

  const initializeValues = async (patient, pt) => {
    setParent({
      label: patient?.parentId?.fullName,
      value: patient?.parentId?._id,
    });
    
    setSelectedPetTemperament({ value: patient.petTemperament, label: patient.petTemperament })
    const selectedPetType = [...(pt ? pt : petTypes)].find(
      ele => ele.name === patient.petType
    )
    if (selectedPetType) {
      setPetTypes({ label: selectedPetType.name, value: selectedPetType._id })
      let brds = null
      const response = await getPetBreed(selectedPetType.id)
      if (response.status == 1) {
        brds = modifyAppointmentType(response.data)
        setBreeds(brds)
      }

      const selectedPetBreed = brds.find(ele => ele.label === patient.petBreed)
      setSelectedBreed(selectedPetBreed)
    }
    let selectedGender = { label: "Male", value: "Male" }
    if (patient.gender != "Male") {
      selectedGender = { label: "Female", value: "Female" }
    }
    console.log("patient.gender???", patient.gender)

    setGender(selectedGender)
    let selectedColour = colorArr[0]
    if (patient.colour != colorArr[0].value) {
      selectedColour = colorArr[1]
    }

    //xpecto Developer 6/7/2024
    setColour(selectedColour)
    let selectedSpayed = { label: "no", value: "No" }
    if (patient.spay != "no") {
      selectedSpayed = { label: "yes", value: "Yes" }
    }
    setSpayed(selectedSpayed)
    setImageUrl(patient.images)
    //xpecto Developer 6/7/2024
    dispatchReducer({
      type: "edit",
      payload: { name: "fullName", value: patient.fullName || "" },
    })

    dispatchReducer({
      type: "edit",
      payload: { name: "microchipNo", value: patient.microchipNo || "" },
    })
    dispatchReducer({
      type: "edit",
      payload: { name: "gender", value: patient.selectedGender || "" }, //xpecto Developer 6/7/2024
    })
    dispatchReducer({
      type: "edit",
      payload: { name: "spay", value: patient.selectedSpayed || "" },
    })
    dispatchReducer({
      type: "edit",
      payload: {
        name: "locationChipping",
        value: patient.locationChipping || "",
      },
    })
    dispatchReducer({
      type: "edit",
      payload: { name: "insurance", value: patient.insurance || "" },
    })
    dispatchReducer({
      type: "edit",
      payload: { name: "parentId", value: patient?.parentId?._id },
    })
    dispatchReducer({
      type: "edit",
      payload: {
        name: "dateOfBirth",
        value: moment(patient.dateOfBirth).format("yyyy-MM-DD") || "",
      },
    })
    dispatchReducer({
      type: "edit",
      payload: { name: "colour", value: patient.selectedColour || "" },
    })
    dispatchReducer({
      type: "edit",
      payload: {
        name: "adoptedOn",
        value: patient?.adoptedOn ? moment(patient.adoptedOn).format("yyyy-MM-DD") : "",
      },
    })
    dispatchReducer({
      type: "edit",
      payload: { name: "weight", value: patient.weight || "" },
    })
    dispatchReducer({
      type: "edit",
      payload: { name: "notes", value: patient.notes || "" },
    })
    dispatchReducer({
      type: "edit",
      payload: { name: "images", value: patient.images || [] },
    })
    dispatchReducer({
      type: "edit",
      payload: { name: "petTemperament", value: patient.petTemperament || "" },
    })
  }

  const changeHandler = async event => {
    try {
      const file = event.target.files[0]

      // Validate that the file is an image
      if (file && file.type.startsWith("image/")) {
        const formData = new FormData()
        formData.append("files", file)

        setSelectedImage(file)
        setIsFileUploading(true)
        dispatch(setGlobalLoading(true));
        const response = await uploadAttachment(formData);
        dispatch(setGlobalLoading(false));

        if (response.status === 1) {
          setImageUrl(response.data)
          showMessage({
            label: "Uploaded Successfully! Please press update to continue",
            status: "success",
          })
        }
      } else {
        showMessage({
          label: "Please upload a valid image file.",
          status: "error",
        })
        // Optionally, reset the file input
        event.target.value = null
      }
    } catch (error) {
      console.error("Error uploading file:", error)
      setIsFileUploading(false);
      dispatch(setGlobalLoading(false));
      showMessage({ label: "Something went wrong!", status: "error" })
    } finally {
      setIsFileUploading(false)
    }
  }

  const handleParentListMenuOpen = () => {
    setTimeout(() => {
      if (parentList.length === 0) {
        showMessage({
          label: "No Parents Available! Need to Create First",
          status: "info",
        })
      }
    }, 1000)
  }
  const [showFullResults, setShowFullResults] = useState(false)
  const generalPatients = useSelector(state => state.Patients.generalPatients)
  const [searchTerm, setSearchTerm] = useState("")
  const [filteredItems, setFilteredItems] = useState([])

  useEffect(() => {
    const filtered = generalPatients.filter(
      item =>
        item?.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.ParentName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.petId?.includes(searchTerm.toLowerCase())
    )
    setFilteredItems(filtered)
  }, [generalPatients, searchTerm])

  const todayDate = moment().format("yyyy-MM-DD")

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="appointment-heading-div ">
            <Col
              className="d-flex align-items-center justify-content-start gap-2"
              xs="auto"
            >
              <img
                src={LeftArrow}
                width={32}
                onClick={() => history.goBack()}
                role="button"
              />
              <h4 className="mb-0 font-fontFamily fontSize-28">Patients</h4>
            </Col>

            <Col
              className="d-flex align-items-center justify-content-between gap-4"
              xs="auto"
            >
              <div>
                <div>
                  <FormGroup className="mb-4" row>
                    <div>
                      <Input
                        id="itemName"
                        name="itemName"
                        type="text"
                        className="form-control"
                        placeholder="Search by Pet Parent / Pet Name / Pet ID"
                        value={searchTerm}
                        style={{
                          width: "444px",
                          border: "none",
                          borderBottom: "1px solid #B5B5B5",
                          borderRadius: "0",
                          paddingLeft: "0",
                          paddingBottom: "0",
                          backgroundColor: "transparent",
                        }}
                        onChange={e => setSearchTerm(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </FormGroup>
                  {searchTerm && (
                    <div style={{ position: "relative", marginTop: "10px" }}>
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          width: "444px",
                          height: filteredItems.length > 0 ? "334px" : "60px",
                          background: "#FFFFFF",
                          zIndex: "1",
                          boxShadow: "0px 18px 30px 16px #00000008",
                          borderRadius: "12px",
                          overflowY: showFullResults ? "scroll" : "hidden",
                        }}
                      >
                        {filteredItems.length > 0 ? (
                          <>
                            {showFullResults ? (
                              <>
                                {filteredItems.map((ele, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      marginTop: "14px",
                                      marginLeft: "24px",
                                    }}
                                  >
                                    <Link
                                      to={`/pet/${ele._id}`}
                                      style={{ color: "black" }}
                                    >
                                      {ele.fullName}
                                    </Link>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <>
                                {filteredItems.slice(0, 3).map((ele, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      marginTop: "14px",
                                      marginLeft: "24px",
                                    }}
                                  >
                                    <Link
                                      to={`/pet/${ele._id}`}
                                      style={{ color: "black" }}
                                    >
                                      {ele.fullName}
                                    </Link>
                                  </div>
                                ))}
                                {filteredItems.length > 3 && (
                                  <div
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      marginTop: "auto",
                                      marginLeft: "27px",
                                      marginRight: "27px",
                                      marginBottom: "30px",
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setShowFullResults(true)}
                                  >
                                    <i className="fa fa-search me-1" />
                                    See All items
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <div
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                              marginTop: "14px",
                              marginLeft: "24px",
                              textAlign: "center",
                            }}
                          >
                            No items found
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            {/* <div className="create-link">
              <Link
                color="primary"
                className="font-16 btn-block"
                to={"/add-appointment"}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New Appointment
              </Link>
            </div> */}
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4 font-fontFamily mx-4">
                    <p className="fontSize-24">
                      {id ? "Edit Pet" : "Pet Detail"}
                    </p>
                  </CardTitle>

                  <div className="d-flex flex-wrap">
                    <div className="ms-3">
                      <img
                        src={imageUrl[0] ?? avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <hr />
                    {/* <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <div className="file-upload">
                          <ButtonMain
                            isLoading={false}
                            className="btn btn-primary btn-med"
                            type="submit"
                          >
                            {isFileUploading
                              ? ` uploading ${selectedImage?.name}`
                              : "Upload New"}
                          </ButtonMain>
                          <input
                            type="file"
                            onChange={changeHandler}
                            accept="image/*"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div> */}

                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <div className="file-upload">
                          <ButtonMain
                            isLoading={false}
                            className="btn btn-primary btn-med"
                            type="submit"
                          >
                            {isFileUploading
                              ? ` uploading ${selectedImage?.name}`
                              : "Upload Image"}
                          </ButtonMain>
                          <input
                            type="file"
                            onChange={changeHandler}
                            accept="image/*"
                            className="form-control"
                          />
                          <p style={{ whiteSpace: "nowrap", padding: '10px 0'}}>{`( .jpeg, .png, .jpg, .webp )`}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12} className="pl-0">
                        <FormGroup className="mb-3" row>
                          <Label className="col-form-label col-lg-2  text-lg-start text-start text-input-label font-fontFamily-400">
                            Select Parent<span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-10">
                            <ReactSelect
                              value={parent}
                              onChange={setParent}
                              isDisabled={isParentDropdownDisabled}
                              options={parentList}
                              classNamePrefix="form-react-select"
                              className="form-react-select"
                              onMenuOpen={handleParentListMenuOpen}
                            />
                            {!parentList?.length ? (
                              <Link
                                to="/add-parent"
                                className="new-actions-cta my-2"
                              >
                                Please Add Parent before adding parent
                              </Link>
                            ) : null}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6} className="pl-0">
                        <FormGroup className="mb-3" row>
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-4  text-lg-start text-start text-input-label font-fontFamily-400"
                          >
                            Pet's name<span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-8">
                            <Input
                              id="projectname"
                              name="projectname"
                              type="text"
                              value={values.fullName || ""}
                              onChange={stateChange("fullName")}
                              className="form-control"
                              placeholder="Enter Pet's Name"
                            />
                          </div>
                        </FormGroup>

                        <FormGroup className="row mb-3">
                          <Label className="col-form-label col-lg-4  text-lg-start text-start text-input-label font-fontFamily-400">
                            Pet Type<span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-8">
                            <ReactSelect
                              value={values.petType || petType}
                              onChange={handlePetTypeChange}
                              placeholder="Select Pet Type"
                              options={modifyAppointmentType(petTypes || [])}
                              classNamePrefix="form-react-select"
                              className="form-react-select"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="row mb-3">
                          <Label className="col-form-label col-lg-4  text-lg-start text-start text-input-label font-fontFamily-400">
                            Pet's Breed<span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-8">
                            <ReactSelect
                              value={values.petBreed || selectedBreed}
                              isDisabled={!petType?.value}
                              onChange={setSelectedBreed}
                              options={breeds}
                              classNamePrefix="form-react-select"
                              className="form-react-select"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="mb-3" row>
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-4  text-lg-start text-start text-input-label font-fontFamily-400"
                          >
                            Date Of Birth<span className="text-danger">*</span>
                          </Label>
                          {/*xpecto Developer 22/7/2024 */}
                          <div className="col-lg-8">
                            <input
                              className="form-control"
                              type="date"
                              value={values.dateOfBirth || ""}
                              onChange={stateChange("dateOfBirth")}
                              id="example-date-input"
                              max={todayDate}
                              onKeyDown={e => e.preventDefault()}
                            />
                          </div>
                          {/*xpecto Developer 22/7/2024 */}
                        </FormGroup>

                        <FormGroup className="mb-3" row>
                          <Label
                            htmlFor="projectdesc"
                            className="ol-form-label col-lg-4 text-lg-start text-right text-input-label font-fontFamily-400"
                          >
                            Adopted On
                          </Label>
                          <div className="col-lg-8">
                            <input
                              className="form-control"
                              type="date"
                              value={values.adoptedOn || ""}
                              onChange={stateChange("adoptedOn")}
                              id="example-date-input"
                              max={todayDate}
                              onKeyDown={e => e.preventDefault()}
                              min={values.dateOfBirth}
                              disabled={!values.dateOfBirth}
                            />
                          </div>
                        </FormGroup>
                        {/* <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400"
                          >
                            Colour
                          </Label>
                          <div className="col-lg-7">
                            <input
                              className="form-control"
                              type="text"
                              value={values.colour || ""}
                              onChange={stateChange("colour")}
                              id="example-date-input"
                            />
                          </div>
                        </FormGroup> */}
                        <FormGroup className="mb-3" row>
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-4 text-lg-start text-start text-input-label font-fontFamily-400"
                          >
                            Colour<span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-8">
                            <ReactSelect
                              value={values.colour || colour}
                              onChange={setColour}
                              options={colorArr}
                              classNamePrefix="form-react-select"
                              className="form-react-select mb-4"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="row mb-3">
                          <Label className="col-form-label col-lg-4  text-lg-start text-start text-input-label font-fontFamily-400">
                            Pet's Temperament<span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-8">
                            <ReactSelect
                              value={selectedPetTemperament}
                              isDisabled={!petType?.value}
                              onChange={setSelectedPetTemperament}
                              options={temperamentDropdown}
                              classNamePrefix="form-react-select"
                              className="form-react-select"
                            />
                          </div>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-3" row>
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-4  text-lg-start text-start text-input-label font-fontFamily-400"
                          >
                            Gender<span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-8">
                            <ReactSelect
                              value={values.gender || gender}
                              onChange={setGender}
                              options={[
                                { value: "male", label: "Male" },
                                { value: "female", label: "Female" },
                              ]}
                              classNamePrefix="form-react-select"
                              className="form-react-select"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="mb-3" row>
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-4  text-lg-start text-start text-input-label font-fontFamily-400"
                          >
                            Spayed/Neutred
                          </Label>
                          <div className="col-lg-8">
                            <ReactSelect
                              value={values.spay || spay}
                              onChange={setSpayed}
                              options={[
                                { value: "no", label: "No" },
                                { value: "yes", label: "Yes" },
                              ]}
                              classNamePrefix="form-react-select"
                              className="form-react-select"
                            />
                          </div>
                        </FormGroup>

                        <FormGroup className="mb-3" row>
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-4  text-lg-start text-start text-input-label font-fontFamily-400"
                          >
                            Location Of Chipping
                          </Label>
                          <div className="col-lg-8">
                            <Input
                              className="form-control"
                              id="projectdesc"
                              value={values.locationChipping || ""}
                              onChange={stateChange("locationChipping")}
                              placeholder="Enter Location Of Chipping"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="mb-3" row>
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-4  text-lg-start text-start text-input-label font-fontFamily-400"
                          >
                            Weight (Kg.)
                          </Label>
                          <div className="col-lg-8">
                            <Input
                              className="form-control"
                              id="projectdesc"
                              rows="3"
                              type="number"
                              value={values.weight || ""}
                              onChange={stateChange("weight")}
                              placeholder="Enter Weight"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="mb-3" row>
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-4  text-lg-start text-start text-input-label font-fontFamily-400"
                          >
                            Insurance
                          </Label>
                          <div className="col-lg-8">
                            <Input
                              className="form-control"
                              id="projectdesc"
                              rows="3"
                              value={values.insurance || ""}
                              onChange={stateChange("insurance")}
                              placeholder="Enter Insurance"
                            />
                          </div>
                        </FormGroup>

                        <FormGroup className="mb-3" row>
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-4 text-lg-start text-start text-input-label font-fontFamily-400"
                          >
                            Microchip No.
                          </Label>
                          <div className="col-lg-8">
                            <Input
                              id="projectname"
                              name="projectname"
                              type="text"
                              value={values.microchipNo || ""}
                              onChange={stateChange("microchipNo")}
                              className="form-control"
                              placeholder="Enter Microchip No."
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12} className="pl-0">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-2 text-lg-start text-start text-input-label font-fontFamily-400"
                          >
                            Notes
                          </Label>
                          <div className="col-lg-10">
                            <textarea
                              className="form-control"
                              style={{ minHeight: 200 }}
                              value={values.notes || ""}
                              onChange={stateChange("notes")}
                              rows="3"
                              placeholder="Enter Notes"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" className=" d-flex justify-content-end">
                        <ButtonMain
                          isLoading={isLoading}
                          type="submit"
                          className="btn btn-med btn-primary"
                        >
                          {patient ? "Update Patient" : "Create Patient"}
                        </ButtonMain>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreatePatient
