import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"
import "./labs.scss"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  getAllParents,
  getParentList,
  getUserDetails,
  setGlobalLoading,
} from "../../store/slices"

import {
  OrderId,
  FormattedTime,
  Date,
  Total,
  PaymentStatus,
  //   PaymentMethod,
} from "../Pets/components/PatientsCol"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Col, Row, UncontrolledTooltip, Card, CardBody } from "reactstrap"
import { deleteParent, getImagingAndLap } from "helpers/api_helper"
import { IconSVG } from "components/Common/IconSvg"
import LeftArrow from "../../assets/icons/ArrowLeft.png"
import LabRequestViewBody from "./components/LabRequestViewBody"
import ActionButton from "./components/ActionButton"
function ImagingRequestList() {
  //meta title
  document.title = "Lab Request | SuPaw"

  const userDetails = useSelector(getUserDetails)
  const [list, setList] = useState([])

  const history = useHistory()

  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setGlobalLoading(true))
      const list = await getImagingAndLap({
        doctorId: userDetails._id,
        testType: "labs",
      })
      setList(list.data.filter(ell => ell.status === "Requested"))
      dispatch(setGlobalLoading(false))
    }
    fetchData()
  }, [dispatch])

  const handleAddNewRequest = () => {
    history.push("/add-lab-request")
  }

  console.log("list", list)

  const Cost = cell => {
    if (typeof cell.value === "number") {
      return "Rs " + Number(cell.value)
    }
    return ""
  }

  const columns = useMemo(
    () => [
      {
        Header: "Date Requested ",
        accessor: "createdAt",
        filterable: true,
        Cell: cellProps => {
          return <FormattedTime {...cellProps} />
        },
      },
      // {
      //   Header: "Requested By",
      //   accessor: "email",
      //   filterable: true,
      //   Cell: cellProps => {
      //     return <Date {...cellProps} />
      //   },
      // },
      {
        Header: "Patient",
        accessor: "petObjectId.fullName",
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },

      {
        Header: "Lab Type",
        accessor: "type",
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Lab Test",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Price",
        accessor: "pricing",
        filterable: true,
        Cell: cellProps => {
          return <Cost {...cellProps} />
        },
      },
      {
        Header: "Notes",
        accessor: "notes",
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          const cellData = cellProps.row.original;
          return (
            <div className="d-flex gap-3 justify-content-end">

              <LabRequestViewBody cellData={cellData}/>

              <ActionButton cellProps={cellProps} type={"edit"}/>

              <ActionButton cellProps={cellProps} type={"update"}/>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="mx-2 mb-4 ">
            <Col className="d-flex align-items-center justify-content-start gap-2">
              <img
                src={LeftArrow}
                width={32}
                onClick={() => history.goBack()}
                role="button"
              />
              <h4 className="mb-0 font-fontFamily fontSize-28">Lab Requests</h4>
            </Col>

            <Col className="d-flex align-items-center justify-content-between gap-4"></Col>
            {/* <div className="create-link">
              <Link
                color="primary"
                className="font-16 btn-block"
                to={"/add-appointment"}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New Appointment
              </Link>
            </div> */}
          </Row>
          {/* <Breadcrumbs
            title="Dashboard"
            titleLink="/"
            breadcrumbItem="Lab Requests"
          /> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={list}
                    isGlobalFilter={false}
                    isAddOptions={true}
                    handleOrderClicks={handleAddNewRequest}
                    customPageSize={10}
                    emptyListLink="/add-lab-request"
                    emptyText="No requests found"
                    emptyLinkText="+ New Lab Request"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
ImagingRequestList.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default ImagingRequestList
