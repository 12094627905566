import React, { useState, useEffect, useReducer } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  FormGroup,
  CardText,
  UncontrolledTooltip,
} from "reactstrap"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import ReactModal from "react-modal-resizable-draggable"
import "./video.scss"
//redux
import { useSelector, useDispatch } from "react-redux"

import { useHistory, useParams, withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import {
  fetchAppointmentsBasedOnId,
  updateVet,
  updateVisit,
  updatePrescription,
} from "helpers/api_helper"
import ButtonMain from "components/Common/Button"
import {
  calculateWeeksOfBirth,
  getDateDDMMYYYY,
  getDateTime,
  modifyAppointmentType,
} from "helpers/utils"
import Type from "./Type"
import Medicines from "./Medicines"
import ReactSelect from "react-select"
import {
  fetchAppointmentTypes,
  fetchMedicines,
  getAppointments,
  getServices,
  getTodaysAppointments,
  getUserDetails,
  setGlobalLoading,
} from "store/slices"
import { showMessage } from "components/Notification"

import ImagingTest from "./OtherRequests/ImagingTest"
import LabTest from "./OtherRequests/LabTest"
import VacinationTest from "./OtherRequests/Vacination"

import VideoChat from "pages/VideoScreens/VideoChat"
import classNames from "classnames"
import VacinationForm from "./OtherRequests/Vacination";
import appConstants from "../../constants/appConstants/appConstants.json";
import PrescriptionImagingAndLabTestListing from "components/Common/PrescriptionImagingAndLabTestListing"

const UserProfile = () => {
  //meta title
  document.title = "Profile | Supaw"

  const dispatch = useDispatch()

  const [key, setKey] = useState("Medicines")
  const [isLoading, setIsLoading] = useState(false)
  const [appointment, setAppointment] = useState({})
  const [medicines, setMedicines] = useState([])
  const [Vaccines, setVaccines] = useState([])
  const [modalIsOpen, setVideoModalState] = useState(false)
  const [isFullScreen, setFullScreen] = useState(false)
  const userDetails = useSelector(getUserDetails)
  const [relationIds, setRelationalIds] = useState({})
  const [diagnosisIds, setDiagnosisIds] = useState([])
  const [imagingTestValuesArray, setImagingTestValuesArray] = useState([])
  const [labTestValuesArray, setLabTestValuesArray] = useState([])
  // Callback function to add imaging test values to the array
  const handleImagingTestValues = values => {
    setImagingTestValuesArray(prevValues => [...prevValues, values])
  }
  const handleLabTestValues = values => {
    setLabTestValuesArray(prevValues => [...prevValues, values])
  }

  const { id } = useParams()

  const reducer = (state, action) => {
    switch (action.type) {
      case "edit":
        return { ...state, [action.payload.name]: action.payload.value }
      default:
        return state
    }
  }


  const [values, dispatchReducer] = useReducer(reducer, {
    notes: appointment.notes,
  })

  const stateChange = name => e => {
    const _value = e.target.value
    dispatchReducer({ type: "edit", payload: { name, value: _value } })
  }
  const history = useHistory()
  useEffect(() => {
    async function fetch() {
      if (id) {
        dispatch(setGlobalLoading(true));
        const response = await fetchAppointmentsBasedOnId(id);
        dispatch(setGlobalLoading(false));
        if (response.status === 1) {
          if (response.data.status !== "checkIn") {
            history.push(`/checkin/${id}`)
          }
          setAppointment(response.data)
          setKey(
            response.data.visitType == "Vaccination"
              ? "Vaccination"
              : "Medicines"
          )

          const obj = {}
          obj.petObjectId = response?.data?.petObjectId?._id
          obj.parentId = response?.data?.petObjectId?.parentId
          obj.doctorId = response?.data?.doctorId?._id
          console.log(response.data)
          const _diagnosisIds = response?.data?.primaryDiagnosis?.map(
            el => el.id
          )
          setDiagnosisIds(_diagnosisIds)
          setRelationalIds(obj)
        }
      }
      dispatch(fetchAppointmentTypes())
      // dispatch(fetchMedicines())
    }
    fetch()
  }, [dispatch])

  const handleFullScreen = () => {
    setFullScreen(current => !current)
  }

  if (!id || !appointment?._id) {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumb title="Supaw" breadcrumbItem="Prescriptionn" />

            <Row>
              <Card>
                <CardBody>
                  <CardText>Nothing to show here yet!</CardText>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }

  const handleSubmit = async () => {
    try {
      console.log("vac", Vaccines)
  
      for (let i = 0; i < medicines.length; i++) {
        const { id, medicine, dosage, duration } = medicines[i]
        if (!id || !medicine || !dosage || !duration) {
          showMessage({
            label: `Medicine entry ${
              i + 1
            } is incomplete. Please fill all required fields.`,
            status: "error",
          })
          return // Stop submission if validation fails
        }
      }
  
      let obj = {
        medicines,
        notes: values.notes,
        imagingTests: imagingTestValuesArray,
        labTests: labTestValuesArray,
        vaccinations: Vaccines.map(it => {
          if(!it?.vaccine?.value){
            showMessage({
              label: "Vaccine entry is incomplete. Please fill all required fields.",
              status: "error",
            })
            throw new Error("Vaccine entry is incomplete. Please fill all required fields");
          }
          return {
            vaccine: it.vaccine.value,
            type: it.type,
            date: it?.DOV,
            expiryDate: it?.EOV,
          }
        }),
      }
  
      const noMedAdded = medicines.filter(el => !el.medicine)
      const noDosage = medicines.filter(el => !el.dosage)
      const noDuration = medicines.filter(el => !el.duration)
  
      const response = await updatePrescription(id, obj)
      if (response.status == 1) {
        showMessage({
          label: "Prescription created successfully",
          status: "success",
        })
        dispatch(getTodaysAppointments())
        history.push(`/prescription-details/${id}`)
      }
    } catch (error) {
      console.error("Error in UserProfile:handleSubmit:",error)
    }
  }
  const handleAddMed = data => {
    console.log(data, "data")
    setMedicines(data)
  }
  const handleAddVac = data => {
    console.log(data, "dddaartttaa")
    setVaccines(data)
  }

  const BackButton = () => {
    window.history.back()
  }

  const renderCells = type => {
    const array = appointment?.primaryDiagnosis.filter(el => el.type === type)
    return (
      <div className="cell-tag-container">
        {array?.length ? (
          <>
            <h6>
              {type === "primary" ? "Primary Diagnosis" : "Secondary Diagnosis"}
              :
            </h6>
            <div className="cell-tags-roe">
              {array.map(({ title }, index) => {
                return (
                  <div key={`${title}-${index}`} className="cell-tags">
                    {title}
                  </div>
                )
              })}
            </div>
          </>
        ) : null}
      </div>
    )
  }

  const renderAllergies = () => {
    return (
      <div className="cell-tag-container  ">
        {appointment?.patientAllergies?.length ? (
          <>
            <div className="cell-tags-roe">
              {appointment.patientAllergies.map(({ title }, index) => {
                return (
                  <div key={`${title}-${index}`} className="cell-tags">
                    {title}
                  </div>
                )
              })}
            </div>
          </>
        ) : null}
      </div>
    )
  }

  const handleOpenVideo = () => {
    var body = document.body
    body.classList.add("vertical-collpsed")
    body.classList.remove("sidebar-enable")

    setVideoModalState(true)
  }

  const handleOnEnd = () => {
    setVideoModalState(false)
  }

  const deleteClickHandler = ( item ) => {
    console.log("Delete Item::", item, item.testType === appConstants.IMAGING, item.testType === appConstants.LAB_TEST)
    if(item.testType === appConstants.IMAGING){
      // setImagingTestValuesArray((prev) => prev.filter(i => i.type!== item.type));
      console.log("setImagingTestValuesArray" , imagingTestValuesArray.filter(i => i.type!== item.type));
      const imagingsArray = imagingTestValuesArray.filter(i => i.type!== item.type);
      setImagingTestValuesArray(imagingsArray);
    } else if(item.testType === appConstants.LAB_TEST) {
      // setLabTestValuesArray((prev) => prev.filter(i => i.title !== item.type));
      console.log("setImagingTestValuesArray" , labTestValuesArray.filter(i => i.title!== item.type));
      const labTestsArray = labTestValuesArray.filter(i => i.title!== item.type);
      setLabTestValuesArray(labTestsArray);
    }
  }

  const renderPrescriptionForm = () => {
    return (
      <Form
        className="form-horizontal row"
        onSubmit={e => {
          e.preventDefault()
          return false
        }}
      >
        <Col lg={6}>
          <div className="row">
            <Label
              htmlFor="projectdesc"
              className="col-form-label col-lg-5 text-left text-input-label font-fontFamily"
            >
              Checkin:
            </Label>
            <div className="col-lg-7">
              <Label
                htmlFor="projectdesc"
                className="col-form-label col-lg-8 text-primary text-left text-input-label-value font-fontFamily"
              >
                {getDateTime(appointment?.checkIn)}
              </Label>
            </div>
          </div>
          <div className="row">
            <Label className="col-form-label col-lg-5 text-left text-input-label font-fontFamily">
              Primary Diagnosis:
            </Label>
            <div className="col-lg-7">
              {renderCells("primary")}
              {renderCells("secondary")}
            </div>
          </div>

          <div className="row">
            <Label className="col-form-label col-lg-5 text-left text-input-label font-fontFamily">
              Patient Allergies:
            </Label>
            <div className="col-lg-7">{renderAllergies()}</div>
          </div>
          <div className="row">
            <Label
              htmlFor="projectdesc"
              className="col-form-label col-lg-5 text-left text-input-label font-fontFamily"
            >
              Name:
            </Label>
            <div className="col-lg-7">
              <Label
                htmlFor="projectdesc"
                className="col-form-label col-lg-8 text-primary text-left text-input-label-value font-fontFamily"
              >
                {appointment?.petObjectId?.fullName}
              </Label>
            </div>
          </div>
          <div className="row">
            <Label className="col-form-label col-lg-5 text-left text-input-label font-fontFamily">
              Type:
            </Label>
            <div className="col-lg-7">
              <Label className="col-form-label col-lg-8 text-primary text-left text-input-label-value font-fontFamily">
                {appointment?.petObjectId?.petType}
              </Label>
            </div>
          </div>
          <div className="row">
            <Label className="col-form-label col-lg-5 text-left text-input-label font-fontFamily">
              Breed:
            </Label>
            <div className="col-lg-7">
              <Label className="col-form-label col-lg-8 text-primary text-left text-input-label-value font-fontFamily">
                {appointment?.petObjectId?.petBreed}
              </Label>
            </div>
          </div>
          <div className="row">
            <Label className="col-form-label col-lg-5 text-left text-input-label font-fontFamily">
              Gender:
            </Label>
            <div className="col-lg-7">
              <Label
                htmlFor="projectdesc"
                className="col-form-label col-lg-8 text-primary text-left text-input-label-value font-fontFamily"
              >
                {appointment?.petObjectId?.gender}
              </Label>
            </div>
          </div>
          <div className="row">
            <Label className="col-form-label col-lg-5 text-left text-input-label font-fontFamily">
              Date of Birth:
            </Label>
            <div className="col-lg-7">
              <Label
                htmlFor="projectdesc"
                className="col-form-label col-lg-8 text-primary text-left text-input-label-value font-fontFamily"
              >
                {getDateDDMMYYYY(appointment?.petObjectId?.dateOfBirth)}
              </Label>
            </div>
          </div>
          <div className="row">
            <Label className="col-form-label col-lg-5 text-left text-input-label font-fontFamily">
              Reason For Visit:
            </Label>
            <div className="col-lg-7">
              <Label
                htmlFor="projectdesc"
                className="col-form-label col-lg-7 text-primary text-left text-input-label-value font-fontFamily"
              >
                {appointment.reasonForVisit}
              </Label>
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <div className="col-lg-4" />
          <div className="col-lg-6 d-flex justify-content-end h-[2rem]">
            <span className="gray-note font-fontFamily">
              Unique Id:{" "}
              <span className="gray-note--primary font-fontFamily">
                {appointment?.petObjectId?.petId}
              </span>
            </span>
          </div>
          <br />
          <br />
          {/* <div className="row">
            <Label
              htmlFor="projectdesc"
              className="col-form-label col-lg-4 text-left text-input-label font-fontFamily"
            >
              Name:
            </Label>
            <div className="col-lg-7">
              <Label
                htmlFor="projectdesc"
                className="col-form-label col-lg-8 text-primary text-left text-input-label-value font-fontFamily"
              >
                {appointment?.petObjectId?.fullName}
              </Label>
            </div>
          </div>
          <div className="row">
            <Label className="col-form-label col-lg-4 text-left text-input-label font-fontFamily">
              Type:
            </Label>
            <div className="col-lg-7">
              <Label className="col-form-label col-lg-8 text-primary text-left text-input-label-value font-fontFamily">
                {appointment?.petObjectId?.petType}
              </Label>
            </div>
          </div>
          <div className="row">
            <Label className="col-form-label col-lg-4 text-left text-input-label font-fontFamily">
              Breed:
            </Label>
            <div className="col-lg-7">
              <Label className="col-form-label col-lg-8 text-primary text-left text-input-label-value font-fontFamily">
                {appointment?.petObjectId?.petBreed}
              </Label>
            </div>
          </div>
          <div className="row">
            <Label className="col-form-label col-lg-4 text-left text-input-label font-fontFamily">
              Gender:
            </Label>
            <div className="col-lg-7">
              <Label
                htmlFor="projectdesc"
                className="col-form-label col-lg-8 text-primary text-left text-input-label-value font-fontFamily"
              >
                {appointment?.petObjectId?.gender}
              </Label>
            </div>
          </div>
          <div className="row">
            <Label className="col-form-label col-lg-4 text-left text-input-label font-fontFamily">
              Date of Birth:
            </Label>
            <div className="col-lg-7">
              <Label
                htmlFor="projectdesc"
                className="col-form-label col-lg-8 text-primary text-left text-input-label-value font-fontFamily"
              >
                {getDateDDMMYYYY(appointment?.petObjectId?.dateOfBirth)}
              </Label>
            </div>
          </div>
          <div className="row">
            <Label className="col-form-label col-lg-4 text-left text-input-label font-fontFamily">
              Reason For Visit:
            </Label>
            <div className="col-lg-7">
              <Label
                htmlFor="projectdesc"
                className="col-form-label col-lg-7 text-primary text-left text-input-label-value font-fontFamily"
              >
                {appointment.reasonForVisit}
              </Label>
            </div>
          </div> */}
        </Col>

        {!modalIsOpen && (
          <div
            style={{
              marginBottom: 20,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ButtonMain
              onClick={handleOpenVideo}
              style={{ maxWidth: 120 }}
              className="btn btn-small btn-secondary btn-secondary2 mx-4"
              disabled={appointment?.visitType?.trim() !==  appConstants?.VIRTUAL_CARE?.trim()}
            >
              Start Virtual Visit
            </ButtonMain>
          </div>
        )}

        {/* <Row>
          <Col lg={12}>
            <FormGroup className="mb-4" row>
              <Label
                htmlFor="projectdesc"
                className="col-form-label col-lg-2  text-left text-input-label text-input-label--md font-fontFamily"
              >
                Notes
              </Label>
              <div className="col-lg-8">
                <textarea
                  className="form-control "
                  id="projectdesc"
                  style={{ resize: "none", minHeight: 120 }}
                  onChange={stateChange("notes")}
                  rows="16"
                  defaultValue={appointment.notes}
                  placeholder="Enter Notes"
                />
              </div>
            </FormGroup>
          </Col>
        </Row> */}
        <hr />
        <h4 className="mb-sm-0  font-fontFamily fontSize-24">Basic Vitals</h4>
        <Row className="mt-20">
          <Col lg={5}>
            <FormGroup className="mb-4  row">
              <Label
                htmlFor="projectdesc"
                className="col-form-label col-lg-5 text-left text-input-label font-fontFamily"
              >
                Weight
              </Label>
              <div className="col-lg-7">
                <Label
                  htmlFor="projectdesc"
                  className="col-form-label col-lg-8 text-primary text-left text-input-label font-fontFamily"
                >
                  {appointment?.basicVitals?.weight} Kg.
                </Label>
              </div>
            </FormGroup>
          </Col>
          <Col lg={7}>
            <FormGroup className="row mb-9">
              <Label
                htmlFor="projectdesc"
                className="col-form-label col-lg-5 text-left text-input-label text-input-label--md font-fontFamily"
              >
                Temperature
              </Label>
              <div className="col-lg-7">
                <Label
                  htmlFor="projectdesc"
                  className="col-form-label col-lg-8 text-primary text-left text-input-label font-fontFamily"
                >
                  {appointment?.basicVitals?.temperature} degree (c)
                </Label>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Col lg={12}>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={k => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="Medicines" title="Medicines">
              <h4 className="mb-sm-0  font-fontFamily fontSize-24">
                Medicines
              </h4>
              <Medicines ids={diagnosisIds} onConfirm={handleAddMed} />
            </Tab>

            <Tab eventKey="Vaccination" title="Vaccination">
              <h4 className="mb-sm-0  font-fontFamily fontSize-24">Vaccines</h4>
              <VacinationForm
                ids={diagnosisIds}
                agegroup={calculateWeeksOfBirth(
                  appointment?.petObjectId?.dateOfBirth
                )}
                petType={appointment?.petObjectId?.petType}
                onConfirm={handleAddVac}
              />
            </Tab>
          </Tabs>
        </Col>
        <Col lg={12}>
          <hr />
          <h4 className="mb-4 font-fontFamily fontSize-24">Others</h4>
          <ImagingTest
            relationIds={relationIds}
            onImagingTestValues={handleImagingTestValues}
            id={id}
          />
          <LabTest
            relationIds={relationIds}
            onLabTestValues={handleLabTestValues}
            id={id}
          />
        </Col>

        <Col lg={12}>
        <hr/>
         <PrescriptionImagingAndLabTestListing 
          data={[
            ...imagingTestValuesArray.map(( item) => ({...item, testType: appConstants.IMAGING })), 
            ...labTestValuesArray.map(( item) => ({ type: item?.title, notes: item?.instructions, testType:  appConstants.LAB_TEST }))
          ]}
          deleteClickHandler={deleteClickHandler}
         />

        </Col>

        <Row className="justify-content-end mt-8">
          <ButtonMain
            className="btn btn-secondary btn-med "
            type="button"
            onClick={BackButton}
          >
            Cancel
          </ButtonMain>
          <ButtonMain
            isLoading={isLoading}
            className="btn btn-primary btn-med"
            type="button"
            onClick={handleSubmit}
          >
            Submit <i className="mdi mdi-arrow-down" id="edittooltip" />
          </ButtonMain>
        </Row>
      </Form>
    )
  }

 

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Supaw" breadcrumbItem="Prescription" />

          <Row>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={modalIsOpen ? 9 : 12}>
                    {renderPrescriptionForm()}
                  </Col>
                  {modalIsOpen ? (
                    <Col lg={3}>
                      <div
                        className={classNames("video-container", {
                          "video-container--full": isFullScreen,
                        })}
                      >
                        <VideoChat
                          onEnd={handleOnEnd}
                          onFullScreen={handleFullScreen}
                          isFullScreen={isFullScreen}
                          userName={userDetails?.fullName}
                          roomName={id || appointment?._id}
                        />
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </CardBody>
            </Card>
            {/* </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
