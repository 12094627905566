import React, { useEffect, useState } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "./dashboard.scss"
import {
  Card,
  Row,
  Col,
  ListGroup,
  ButtonGroup,
  Dropdown,
} from "react-bootstrap"
import { Doughnut, Bar } from "react-chartjs-2"
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js"
import { FaCaretDown } from "react-icons/fa"
import patientImage from "../../assets/images/patient.svg"
import consultantImage from "../../assets/images/consultant.svg"
import vaccination from "../../assets/images/vaccinnation.svg"
import BarChart from "components/Dashboard/BarChart"
import DoctorSchedule from "components/Dashboard/ScheduelList"
import UpcomingBirthdays from "../../components/Dashboard/UpcomingBirthday"
import DashBoardHeader from "../../components/Dashboard/DashboardHeader"
import { DonutChart } from "components/Dashboard/DonutChart"
import AppointmentsCard from "components/Dashboard/AppoinementCard"
import { fetchDashboardApi, fetchDashboardApiTwo } from "helpers/api_helper"
import { useDispatch, useSelector } from "react-redux"
import { fetchPetTypes, getDashboardApiTwo, getDashboardData, getTypes, getUserDetails, setGlobalLoading } from "store/slices"
import appConstants  from "../../constants/appConstants/appConstants.json"
import { extractConsultationsTypeCounts } from "helpers/dashboardHelper"
import { IconSVG } from "components/Common/IconSvg"
import { useHistory } from 'react-router-dom'
import { capitalizeFirstLetter } from "helpers/commonHelpers"

// Register Chart.js components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
)

function Dashboard() {

  const [appointment, setAppointment] = useState([]);
  const [firstApiResponse, setFirstApiResponse] = useState({});
  const [appointmentType, setAppointmentType] = useState("Dog");
  const [timeRange, setTimeRange] = useState({
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleDateString("in"),
    endDate: new Date().toLocaleDateString("in") 
  });
  const [appointmentStatus, setAppointmentStatus] = useState("completed");
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const dashboardStoreData = useSelector(getDashboardData);
  const userDetails = useSelector(getUserDetails);
  const petTypes = useSelector(getTypes);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dashboardData();
  }, [appointmentType, appointmentStatus]);
  
  async function dashboardData() {
    dispatch(setGlobalLoading(true))
    const params = {
      "vetId": userDetails._id,
      "startDate": timeRange.startDate.split("/").reverse().join("-"),
      "endDate": timeRange.endDate.split("/").reverse().join("-"),
      "appointmentStatus": appointmentStatus,
      // "petType": appointmentType
  }
    const [response, _, __ ] = await Promise.all([
      fetchDashboardApi(params),
      dispatch(getDashboardApiTwo(
        appointmentType, 
        userDetails._id, 
        timeRange.startDate.split("/").reverse().join("-"), 
        timeRange.endDate.split("/").reverse().join("-")
      )),
      dispatch(fetchPetTypes())
    ])

    if(response && response.status) {
      setFirstApiResponse(response.data);
      if(response.data.appointments) {
        setAppointment(response.data.appointments);
      }
    }
    dispatch(setGlobalLoading(false))
  }

  const handleApplyRange = () => {
    dashboardData();
  }

  const handleRebookAppointment = (petData) => {
    history.push(`/add-appointment?parentId=${petData.parentId}&petId=${petData.petId}`)
  }

  const handlePrintPrescription = (appointmentId) => {
    history.push(`/prescription-details/${appointmentId}`)
  }


  return (
    <div className="px-3 " style={{ backgroundColor: "#F0F2F3" }}>
      <DashBoardHeader setTimeRange={setTimeRange} timeRange={timeRange} handleApplyRange={handleApplyRange}/>

      {/* First Row: Stats */}
      <Row>
        <Col xxl={9}>
          <Row className="mb-3 sm-gap">
            <Col sm={12} md={4}>
              <StatsCard title={appConstants.NEW_PATIENTS} value={firstApiResponse?.newPatients} icon={patientImage} />
            </Col>
            <Col sm={12} md={4}>
              <StatsCard
                title={appConstants.CONSULTATIONS}
                value={firstApiResponse?.consultations}
                icon={consultantImage}
              />
            </Col>
            <Col sm={12} md={4}>
              <StatsCard
                title={appConstants.VACCINATIONS_DATA}
                value={firstApiResponse?.vaccinationsData}
                icon={vaccination}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} sm={6} lg={4}>
              <DonutChart
                title={appConstants.PET_TYPES}
                data={firstApiResponse?.petCountAndTypeDistribution?.map((pet) => { return pet.counts?.Male + pet.counts?.Female || 0}) || []}
                labels={firstApiResponse?.petCountAndTypeDistribution?.map((pet) => pet.petType) || []}
                colors={Object.values(appConstants.THEME_COLORS)}
                text={appConstants.PETS}
                genderDistribution={firstApiResponse?.petCountAndTypeDistribution?.map((pet) => { return { Male: pet.counts?.Male, Female: pet.counts?.Female }})}
              />
            </Col>
            <Col xs={12} lg={4}>
              <BarChart
                title={appConstants.TOP_INVENTORY_ITEM_SOLD}
                labels={["Dolo", "Formosent 500", "Disprin", "Combiflam", "Olfagol 250"]}
                data={[ 100, 80, 78, 60, 44]}
                thickness={15}
              />
            </Col>
            <Col xs={12} lg={4}>
              <UpcomingBirthdays birthdays={firstApiResponse?.petsWithUpcomingBirthdays}/>
            </Col>
          </Row>
        </Col>

        {/* Appointments List */}
        <Col xxl={3}>
          <AppointmentsList className="flex-grow-1" 
            appointmentList={appointment}
            setAppointmentType={setAppointmentType}
            appointmentType={appointmentType}
            setAppointmentStatus={setAppointmentStatus}
            appointmentStatus={appointmentStatus}
            petTypes={petTypes || []}
            hoveredIndex={hoveredIndex} 
            setHoveredIndex={setHoveredIndex}
            handleRebookAppointment={handleRebookAppointment}
            handlePrintPrescription={handlePrintPrescription}
          />
        </Col>
      </Row>

      {/* Third Row: Lab Tests and Imaging Tests */}
      <Row className="mb-4 gap-0">
        <Col xs={12} md={6}>
          <BarChart
            title={appConstants.TOP_LAB_TESTS}
            labels={dashboardStoreData?.topLabTests?.map((test) => test._id) || []}
            data={dashboardStoreData?.topLabTests?.map((test) => test.count) || []}
            boolean={false}
            thickness={30}
          />
        </Col>
        <Col xs={12} md={6}>
          <BarChart
            title={appConstants.TOP_IMAGING_TESTS}
            labels={dashboardStoreData?.topImagingTests?.map((test) => test._id) || []}
            data={dashboardStoreData?.topImagingTests?.map((test) => test.count) || []}
            boolean={false}
            thickness={30}
          />
        </Col>
      </Row>

      {/* Fourth Row: Doctor's Schedule and Upcoming Birthdays */}
      <Row className="doctorSchedual">
        <Col xs={12} md={12}>
          <DoctorSchedule
            title={appConstants.DOCTORS_SCHEDULE}
            appointments={dashboardStoreData?.doctorsAppointment}
          />
        </Col>
      </Row>

    </div>
  )
}

function StatsCard({ title, value, icon }) {
  const isConsultaion = title === appConstants.CONSULTATIONS;
  let totalConsultation = 0;

  if(value && isConsultaion && Object.values(value) && Object.values(value).length){
    Object.values(value).forEach(value => {
      totalConsultation = totalConsultation + value?.due || 0 + value?.completed || 0;
    })
  }
   const [visitCount, urgentCount, virtualCount ]= extractConsultationsTypeCounts(value);

  return (
    <Card
      className="custom-card shadow-sm border-light h-100"
      style={{ borderRadius: "15px", padding: "0px" }}
    >
      <Card.Body className="d-flex align-items-center flex-wrap">
        <img
          src={icon}
          className="me-3"
          style={{ width: "40px", maxWidth: "100%", height: "auto" }}
          alt="icon"
        />
        <div className="flex-grow-1" style={{ width: "100%"}}>
          <Card.Title
            className="mb-0 text-truncate"
            style={{ fontSize: "1rem" }}
          >
            <div style={{ display: "flex", alignItems:'center', justifyContent: "space-between", marginBottom: "5px"}}>
              <div style={{ fontSize: "1rem"}}>
                {title}
              </div>
              <div style={{ fontSize: "1.5rem"}}>
                {title === appConstants.CONSULTATIONS ? totalConsultation : title === appConstants.NEW_PATIENTS ? value : isNaN(value?.completed + value?.due) ? 0 : value?.completed + value?.due }
              </div>
            </div>
          </Card.Title>
          
          {title === appConstants.VACCINATIONS_DATA && (<div style={{ width: "100%", display: "flex",  justifyContent: "space-between" }}>
            <div style={{ flex: 0.4, padding: "5px" }}>
              <h6 style={{color: "#e75c25"}}>Completed</h6>
              <p><strong>{ title === appConstants.CONSULTATIONS ? 0 : value?.completed || 0}</strong></p>
            </div>
            <div style={{ flex: 0.4, padding: "5px" }}>
              <h6 style={{color: "#e75c25"}}>Due</h6>
              <p><strong>{title === appConstants.CONSULTATIONS ? 0 : value?.due || 0}</strong></p>
            </div>
          </div>)}

          {title === appConstants.CONSULTATIONS && (<div style={{ width: "100%", display: "flex",  justifyContent: "space-between" }}>
            <div style={{ flex: 0.3 }}>
              <h6 style={{color: "#e75c25"}}>Visit</h6>
              <p><strong>{visitCount}</strong></p>
            </div>
            <div style={{ flex: 0.3}}>
              <h6 style={{color: "#e75c25"}}>Virtural</h6>
              <p><strong>{virtualCount}</strong></p>
            </div>
            <div style={{ flex: 0.3}}>
              <h6 style={{color: "#e75c25"}}>Emergency</h6>
              <p><strong>{urgentCount}</strong></p>
            </div>
          </div>)}
        </div>
      </Card.Body>
    </Card>
  )
}

function AppointmentsList({ 
  appointmentList, 
  setAppointmentType, 
  appointmentType, 
  setAppointmentStatus, 
  appointmentStatus, 
  petTypes, 
  hoveredIndex, 
  setHoveredIndex,
  handleRebookAppointment,
  handlePrintPrescription
}) {


  return (
    <Card className="mb-4 appointment-card dashboard-card" style={{ borderRadius: "15px" }}>
      <Card.Body>
        {/* Header */}
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="card-title">Appointments</h5>
          {/* Dropdowns */}
          <div className="d-flex justify-content-start mb-1 p-0 ml-1">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle variant="light" className="dropdown-toggle" style={{ padding: "4px 6px"}}>
                {capitalizeFirstLetter(appointmentStatus)} <IconSVG icon='arrowDown' />
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ marginTop: "35px" }}>
                {appConstants.APPOINTMENT_STATUS.map((status, index) => {
                  return (
                    <Dropdown.Item key={index} onClick={() => setAppointmentStatus(status)}>
                      {capitalizeFirstLetter(status)}
                    </Dropdown.Item>
                  )
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>


        {/* Appointment List */}
        <ListGroup
          variant="flush"
          style={{ overflowY: "auto", maxHeight: "453px", overflowX: "hidden" }}
        >
          { appointmentList && appointmentList.length ? appointmentList?.map((appointment, index) => (
            <ListGroup.Item key={index} className="d-flex align-items-center" onMouseEnter={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(null)} style={{ position: "relative"}}>
              <div
                style={{
                  borderLeft: `5px solid ${appointment?.visitType === appConstants.VACCINATION ? 'var(--primary-color)' : 'var(--secondary-color)'}`,
                  paddingLeft: "10px",
                  width: "100%",
                }}
              >
                <div>
                  {appointment?.petType} - <strong>{appointment?.petObjectId?.fullName}</strong> - {appointment?.visitType}
                </div>
                <small className="text-muted">
                  {appointment.time} • 30min • {new Date(appointment?.date).toLocaleDateString()}
                </small>
              </div>

              <div className={`sliding-options ${hoveredIndex === index ? "slide-in" : ""}`}>
                <div className="option" onClick={() => handleRebookAppointment({ parentId: appointment?.parentId, petId: appointment?.petObjectId?.petId })} data-bs-toggle="tooltip" data-bs-placement="top" title="Rebook Appointment">
                  <IconSVG icon={"rebook"}/>
                </div>
                <div className="option" onClick={() => handlePrintPrescription(appointment?._id)} data-bs-toggle="tooltip" data-bs-placement="top" title="Print Prescription">
                  <IconSVG icon={"print"}/>
                </div>
              </div>
            </ListGroup.Item>
          )) : (<div className="flex-center">
            <h6>No appointment found.</h6>
          </div>)}
        </ListGroup>
      </Card.Body>
    </Card>
  )
}

export default Dashboard
