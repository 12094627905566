import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  CardText,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import { isEmpty, map } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//Import Image
import logo from "../../../assets/images/supaw-vets.svg"
import { fetchAppointmentsBasedOnId } from "helpers/api_helper"
import { getDateDDMMYYYY } from "helpers/utils"
import { useDispatch } from "react-redux"
import { setGlobalLoading } from "store/slices"
import moment from "moment"

import { jsPDF } from "jspdf"
import html2canvas from "html2canvas"
import { calculateAge, capitalizeFirstLetter } from "helpers/commonHelpers"
import rxImage from "../../../assets/images/rx-icon-25472.png";
import { IoCall, IoLocation } from "react-icons/io5";
import { MdPets } from "react-icons/md";
import VetImage from "../../../assets/images/veterinary.png";
const InvoiceDetail = props => {
  const [details, setDetails] = useState({})
  const [noPrescriptionFound, setPrescriptionFound] = useState(false)
  console.log("Details", details)
  //meta title
  document.title = "Prescription Detail | Supaw"

  const {
    match: { params },
  } = props
  const dispatch = useDispatch()
  useEffect(() => {
    async function fetchData() {
      document.body.classList.add("prescription-container")
      if (params && params.id) {
        dispatch(setGlobalLoading(true))
        const _details = await fetchAppointmentsBasedOnId(params.id)
        if (_details.status === 1 && _details.data) {
          setDetails(_details.data)
          dispatch(setGlobalLoading(false))
        } else {
          setPrescriptionFound(true)
          dispatch(setGlobalLoading(false))
        }
      }
    }
    fetchData()
  }, [])

  //Print the Invoice
  const printInvoice = () => {
    window.print()
  }
  const _specialization =
    typeof details?.doctorId?.specialization === "object"
      ? details?.doctorId?.specialization?.map(el => el.label).join(", ")
      : details?.doctorId?.specialization
  const currentDate = new Date()
  const formattedDate = `${currentDate.getDate().toString().padStart(2, "0")}${(
    currentDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}${currentDate.getFullYear()}`

  const handleDownloadPDF = async () => {
    try {
      // Create a new jsPDF instance with A4 dimensions
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });

      // Select only the download-pdf-content div
      const element = document.querySelector('.download-pdf-content');

      if (!element) {
        console.error('PDF content element not found');
        return;
      }

      // Use html2canvas with higher resolution and quality
      const canvas = await html2canvas(element, {
        scale: 3,
        useCORS: true,
        logging: false,
        allowTaint: true,
        backgroundColor: '#ffffff',
        width: element.scrollWidth,
        height: element.scrollHeight
      });

      // Convert canvas to image
      const imgData = canvas.toDataURL('image/jpeg', 1.0);

      // A4 dimensions
      const pageWidth = 210;
      const pageHeight = 297;

      // Calculate image dimensions to fit A4
      const imgWidth = pageWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add first page
      doc.addImage(
        imgData,
        'JPEG',
        0,    // x-coordinate
        0,    // y-coordinate
        imgWidth,
        imgHeight,
        '',
        'FAST'
      );

      // If image is taller than A4, add additional pages
      let heightLeft = imgHeight;
      let position = 0;
      let pageNum = 1;

      while (heightLeft > pageHeight) {
        position = heightLeft - imgHeight;
        doc.addPage();
        pageNum++;

        doc.addImage(
          imgData,
          'JPEG',
          0,
          -position,
          imgWidth,
          imgHeight,
          '',
          'FAST'
        );

        heightLeft -= pageHeight;
      }

      // Generate filename with current date
      const currentDate = new Date().toISOString().split('T')[0];
      const petName = details?.petObjectId?.fullName || 'Pet';
      const filename = `Prescription_${petName}_${currentDate}.pdf`;

      // Save the PDF
      doc.save(filename);

    } catch (error) {
      console.error('Error generating PDF:', error);
      // Optionally show an error notification
      toast.error('Failed to generate PDF. Please try again.');
    }
  };


  return (
    <React.Fragment>
      <div className="page-content prescription-container">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Invoices" breadcrumbItem="Prescription: Detail" />
          {!isEmpty(details) || !noPrescriptionFound ? (
            <>
              <Row className="download-pdf-content">
                <Col sm="12">
                  <div className="invoice-title" style={{ backgroundColor: "#fff", padding: "15px" }}>
                    <div className="float-end d-flex align-items-center justify-content-center" style={{ height: "90px", width: "90px", border: "1px solid tranparent", borderRadius: "50%" }}>
                      <img
                        className="logo"
                        src={details?.doctorId?.profile || VetImage}
                        alt="vet-logo"
                        style={{ height: "100%", width: "100%", objectFit: "cover" }}
                      />
                    </div>
                    <div style={{ height: "100px", padding: "15px" }}>
                      <div className="prescription-doc-details">
                        <div className="d-flex flex-column align-items-start gap-1">
                          <h2 className="fw-bold" style={{ color: "var(--primary-color)" }}>
                            Dr. {details?.doctorId?.fullName.split(" ").map((text) => capitalizeFirstLetter(text)).join(" ")}
                          </h2>
                          {"\n"}
                          <h4 style={{ color: "var(--secondary-color)" }}>{details?.doctorId?.specialization.map((item) => item.value).join(", ")}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Card style={{ paddingTop: "20px" }}>
                    <hr style={{ padding: 0 }} />
                    <CardBody style={{ padding: 20, paddingTop: 0 }}>
                      <div >
                        <Row style={{ paddingBottom: "10px" }}>
                          <Col sm="4" style={{ padding: 0 }}>
                            <span style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-start" }}>
                              <h3 className="font-size-14 fw-bold head m-0">
                                Patient Name:
                              </h3>
                              <p className="head-data m-0 " style={{ flex: 1, border: "1px solid transparent", borderBottomColor: "#000", fontSize: "0.75rem" }}>
                                &nbsp;&nbsp; {capitalizeFirstLetter(details?.petObjectId?.fullName || "")}
                              </p>
                            </span>
                          </Col>
                          <Col sm="5" style={{ padding: 0 }}>
                            <span style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-start" }}>
                              <h3 className="font-size-14 fw-bold head m-0">
                                Parent Name:
                              </h3>
                              <p className="head-data m-0" style={{ flex: 1, border: "1px solid transparent", borderBottomColor: "#000", fontSize: "0.75rem" }}>
                                &nbsp;&nbsp; {capitalizeFirstLetter(details?.parentId?.fullName.split(" ").slice(0, 2).join(" ") || "")}
                              </p>
                            </span>
                          </Col>
                          <Col sm="3" style={{ padding: 0 }}>
                            <span style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-start" }}>
                              <h3 className="font-size-14 fw-bold head m-0">
                                Date:
                              </h3>
                              <p className="head-data m-0" style={{ flex: 1, border: "1px solid transparent", borderBottomColor: "#000", fontSize: "0.75rem" }}>
                                &nbsp;&nbsp; {new Date().toLocaleDateString()}
                              </p>
                            </span>
                          </Col>
                        </Row>

                        <Row style={{ paddingBottom: "10px" }}>
                          <Col sm="4" style={{ padding: 0 }}>
                            <span style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-start" }}>
                              <h3 className="font-size-14 fw-bold head m-0">
                                Patient Type:
                              </h3>
                              <p className="head-data m-0" style={{ flex: 1, border: "1px solid transparent", borderBottomColor: "#000", fontSize: "0.75rem" }}>
                                &nbsp;&nbsp; {capitalizeFirstLetter(details?.petObjectId?.petType || "")}
                              </p>
                            </span>
                          </Col>

                          <Col sm="5" style={{ padding: 0 }}>
                            <span style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-start" }}>
                              <h3 className="font-size-14 fw-bold head m-0">
                                Patient Age:
                              </h3>
                              <p className="head-data m-0" style={{ flex: 1, border: "1px solid transparent", borderBottomColor: "#000", fontSize: "0.75rem" }}>
                                &nbsp;&nbsp; {details?.petObjectId?.dateOfBirth ? calculateAge(details?.petObjectId?.dateOfBirth) : "N/A"}
                              </p>
                            </span>
                          </Col>

                          <Col sm="3" style={{ padding: 0 }}>
                            <span style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-start" }}>
                              <h3 className="font-size-14 fw-bold head m-0">
                                Gender:
                              </h3>
                              <p className="head-data m-0 font-size-14" style={{ flex: 1, border: "1px solid transparent", borderBottomColor: "#000" }}>
                                &nbsp;&nbsp; {details?.petObjectId?.gender || ""}
                              </p>
                            </span>
                          </Col>

                        </Row>
                        <Row style={{ paddingBottom: "10px" }}>
                          <Col sm="3" style={{ padding: 0 }}>
                            <span style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-start" }}>
                              <h3 className="font-size-14 fw-bold head m-0">
                                Type:
                              </h3>
                              <p className="head-data m-0" style={{ flex: 1, border: "1px solid transparent", borderBottomColor: "#000", fontSize: "0.75rem" }}>
                                &nbsp;&nbsp; {details?.visitType || "N/A"}
                              </p>
                            </span>
                          </Col>
                          <Col sm="5" style={{ padding: 0 }}>
                            <span style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-start" }}>
                              <h3 className="font-size-14 fw-bold head m-0">
                                Diagnosis:
                              </h3>
                              <p className="head-data m-0" style={{ flex: 1, border: "1px solid transparent", borderBottomColor: "#000", fontSize: "0.75rem" }}>
                                &nbsp;&nbsp; 
                                {details?.primaryDiagnosis
                                ?.filter(el => el.type === "primary")
                                .map(el1 => {
                                  return el1.title;
                                })
                                }
                                {details?.primaryDiagnosis
                                ?.filter(el => el.type === "secondary")
                                .map(el1 => {
                                  return el1.title;
                                })}
                              </p>
                            </span>
                          </Col>
                          <Col sm="4" style={{ padding: 0 }}>
                            <span style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-start" }}>
                              <h3 className="font-size-14 fw-bold head m-0">
                                Allergies:
                              </h3>
                              <p className="head-data m-0" style={{ flex: 1, border: "1px solid transparent", borderBottomColor: "#000", fontSize: "0.75rem" }}>
                                &nbsp;&nbsp; 
                                {details?.patientAllergies?.map(el1 => {
                                        return el1.title;
                                }).join(", ")}
                              </p>
                            </span>
                          </Col>
                        </Row>

                        <Row style={{ minHeight: "31rem", position: 'relative' }}>
                          <div style={{ height: '70px', width: "70px" }}>
                            <img
                              src={rxImage}
                              alt="Rx"
                              style={{ width: "100%", height: "100%", objectFit: "contain" }}
                            />
                          </div>
                          {!!details?.medicines?.length && (
                            <>
                              <div className=" mt-1">
                                <h3 className="font-size-12 fw-bold">Medicines</h3>
                              </div>
                              <div className="table-responsive">
                                <Table className="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th style={{ width: "70px" }} className="text-left head">No.</th>
                                      <th className="text-left head">Medicine Name</th>
                                      <th className="text-left head">Dosage</th>
                                      <th className="text-left head">Duration</th>
                                      <th className="text-left head">Instructions</th>
                                    </tr>
                                  </thead>
                                  <tbody className="table-group-divider">
                                    {details?.medicines?.length
                                      ? details?.medicines?.map((item, index) => (
                                        <tr key={index}>
                                          <td className="font-size-14-black">
                                            {index + 1}
                                          </td>
                                          <td>
                                            <div>
                                              {item.medicine} {<br />}
                                            </div>
                                          </td>
                                          <td className="font-size-14-black">
                                            {item.dosage}
                                          </td>
                                          <td className="font-size-14-black">
                                            {item.duration}
                                          </td>
                                          <td className="text-left font-size-14-black">
                                            {item?.instructions || "N/A"}
                                          </td>
                                        </tr>
                                      ))
                                      : null}
                                  </tbody>
                                </Table>
                              </div>
                            </>
                          )}

                          {details?.vaccinations?.length ? (
                            <>
                              <div className=" mt-1">
                                <h3 className="font-size-12 fw-bold">Vaccines</h3>
                              </div>
                              <div className="table-responsive">
                                <Table className="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th className="text-left head" style={{ width: "70px" }}>No.</th>
                                      <th className="text-left head">Vaccines Name</th>
                                      <th className="text-left head">Type</th>
                                      <th className="text-left head">Date of Vaccine</th>
                                      <th className="text-left head">Expiry Date</th>
                                    </tr>
                                  </thead>
                                  <tbody className="table-group-divider">
                                    {details?.vaccinations?.length
                                      ? details?.vaccinations?.map(
                                        (item, index) => {
                                          console.log(item, "item")
                                          return (
                                            <tr key={index}>
                                              <td className="font-size-14-black">
                                                {index + 1}
                                              </td>
                                              <td className="font-size-14-black">
                                                <div>
                                                  {item?.vaccine || ""}
                                                </div>
                                              </td>
                                              <td className="font-size-14-black">
                                                {item?.type || ""}
                                              </td>
                                              <td className="font-size-14-black">
                                                { item?.date ? moment(item.date).format(
                                                  "MMM Do YY"
                                                ) : ""}
                                              </td>
                                              <td className="text-left head font-size-14-black">
                                                { item?.expiryDate ? moment(item.expiryDate).format(
                                                  "MMM Do YY"
                                                ) : ""}
                                              </td>
                                            </tr>
                                          )
                                        }
                                      )
                                      : null}
                                  </tbody>
                                </Table>
                              </div>
                            </>
                          ) : null}

                          {details?.imagingTests?.length ? (
                            <>
                              <div className=" mt-1">
                                <h3 className="font-size-12 fw-bold">
                                  Imaging Tests
                                </h3>
                              </div>
                              <div className="table-responsive">
                                <Table className="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th className="text-left head" >No.</th>
                                      <th className="text-left head" >Test Name</th>

                                      <th className="text-left head">
                                        Description
                                      </th>
                                      <th className="text-left head" >Instructions</th>
                                    </tr>
                                  </thead>
                                  <tbody className="table-group-divider">
                                    {details?.imagingTests?.length
                                      ? details?.imagingTests?.map(
                                        (item, index) => (
                                          <tr key={index}>
                                            <td className="font-size-14-black">
                                              {index + 1}
                                            </td>
                                            <td>
                                              <div className="font-size-14-black">
                                                {item.type}
                                              </div>
                                            </td>
                                            <td className="font-size-14-black">
                                              {item.result}
                                            </td>
                                            <td className="text-left head font-size-14-black">
                                              {item.notes}
                                            </td>
                                          </tr>
                                        )
                                      )
                                      : null}
                                  </tbody>
                                </Table>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {details?.labTests?.length ? (
                            <>
                              <div className=" mt-1">
                                <h3 className="font-size-12 fw-bold">Lab Tests</h3>
                              </div>
                              <div className="table-responsive">
                                <Table className="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th className="text-left head" >No.</th>
                                      <th className="text-left head" >Test Name</th>

                                      <th className="text-left head" >Description</th>
                                      <th className="text-left head" >Instructions</th>
                                    </tr>
                                  </thead>
                                  <tbody className="table-group-divider">
                                    {details?.labTests?.length
                                      ? details?.labTests?.map((item, index) => (
                                        <tr key={index}>
                                          <td className="font-size-14-black">
                                            {index + 1}
                                          </td>
                                          <td>
                                            <div className="font-size-14-black">
                                              {item.title}
                                            </div>
                                          </td>
                                          <td className="font-size-14-black">
                                            {item.description}
                                          </td>
                                          <td className="text-left head font-size-14-black">
                                            {item.instructions}
                                          </td>
                                        </tr>
                                      ))
                                      : null}
                                  </tbody>
                                </Table>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </Row>

                        {
                          details?.notes && (
                            <div>
                              <h3 className="font-size-12 fw-bold">Notes: </h3>
                              <p style={{ backgroundColor: "#f8f8fb" }}>
                                <ul>
                                  <li>{details?.notes}</li>
                                </ul>
                              </p>
                            </div>
                          )
                        }

                        <Col sm="12" className="text-sm-end pb-2" style={{ marginTop: "5rem"}}>
                          <div className="authorized-signatory text-end head">
                            Authorised Signatory{" "}
                          </div>
                        </Col>
                        <Row>
                          {/* <Row style={{ padding: 0}}>
                        <Col sm="12" className="text-sm-end">
                          <div className="fs-5">
                            <h3 className="text-left font-size-12 fw-bold">
                              Terms & Conditions
                            </h3>
                            <ol className="fs-6 text-left">
                              <li>
                                Medicines/Pharmacy products once purchased,
                                cannot be returned or exchanged.
                              </li>
                              <li>
                                Items once purchased cannot be returned for
                                refund and the returned amount will be provided
                                as Supaw Pet Clinic Credit Note.
                              </li>
                              <li>
                                The returned product should be unused, with
                                original packaging and product tag intact. The
                                product should be in Resellable condition,
                                discretion of which will lie with Supaw Pet
                                Clinic.
                              </li>
                            </ol>
                          </div>
                        </Col>
                        </Row> */}
                        </Row>
                        <Row style={{ marginBottom: "10px" }}>
                          <Col sm="2" style={{ padding: 0 }}>
                            <span style={{ display: "flex", alignItems: "flex-end", justifyContent: "center" }}>
                              <h3 className="font-size-14 fw-bold head m-0">
                                <MdPets color={"var(--primary-color)"} /> {details?.doctorId?.clinicName.toUpperCase() || ""}
                              </h3>
                            </span>
                          </Col>
                          <Col sm="8" style={{ padding: 0 }}>
                            <span style={{ display: "flex", alignItems: "flex-end", justifyContent: "center" }}>
                              <h3 className="font-size-14 fw-bold head m-0">
                                <IoLocation color="#E75C25" /> {capitalizeFirstLetter(details?.doctorId?.completeAddress || "")}
                              </h3>
                            </span>
                          </Col>

                          <Col sm="2" style={{ padding: 0 }}>
                            <span style={{ display: "flex", alignItems: "flex-end", justifyContent: "center" }}>
                              <h3 className="font-size-14 fw-bold head m-0" style={{ whiteSpace: "nowrap" }}>
                                <IoCall color={"#009E60"} /> {`${details?.doctorId?.countryCode || "+91"}-${details?.doctorId?.phone}` || ""}
                              </h3>
                            </span>
                          </Col>

                        </Row>
                        <div className="text-center">
                          Prescription generated on Supaw.co
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <div className="d-print-none">
                <div className="float-end">
                  <Link
                    to="#"
                    onClick={handleDownloadPDF}
                    className="btn me-2"
                    style={{ background: "#E89F23", color: "white" }}
                  >
                    <i className="fa fa-download" />
                  </Link>
                  <Link
                    to="#"
                    onClick={printInvoice}
                    className="btn me-2"
                    style={{ background: "#E75C25", color: "white" }}
                  >
                    <i className="fa fa-print" />
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <Row>
              <Card>
                <CardBody>
                  <CardText>No Prescription found</CardText>
                </CardBody>
              </Card>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

InvoiceDetail.propTypes = {
  match: PropTypes.any,
}

export default withRouter(InvoiceDetail)
