import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  Row,
  Input,
  Label,
  FormFeedback,
} from "reactstrap"
import "./login.css"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { GoogleLogin } from "react-google-login"

// import images
import logodark from "../../assets/images/supaw-vets.svg"
import logolight from "../../assets/images/supaw-vets.svg"
import googleIcon from "../../assets/images/auth/google.png"
import CarouselPage from "./AuthenticationInner/CarouselPage"
import LandingPage from "../../assets/images/auth/loginImage.png"
import {
  checkExistance,
  googleLoginRegister,
  getLoggedInUserDetails,
} from "../../helpers/api_helper"
import { useDispatch } from "react-redux"
import { google } from "../../config"
import { loginUser } from "../.."
import ButtonMain from "components/Common/Button"
import { Alert } from "reactstrap"
import { getDetails, setAuthUtils } from "store/slices"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import { showMessage } from "components/Notification"

const Login2 = ({ deviceToken }) => {
  const [passwordShow, setPasswordShow] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  //Commented
  //meta title
  document.title = "Login | Supaw"

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async values => {
      try {
        setIsLoading(true)
        const response = await checkExistance(values, deviceToken)
        if (response.status == 1) { 
          localStorage.setItem("id", response?.data?._id);
          if(!!!response.data.stepOneVerifyEmail || response.data.stepOneVerifyEmail === false){
            history.push("/register-step1-verify-email")
          } else if (response.data.stepTwo === false) {
            history.push("/register-step2")
          } else if (response.data.stepThree === false) {
            history.push("/register-step3")
          } else if (response.data.stepFour === false) {
            history.push("/register-step4")
          } else {
            await dispatch(getDetails(response.data._id))
            console.log("refreshing1")
            history.push("/dashboard")
            window.location.reload()
            console.log("refreshing 2")
          }
        }
      } catch (error) {
        console.error("Error::::::", error);
        if(error?.response?.data?.message){
          setError(error.response.data.message);
          showMessage({
            label: error.response.data.message,
            status: "error",
          })
        } else {
          showMessage({
            label: "Something went wrong.",
            status: "error",
          })
        }
        setIsLoading(false)
      }
    },
  })
  useEffect(() => {
    let user = localStorage.getItem("authUser")
    if (user && typeof user === Object) {
      user = JSON.parse(user)
      if (user.password) {
        history.push("/")
      }
    } else {
      localStorage.removeItem("authUser")
      history.push("/login")
    }
    const urlParams = new URLSearchParams(window.location.search)
    const successMessage = urlParams.get("successMessage")
    if (successMessage) {
      setSuccess(successMessage)
      setTimeout(() => {
        setSuccess("")
        history.push("/login")
      }, 4000)
    }
  }, [dispatch])

  const signIn = async (res, type) => {
    if (type === "google" && res) {
      const values = {
        fullName: res.profileObj.name,
        email: res.profileObj.email,
        registerType: "gmail",
        role: "vet",
        gender: "",
        specialization: "",
        consultationFee: "",
        images: [],
        timings: [],
        location: {
          type: "Point",
          coordinates: [79.466062, 28.780943],
        },
      }
      try {
        setIsLoading(true)
        const response = await googleLoginRegister(values)
        if (response?.status == 1) {
          await dispatch(getDetails(response.data._id))
          history.push("/dashboard")
        } else {
          console.log(":::::::::::::::::::::", response)
        }
      } catch (error) {
        console.log(":::::::::::::::::::::11..", response)
        let message = error?.response?.data?.message
        if (message && typeof message !== "string") {
          message = "something went wrong!"
        }
        showMessage({
          label: message,
          status: "error",
        })
        setError(message)
        setIsLoading(false)
      }
    }
  }

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google")
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0 h-100">
          <Row className="g-0">
            <Col xl={5}>
              <div className="auth-full-page-content p-md-5 p-4 vh-100">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 onboarding-logo-container">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logodark} alt="" className="auth-logo-dark" />
                        <img
                          src={logolight}
                          alt=""
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div style={{ padding: "0px 20%" }} className="my-auto">
                      <div>
                        <h1>Sign In</h1>
                        <p className="text-muted">Let's get you started.</p>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          {error ? <Alert color="danger">{error}</Alert> : null}
                          {success ? (
                            <Alert color="success">{success}</Alert>
                          ) : null}

                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3 position-relative">
                            <Label className="form-label">Password</Label>
                            <div className="input-container">
                              <Input
                                className="password-input"
                                name="password"
                                value={validation.values.password || ""}
                                type={passwordVisible ? "text" : "password"}
                                placeholder="Enter Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password &&
                                  validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              <span
                                aria-label={
                                  passwordVisible
                                    ? "Hide password"
                                    : "Show password"
                                }
                                className="password-toggle-icon"
                                onClick={togglePasswordVisibility}
                              >
                                {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                              </span>
                              {validation.touched.password &&
                              validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="text-end mt-2">
                              <Link
                                to="/forgot-password"
                                className="text-black text-decoration-underline"
                              >
                                Forgot password?
                              </Link>
                            </div>
                          </div>
                          {/* <div className="form-check" /> */}
                          {/* xpecto Developer 6/7/2024*/}

                          <div className="mt-3 d-grid">
                            <ButtonMain
                              className="btn btn-primary btn-block"
                              type="submit"
                              isLoading={isLoading}
                            >
                              Sign in
                            </ButtonMain>
                          </div>
                        </Form>

                        <Form action="dashboard">
                          <div className="text-center">
                            {/* <hr data-text="OR" /> */}
                            {/* <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="auth-google"
                                  onClick={renderProps.onClick}
                                >
                                  <img src={googleIcon} /> Sign in with Google
                                </Link>
                              )}
                              onSuccess={googleResponse}
                              onFailure={() => {}}
                            /> */}
                          </div>
                        </Form>
                        <div className="mt-5 text-center">
                          <p>
                            Don&apos;t have an account ?
                            <Link
                              to="/register"
                              className="fw-medium text-primary"
                            >
                              {" "}
                              Signup now
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            {/* <div className="d-none d-md-block inline-display"> */}

            <CarouselPage landingPage={LandingPage} imageTitle="Dashboard" />
            {/* </div> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Login2
