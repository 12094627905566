import axios from "axios"
import { BASE } from "helpers/api_helper"

import { components } from "react-select"
import { debounce } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import ReactSelect from "react-select"
import AsyncSelect from "react-select/async"
import { Col, Form, Label, Modal, ModalBody, Row } from "reactstrap"
import { getAllMedicines } from "store/slices"

const DOSAGE = [
  { value: "0-0-1", label: "0-0-1" },
  { value: "0-1-1", label: "0-1-1" },
  { value: "1-1-1", label: "1-1-1" },
  { value: "1-1-0", label: "1-1-0" },
  { value: "0-1-0", label: "0-1-0" },
  { value: "1-0-0", label: "1-0-0" },
  { value: "1-0-1", label: "1-0-1" },
]

const initialDosage = { value: "1-0-0", label: "1-0-0" }

const TypeModal = ({ onConfirm, data }) => {
  const initialMedicine = { Medicine: "", Salts: "", Brand: "", _id: "" }
  const initialDosage = { value: "", label: "" }
  const [formRows, setFormRows] = useState([{ id: 1 }])
  const [selectedDosages, setSelectedDosages] = useState([initialDosage])
  const [selectedMedicines, setSelectedMedicines] = useState([initialMedicine])
  const [inputValue, setInputValue] = useState(null)

  // Function to handle checkbox change

  // const allMeds = useSelector(getAllMedicines)
  useEffect(() => {
    setFormRows([{ id: 1 }])

    if (data) {
      setFormRows(data)
    }
  }, [data])

  const onAddFormRow = () => {
    const modifiedRows = [...formRows]

    if (
      !(
        modifiedRows[modifiedRows.length - 1].medicine &&
        modifiedRows[modifiedRows.length - 1].duration &&
        modifiedRows[modifiedRows.length - 1].dosage
      )
    ) {
      modifiedRows[modifiedRows.length - 1].error = "Please Enter Values"
      setFormRows(modifiedRows)
      return
    } else {
      delete modifiedRows[modifiedRows.length - 1].error
    }
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setFormRows(modifiedRows)
  }

  const onDeleteFormRow = id => {
    if (id !== 1) {
      var modifiedRows = [...formRows]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setFormRows(modifiedRows)
      onConfirm(modifiedRows)
    }
  }

  const handleAddTitleAndDescription = (index, type, e) => {
    const { value } = e.target
    const modifiedRows = [...formRows]
    modifiedRows[index][type] = value
    setFormRows(modifiedRows)
    console.log("modifiedRows", modifiedRows)
    onConfirm(modifiedRows)
  }

  // HANDLE SELECT AND SEARCH MEDICINE
  // / handle input change event
  const handleInputChange = value => {
    console.log(value)
    setInputValue(value)
  }
  const debouncedResults = useMemo(() => {
    return debounce(handleInputChange, 1000)
  }, [])

  // console.log(ids)
  // handle selection
  const handleChange = (value, index) => {
    console.log(value, index)
    const updatedMedicines = [...selectedMedicines]
    // const updatedDosages = [...selectedDosages]

    updatedMedicines[index] = value
    // updatedDosages[index] = selectedDosages // You need to replace selectedDosage with the actual selected dosage for this row

    setSelectedMedicines(updatedMedicines)
    // setSelectedDosages(updatedDosages)

    handleAddTitleAndDescription(index, "medicine", {
      target: { value: value.Medicine },
    })
  }

  // load options using API call
  const loadOptions = async inputValue => {
    const token = JSON.parse(localStorage.getItem("authUser"))
    console.log("medicine", token)

    const headers = {
      Authorization: `${token?.token}`,
      "Content-Type": "application/json",
    }

    try {
      const response = await axios.post(
        `${BASE}api/MedicineMaster/v1/searchMedicine`,
        {
          diagnosis: ["377", "378", "381", "100022"],
          text: inputValue,
        },
        { headers }
      )

      return response.data.data || []
    } catch (error) {
      console.error("Error fetching options:", error)
      return []
    }
  }

  useEffect(() => {
    return () => {
      debouncedResults.cancel()
    }
  })
  const Option = props => {
    console.log(props)
    return (
      <div>
        <components.Option
          {...props}
          className="multiselect-combobox__option-container"
        >
          <span>
            <label>{props.data.Medicine}</label>
            {props.data.Salts && (
              <p style={{ color: "#444" }}>({props.data.Salts})</p>
            )}
          </span>
        </components.Option>
      </div>
    )
  }
  return (
    <>
      <Col lg={12}>
        <div className="text-center">
          <br />
          <Form className="repeater mt-12" encType="multipart/form-data">
            <div>
              <Row>
                <Col lg={4}>
                  <Label
                    htmlFor="projectdesc"
                    className="col-form-label text-left text-input-label text-input-label--small font-fontFamily"
                  >
                    Medicine *:
                  </Label>
                </Col>
                <Col lg={2}>
                  <Label
                    htmlFor="projectdesc"
                    className="col-form-label text-left text-input-label text-input-label--small font-fontFamily"
                  >
                    Dosage *:
                  </Label>
                </Col>
                <Col lg={3}>
                  <Label
                    htmlFor="projectdesc"
                    className="col-form-label text-left text-input-label text-input-label--small font-fontFamily"
                  >
                    Duration *:
                  </Label>
                </Col>
                <Col lg={2}>
                  <Label
                    htmlFor="projectdesc"
                    className="col-form-label  text-left text-input-label text-input-label--small font-fontFamily"
                  >
                    Instructions (if any):
                  </Label>
                </Col>
                <Col lg={1}></Col>
              </Row>

              {(formRows || []).map((formRow, key) => {
                return (
                  <Row key={key} className="mb-4">
                    <Col lg={4}>
                      {/* <input
                        type="text"
                        id="name"
                        value={formRow.medicine}
                        name="untyped-input"
                        className="form-control"
                        onChange={e =>
                          handleAddTitleAndDescription(key, "medicine", e)
                        }
                        placeholder="Medicine"
                      /> */}
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={selectedMedicines[key]}
                        getOptionLabel={e => {
                          return e?.Medicine ? `${e?.Medicine}-(${e?.Brand})` : ""
                        }}
                        getOptionValue={e => e._id}
                        loadOptions={loadOptions}
                        onInputChange={debouncedResults}
                        components={{
                          Option,
                        }}
                        classNamePrefix="form-react-select"
                        className="form-react-select"
                        onChange={sys => handleChange(sys, key)}
                      />
                    </Col>
                    <Col lg={2}>
                      {/* <input
                        type="text"
                        value={formRow.dosage}
                        id="subject"
                        onChange={e =>
                          handleAddTitleAndDescription(key, "dosage", e)
                        }
                        className="form-control"
                        placeholder="Dosage"
                      /> */}

                      <ReactSelect
                        value={selectedDosages[key]}
                        onChange={item => {
                          handleAddTitleAndDescription(key, "dosage", {
                            target: { value: item.label },
                          })
                          const updatedDosages = [...selectedDosages]
                          updatedDosages[key] = item
                          setSelectedDosages(updatedDosages)
                        }}
                        options={DOSAGE}
                        classNamePrefix="form-react-select"
                        className="form-react-select"
                        placeholder="select"
                      />
                    </Col>
                    {/* <Col lg={3}>
                      <input
                        type="number"
                        value={formRow.duration}
                        id="subject"
                        onChange={e => {
                          const value = e.target.value

                          // Convert the input value to an integer and check if it's valid
                          if (value === "" || parseInt(value, 10) > 0) {
                            handleAddTitleAndDescription(key, "duration", e)
                          }
                        }}
                        className="form-control"
                        placeholder="Duration (days)"
                        max={100}
                        min="1" // Prevent decreasing below 1 via UI (arrows)
                        onKeyDown={e => {
                          if (e.key === "0" && e.target.value === "") {
                            e.preventDefault() // Prevent typing '0' as the first character
                          }
                        }}
                      />
                    </Col> */}

                    <Col lg={3}>
                      <input
                        type="number"
                        value={formRow.duration}
                        id="subject"
                        onChange={e => {
                          let value = e.target.value

                          // Convert the input value to an integer and ensure it doesn't exceed 100
                          if (value !== "" && parseInt(value, 10) > 100) {
                            value = 100 // Set the value to 100 if it exceeds the limit
                          }

                          // Ensure value is greater than 0
                          if (
                            value === "" ||
                            (parseInt(value, 10) > 0 &&
                              parseInt(value, 10) <= 100)
                          ) {
                            handleAddTitleAndDescription(key, "duration", {
                              ...e,
                              target: { ...e.target, value },
                            })
                          }
                        }}
                        className="form-control"
                        placeholder="Duration (days)"
                        max={100}
                        min="1" // Prevent decreasing below 1 via UI (arrows)
                        onKeyDown={e => {
                          if (e.key === "0" && e.target.value === "") {
                            e.preventDefault() // Prevent typing '0' as the first character
                          }
                        }}
                      />
                    </Col>

                    <Col lg={2}>
                      <input
                        type="text"
                        value={formRow.instruction}
                        id="subject"
                        onChange={e =>
                          handleAddTitleAndDescription(key, "instructions", e)
                        }
                        className="form-control"
                        placeholder="Instruction (if any)"
                      />
                    </Col>

                    {
                      key !== 0 && (
                        <Col lg={1} className="align-self-center">
                          <div className="d-grid">
                            <button
                              type="button"
                              style={{ height: 47 }}
                              className="btn btn-dark"
                              onClick={() => onDeleteFormRow(formRow.id)}
                            >
                              <i className="bx bx-minus label-icon "></i>
                            </button>
                          </div>
                        </Col>
                      ) 
                    }
                    

                    {formRow.error && (
                      <label className="form-control--error">
                        {formRow.error}
                      </label>
                    )}
                  </Row>
                )
              })}
            </div>
            <div
              className="justify-content-end d-flex"
              style={{ padding: 40, paddingRight: 0 }}
            >
              <button
                type="button"
                className="btn btn-dark d-flex align-items-center justify-content-center text-center"
                value="Add"
                onClick={() => onAddFormRow()}
              >
                <i className="bx bx-plus label-icon "></i>
                <p style={{ margin: 0 }}>Add Another Medicine</p>
              </button>
            </div>
          </Form>
        </div>
      </Col>
    </>
  )
}

TypeModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
}

export default TypeModal
